import { HrAgentService } from './../../services/hr-agent.service';
import { Subscription } from 'rxjs';
import { MainService } from 'src/app/services/main.service';
import { MessageService } from './../../services/messageService';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HrAgent } from './../../models/hr-agent';
import { Component, OnInit, Input } from '@angular/core';
import { ReceivingEntity } from 'src/app/models/receiving-entity';
import { SendingEntity } from 'src/app/models/sending-entity';
import { SendingEntityService } from 'src/app/routes/sending-entity/sending-entity.service';
import { ActivatedRoute } from '@angular/router';
import { ReceivingEntityService } from 'src/app/routes/receiving-entity/receiving-entity.service';
import { UserService } from 'src/app/services/user.service';


@Component({
    selector: 'app-hr-agents',
    templateUrl: './hr-agents.component.html',
    styleUrls: ['./hr-agents.component.scss']
})
export class HrAgentsComponent {

    constructor(
        private formBuilder: FormBuilder,
        private hrAgengService: HrAgentService,
        private messageService: MessageService,
        private mainService: MainService,
        private route: ActivatedRoute,
        private sendingEntityService: SendingEntityService,
        private receivingEntityService: ReceivingEntityService,
        private userService: UserService
    ) { 
        this.getTenant();
    }

    @Input()
    public hrAgents: HrAgent[];

    /**
     * HR-Agents belongs to either a receiving entity...
     */
    @Input()
    public receivingEntity: ReceivingEntity;

    /**
     * or a sending entity
     */
    @Input()
    public sendingEntity: SendingEntity;

    /**
     * The hr agent being edited
     */
    public hrAgent: HrAgent;

    /**
     * The FormGroup for the ProjectAdmin being edited
     */
    public formGroup: FormGroup;

    public tenantName_;

    private openHrAgent(hrAgent: HrAgent) {
        this.hrAgent = hrAgent;
        if (!this.hrAgent['agentInfo']) {
            this.hrAgent['agentInfo'] = {
                localhireAgent: false
            }
        }

        this.formGroup = this.formBuilder.group({
            username: [hrAgent.username, Validators.required],
            firstname: [hrAgent.firstname, Validators.required],
            lastname: [hrAgent.lastname, Validators.required],
            email: [hrAgent.email, [Validators.email, Validators.required]],
            company: [this.sendingEntity],
            receivingCompany: [this.receivingEntity]
        });
    }

    public cellClickHandler() {
    }

    public cellCloseHandler() {
    }

    public onClickEditHrAgent(hrAgent: HrAgent) {
        if (this.hrAgent && !this.hrAgent['agentInfo']) {
            this.hrAgent['agentInfo'] = {
                localhireAgent: false
            }
        }
        this.openHrAgent(hrAgent);
    }

    public onClickCancelHrAgent(event) {
        this.hrAgent = undefined;
    }

    public onClickSaveHrAgent(event) {
        const isNew = !this.hrAgent.id;
        const value = this.formGroup.value;

        const toSave = {} as any;

        Object.assign(toSave, this.hrAgent);
        Object.assign(toSave, value);
        toSave.id = this.hrAgent.id;

        if (toSave.company) {
            toSave.company = { id: toSave.company.id };
        } else {
            toSave.company = null;
        }

        if (toSave.receivingCompany) {
            toSave.receivingCompany = { id: toSave.receivingCompany.id };
        } else {
            toSave.receivingCompany = null;
        }

        this.hrAgengService.saveHrAgent(toSave).subscribe(hrAgent => {
            this.formGroup.controls.email.markAsPristine();
            if (isNew) {
                this.hrAgents.push(this.hrAgent);
            }
            // get the current list
            this.messageService.basicToast('HR Agent has been saved successfully.');
            // this.sendingEntity.id = this.route.snapshot.params.id * 1;
            // console.log(this.sendingEntity.id);
            const sending_id = this.route.snapshot.params.id * 1;
            // Davor wurde this.sendingEntity.id mitgeschickt aber da hat er die ID nicht gefunden.
            if (toSave.receivingCompany) {
                this.receivingEntityService.getReceivingEntity(sending_id).subscribe(sendingEntity => {

                    this.sendingEntity = sendingEntity;

                    this.hrAgents = sendingEntity.hrAgents;
                    this.hrAgent = undefined;
                }, () => {

                });
            } else {
                this.sendingEntityService.getSendingEntity(sending_id).subscribe(sendingEntity => {

                    this.sendingEntity = sendingEntity;

                    this.hrAgents = sendingEntity.hrAgents;
                    this.hrAgent = undefined;
                }, () => {

                });
            }

        }, (error) => {

            if (error.error.content.message == 'Username_encrypted is a unique field.') {
                this.messageService.basicToast('Please choose a nonexisting username.', 'error');
            }
        });
    }

    public onClickDeleteHrAgent(hrAgent: HrAgent) {
        // console.log(this.receivingEntity);
        // console.log(this.sendingEntity);

        this.mainService.prompt('Are you sure you would like to delete this HR-Agent?', (value, answer) => {
            if (answer === 'yes') {
                this.hrAgengService.deleteHrAgent(hrAgent.id).subscribe(() => {
                    this.messageService.basicToast('Hr Agent has been deleted successfully.');
                    this.loadAgents();
                });
            }
        }, 'confirm');
    }

    private loadAgents(){
        const sending_id = this.route.snapshot.params.id * 1;

        if(this.receivingEntity){
            this.receivingEntityService.getReceivingEntity(sending_id).subscribe(sendingEntity => {

                this.sendingEntity = sendingEntity;

                this.hrAgents = sendingEntity.hrAgents;
                this.hrAgent = undefined;
            }, () => {

            });
        } else {
            this.sendingEntityService.getSendingEntity(sending_id).subscribe(sendingEntity => {

                this.sendingEntity = sendingEntity;

                this.hrAgents = sendingEntity.hrAgents;
                this.hrAgent = undefined;
            }, () => {

            });
        }
    }
    public onClickAddHrAgent() {
        this.openHrAgent(new HrAgent());
    }

    public onClickResendCreds() {
        this.hrAgengService.sendHrAgentCredentials(this.hrAgent).subscribe(resp => {
            this.mainService.prompt('Credentials have been sent successfully', () => { }, 'alert');
        });
    }

    private getTenant() {
        this.userService.getTenant().subscribe((success) => {
            this.tenantName_ = success;
        });
    }
}
