import { User } from './user';
import { Deserializable } from './deserializable.model';

export class UserGroup implements Deserializable {

    public name: string;
    public users: User[];

    deserialize(input: any): this {
        Object.assign(this, input);

        if (!input.users) {
            this.users = [];
        }

        return this;
    }

}
