import { BaseHttpService } from './base-http.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Injectable, NgZone, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { MessageService } from './messageService';
import { AppData } from './app-data.service';
import { AssigneeService } from '../routes/assignee/assignee.service';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseHttpService {
    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService,
        private appData: AppData,
        private assigneeService: AssigneeService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    public login(user: { username: string; password: string }): Observable<User> {
        this.checkRequestTime();

        return this.httpClient.post(this.getApiPath() + '/auth/login', user, this.getOptions()).pipe(
            catchError(this.catchGlobalError),
            map((data) => new User().deserialize(data))
        );
    }

    public forgot(user) {
        this.checkRequestTime();

        const userData = 'userIdentity=' + user.userIdentity;
        const header = { 'Content-type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest' };
        return this.httpClient
            .post(this.getOldApiPath() + '/passwordforgot', userData, this.getOptions(null, header))
            .pipe(catchError(this.catchGlobalError));
    }

    public resetPassowrd(user) {
        this.checkRequestTime();

        const userData = 'password=' + user.password + '&token=' + user.token;
        const header = { 'Content-type': 'application/x-www-form-urlencoded' };
        return this.httpClient
            .post(this.getOldApiPath() + '/passwordreset', userData, this.getOptions(null, header))
            .pipe(catchError(this.catchGlobalError));
    }
    public logout(auth?) {
        this.checkRequestTime();

        let body = {};
        if (auth) {
            body = {
                authorization: auth,
            };
        }
        return this.httpClient.post(this.getApiPath() + '/auth/logout', body, this.getOptions()).pipe(
            catchError(this.catchGlobalError),
            catchError((error, caught) => {
                return null;
            })
        );
    }

    public getList(type: string, offset: number, limit: number, sort?: SortDescriptor[], params?: object) {
        this.checkRequestTime();

        let orderBy;

        if (sort && sort.length && sort[0].dir) {
            orderBy = `${sort[0].field}:${sort[0].dir}`;
        }

        const options = this.getOptions(
            Object.assign(
                {
                    offset,
                    limit,
                    orderBy,
                },
                params
            )
        );

        return this.httpClient
            .get<any>(this.getApiPath() + '/user/users', options)
            .pipe(catchError(this.catchGlobalError));
    }

    public getTenant() {
        this.checkRequestTime();

        return this.httpClient
            .get<any>(this.getApiPath() + '/user/tenantName', {})
            .pipe(catchError(this.catchGlobalError));
    }
    public getUserType() {
        this.checkRequestTime();

        return this.httpClient.get<any>(this.getApiPath() + '/user/active', {}).pipe(catchError(this.catchGlobalError));
    }

    public getUser(id: number): Observable<User> {
        this.checkRequestTime();
        /*
                return of({
                    id: 1,
                    username: 'micger',
                    firstname: 'Michael',
                    lastname: 'Geronimo',
                    email: 'michael.geronimo@brainformance.at',
                    userGroups: [{
                        name: 'Administratoren',
                        id: 1
                    }]
                })
                    .pipe(delay(500))
                    .pipe(
                        map(data => new User()
                            .deserialize(data))
                    );
        */
        return this.httpClient.get<User>(this.getApiPath() + '/user/' + id, this.getOptions()).pipe(
            catchError(this.catchGlobalError),
            map((data) => new User().deserialize(data))
        );
    }

    public save<T>(type: string, ob: any, mapper: (any) => T) {
        if (ob.id) {
            return this.update<T>(type, ob.id, ob, mapper);
        }
        return this.create<T>(type, ob, mapper);
    }

    protected create<T>(type: string, ob: object, mapper: (any) => T) {
        this.checkRequestTime();
        return this.httpClient
            .post<T>(this.getApiPath() + '/' + type, ob, this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }

    protected update<T>(type: string, id: number, ob: object, mapper: (any) => T) {
        this.checkRequestTime();
        return this.httpClient
            .put<T>(this.getApiPath() + '/' + type + '/' + id, ob, this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }

    public delete(type: string, id: number) {
        this.checkRequestTime();
        return this.httpClient
            .delete<object>(this.getApiPath() + '/' + type + '/' + id, this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }

    public getWidgetData() {
        this.checkRequestTime();
        return this.httpClient
            .get<any>(this.getApiPath() + '/widget/data', this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }
    public getNotificationData() {
        this.checkRequestTime();
        return this.httpClient
            .get<any>(this.getApiPath() + '/notification/order', this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }
    public updateNotificationData<T>(id: number, ob: any, mapper: (any) => T) {
        this.checkRequestTime();
        return this.httpClient
            .put<T>(this.getApiPath() + '/notification/order/' + id, ob, this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }

    /**
     * function to update the password
     */
    public updatePassword(newPassword) {
        return this.httpClient
            .post(this.getApiPath() + '/user/updatePassword?newPassword=' + newPassword, {}, this.getOptions())
            .pipe(
                catchError(this.catchGlobalError),
                map((data) => new User().deserialize(data))
            );
    }

    public getMobileAppicationView() {
        this.checkRequestTime();
        return this.httpClient
            .get<any>(this.getApiPath() + '/user/mobileApplicationView', this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }

    /*
     * Get other Assignees from your User
     */
    public getAssigneePotted(key) {
        this.checkRequestTime();
        return this.httpClient
            .get<any>(this.getApiPath() + '/auth/assignee/potted?authorization=' + key, this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }

    public changeToOtherAssignee(username, key) {
        this.checkRequestTime();

        const body = {
            username: username,
            authorization: key,
        };
        return this.httpClient
            .post<any>(this.getApiPath() + '/auth/login/assignee ', body, this.getOptions())
            .pipe(catchError(this.catchGlobalError));
    }

    /**
     * Routine of tasks that need to be done, EVERY TIME in order of getting the logged in user.
     * This is necessary because we store a lot of information in `this.appData`,
     * which will be erased on browser refresh (It's just JavaScript)
     *
     * @returns `Promise<boolean>` so we can `await` for it. `true` means the user is logged in
     */
    async getCurrentUserData() {
        this.checkRequestTime();

        try {
            const response = await this.httpClient
                .get(this.getApiPath() + '/auth/loggedInUser', this.getOptions())
                .pipe(
                    map((data: any) => new User().deserialize(data)),
                    catchError(this.catchGlobalError)
                )
                .toPromise();
            console.log("RESPONSE", response)
            this.appData.loggedInUser = response['user'];
            this.appData.config = response['comicConfig'];
            this.appData.authkey = response['authorization'];

            // store all (ex-) profiles from the assignee, to be able to switch between them
            try {
                console.log("Logged in user:", this.appData.loggedInUser)
                const key = this.appData.authkey ||this.appData.loggedInUser.username
                this.appData.myAssignee = await this.getAssigneePotted(key).toPromise();
            } catch (error) {
                this.appData.myAssignee = null;
            }

            if (this.appData.loggedInUser) {
                // store the users permissions
                for (let permission of this.appData.loggedInUser.allPermissions) {
                    this.appData.permissions[permission.name] = true;
                }

                // if user is Assignee, store his assignee data
                if (this.appData.userIsAssignee()) {
                    try {
                        this.appData.loggedInAssignee = await this.assigneeService
                            .getAssigneeOfLoggedInUser()
                            .toPromise();
                    } catch (error) {
                        this.messageService.basicToast('Assignee data could not be fetched', 'error');
                    }
                }

                // determine and store the users startpage (depends on permissions)
                let startPage = 'dashboard'
                if (this.appData.isReportingMode()) {
                    startPage = 'assignee'
                }
                this.appData.startPage = this.appData.userIsAdmin() ? startPage : 'home';

                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }
}
