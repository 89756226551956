import { HrAgent } from './../models/hr-agent';
import { MessageService } from './messageService';
import { BaseHttpService } from './base-http.service';

import { catchError, map, delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';


@Injectable({
  providedIn: 'root'
})
export class HrAgentService extends BaseHttpService {

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    public saveHrAgent(hrAgent: HrAgent): Observable<HrAgent> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/hrAgent';

        return this.httpClient.post<HrAgent>(url, hrAgent, this.getOptions());
    }

    public deleteHrAgent(id: number): Observable<any> {
        this.checkRequestTime();
        const url = this.getApiPath() + '/hrAgent/' + id;
        return this.httpClient.delete<any>(url, this.getOptions());
    }

    public sendHrAgentCredentials(hrAgent: HrAgent): Observable<boolean> {
        this.checkRequestTime();
        const url = this.getApiPath() + '/hrAgent/sendHrAgentCredentials';
        return this.httpClient.post<boolean>(url, hrAgent, this.getOptions());
    }

}
