import { MessageService } from './messageService';
import { DefaultListQueryParams } from './../models/query';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID, Injectable, NgZone } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';

/**
 * In order to keep timeouts/warnings centrally, all http-services must extend from this class
 * This service may not be injected into and used in components directly
 *
 * Yes, each inherited HttpService needs to pass all injected classes to this class' constructor
 */
@Injectable({
    providedIn: 'root'
})
export class BaseHttpService {

    protected loading = false;
    protected lastRequest: Date = new Date();
    protected clientSessionTimout = 181 * 60;

    constructor(
        @Inject(PLATFORM_ID) protected platformId: object,
        protected httpClient: HttpClient,
        protected ngZone: NgZone,
        protected messageService: MessageService
    ) { }

    protected checkRequestTime(updateLastRequest = true) {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        const now = (new Date()).getTime() / 1000;
        const last = this.lastRequest ? this.lastRequest.getTime() / 1000 : now;

        if (now - last >= this.clientSessionTimout) {
            throw 'timed out';
        }

        if (updateLastRequest) {
            this.lastRequest = new Date();
        }
    }

    public getApiPath() {
        return '/comic/rest';
    }
    public getOldApiPath() {
        return '/comic';
    }

    protected getDefaultHeaders(customHeaders?) {
        const headers = {};

        if (isPlatformServer(this.platformId)) {
            // const cookies = this.serverResponseService.getCookies();
            // headers['cookie'] = this.objectToCookieString(cookies);
        }

        if (customHeaders) {
            Object.assign(headers, customHeaders);
        }

        return headers;
    }

    /**
     * Moved logic to http interceptor
     */
    protected catchGlobalError = (error, caught) => {
        throw error;

        /*
        const status = error.status;
        const isBrowser = isPlatformBrowser(this.platformId);

        

        let msg = 'An unexpected error occurred';
        if (error.message) {
            msg += ': <br>' + error.message;
        }
        this.messageService.basicToast(msg, 'error');

        throw error;
        */
    }

    protected getDefaultParams(customParams?) {
        const params = {};

        if (customParams) {
            Object.assign(params, customParams);
        }

        return params;
    }

    protected getOptions(params?: DefaultListQueryParams | any, headers?) {
        params = this.getDefaultParams(params);

        for (const i in params) {
            if (params.hasOwnProperty(i)) {
                if (params[i] === undefined || params[i] === null) {
                    delete params[i];
                } else if (i === 'orderBy' && params[i] && params[i].dir && params[i].field) {
                    // This field is  is a DefaultListQueryParams
                    params[i] = params[i].field + ':' + params[i].dir;
                }
            }
        }

        return {
            headers: this.getDefaultHeaders(headers),
            params
        };
    }

}
