import { Assignee } from '../models/assignee';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, NgZone, Inject } from '@angular/core';
import { MessageService } from './messageService';
import { Observable } from 'rxjs';
import { Payslip } from '../models/payslip';
import { PayslipEntry } from '../models/payslip-entry';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PayslipService extends BaseHttpService {

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    /** Payslips */
    public payslipsLayout(): Observable<number> {

        this.checkRequestTime();
        const url = this.getApiPath() + '/payslip/payslipLayout';
        const options = this.getOptions();

        return this.httpClient.get<any>(url, options).pipe(map(result => {
            if (result) {
                return parseInt(result);
            }
            else {
                return 0;
            }
        }));
    }

    public createPayslip(payslip): Observable<Payslip> {
        return this.httpClient.post<Payslip>(this.getApiPath() + '/payslip', payslip);
    }

    public savePayslip(payslip: Payslip): Observable<Payslip> {
        return this.httpClient.put<Payslip>(this.getApiPath() + '/payslip', payslip);
    }

    public addMonthForDailyGrossUp(assignee: Assignee): Observable<boolean> {
        return this.httpClient.post<boolean>(this.getApiPath() + '/payslip/addMonthForDailyGrossUp', assignee);
    }

    public payslipByAssignee(assigneeId: number, year: number, month: number): Observable<Payslip> {
        this.checkRequestTime();

        const url = this.getApiPath() + `/payslip/payslipByAssignee/${assigneeId}?year=${year}&month=${month}`;
        const options = this.getOptions();

        return this.httpClient.get<Payslip>(url, options).pipe(map(payslip => {

            if (!payslip || payslip.id == null) {
                return null;
            }
            return new Payslip(payslip);
        }));
    }

    public getMissingMonths(assigneeId: number): Observable<number> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/payslip/missingMonths/' + assigneeId;
        const options = this.getOptions();

        return this.httpClient.get<number>(url, options);
    }

    public payslipsByAssignee(assigneeId: number): Observable<Payslip[]> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/payslip/payslipsByAssignee/' + assigneeId;
        const options = this.getOptions();

        return this.httpClient.get<Payslip[]>(url, options).pipe(map(payslips => {
            if (!payslips) {
                return [];
            }

            return payslips.map(rawData => new Payslip(rawData));
        }));
    }

    /** PayslipEntries */
    public getPayslipEntries(payslipId: number, isForEmployer: boolean): Observable<PayslipEntry[]> {
        this.checkRequestTime();

        const url = this.getApiPath() + `/payslipEntry?payslipId=${payslipId}&isForEmployer=${isForEmployer}`;
        const options = this.getOptions();

        return this.httpClient.get<PayslipEntry[]>(url, options).pipe(map(payslipEntries => {
            if (!payslipEntries) {
                return [];
            }

            return payslipEntries.map(entries => new PayslipEntry(entries));
        }));
    }

    /** Other */
    public saveNotifyAssignee(id, value) {
        return this.httpClient.put<Payslip>(this.getApiPath() + '/assignee/notifyAssigneeAboutPayslips?notify=' + (value ? 'true' : 'false'), { id: id });
    }

    public saveNotifyOrg(id, value) {
        return this.httpClient.put<Payslip>(this.getApiPath() + '/assignee/notifyOrgAdminsAboutPayslips?notify=' + (value ? 'true' : 'false'), { id: id });
    }

    public upload(data) {
        const uploadUrl = `/comic/fileupload`;

        const formData = new FormData();
        // payslips
        formData.append('_upid', '0');
        formData.append(data.name, data);
        
        return this.httpClient.post<any>(uploadUrl, formData);
    }

    public checkFile(url) {
        this.checkRequestTime();
        const options = this.getOptions();

        return this.httpClient.get<any>(url, options).pipe(map(result => {
            
            return result;
        }));
    }
}
