import { AssigneeSalary } from './../../models/assignee-salary';
import { MainService } from 'src/app/services/main.service';
import { MessageService } from './../../services/messageService';
import { Assignee } from './../../models/assignee';
import { DefaultListQueryParams } from './../../models/query';
import { catchError, map, delay, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from './../../services/base-http.service';
import { Injectable, PLATFORM_ID, Inject, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { PaginatedList } from 'src/app/models/paginated-list';
import { Document } from 'src/app/models/document';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class AssigneeService extends BaseHttpService {
    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService,
        private datePipe: DatePipe,
        private mainService: MainService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    public getAssignees(
        start = 0,
        limit = 10,
        status?: string,
        filterText?: string,
        filters?: object,
        sorters?: any
    ): Observable<PaginatedList<Assignee>> {
        this.checkRequestTime();

        const params = {
            start: start + '',
            limit: limit + '',
            status,
            filterText,
            orderBy: sorters ? sorters[0] : null,
        };

        const url = this.getApiPath() + '/assignee/assigneesPaginated' + this.mainService.serialize(params);
        // const url = this.getApiPath() + '/assignee/assigneesPaginated' + params;

        return this.httpClient.post<PaginatedList<Assignee>>(url, {
            filters: filters,
        });
    }

    public getAssignee(id: number): Observable<Assignee> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/assignee/' + id;

        return this.httpClient.get<Assignee>(url, this.getOptions()).pipe(
            map((data) => new Assignee(data)),
            catchError(this.catchGlobalError)
        );
    }

    public getAssigneeByMongoKey(id: string): Observable<Assignee> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/reporting/assignee/' + id;

        return this.httpClient.get<Assignee>(url, this.getOptions()).pipe(
            map((data) => new Assignee(data["assignee"])),
            catchError(this.catchGlobalError)
        );
    }

    public getAssigneesThatNeedReview(): Promise<Assignee[]> {
        const url = this.getApiPath() + '/reporting/assignee/assignees';
        return this.httpClient.get<Assignee[]>(url, this.getOptions()).toPromise();
    }

    public getAssigneesMinified(): Observable<any[]> {
        const url = this.getApiPath() + '/assignee/assigneesMinified';
        return this.httpClient.get<any[]>(url, this.getOptions());
    }

    public getDocumentsByAssignee(assigneeId: number): Observable<Document[]> {
        const url = this.getApiPath() + '/document/documentsByAssignee/' + assigneeId;
        return this.httpClient.get<Document[]>(url, this.getOptions());
    }

    public sendCredentials(assigneeId: number): Observable<boolean> {
        const url = this.getApiPath() + '/assignee/sendCredentials';
        return this.httpClient.post<boolean>(url, { id: assigneeId }, this.getOptions());
    }

    public confirmUser(assigneeId: number) {
        const url = this.getApiPath() + '/assignee/confirmUser';
        return this.httpClient.post<boolean>(url, { id: assigneeId }, this.getOptions());
    }

    public sendInspectionNotification(assigneeId: number, phone: string) {
        const url = this.getApiPath() + '/assignee/sendInspectionNotification';
        return this.httpClient.post<boolean>(
            url,
            { id: assigneeId },
            this.getOptions({
                phone,
            })
        );
    }

    public getVisibleExempted(): Observable<boolean> {
        const url = this.getApiPath() + '/assignee/visibleExempted';

        return this.httpClient.get<boolean>(url, this.getOptions());
    }

    public saveAssignee(assignee: Assignee): Observable<Assignee> {
        const url = this.getApiPath() + '/assignee';
        const options = this.getOptions();

        if (assignee.id) {
            //console.log("Update assignee", assignee)
            return this.httpClient.put<Assignee>(url, assignee, options).pipe(map((data) => new Assignee(data)));
        }
        //console.log("Create assignee", assignee)
        return this.httpClient.post<Assignee>(url, assignee, options).pipe(map((data) => new Assignee(data)));
    }

    public getAssigneeOfLoggedInUser(): Observable<Assignee> {
        const url = this.getApiPath() + '/assignee/assigneeOfLoggedInUser';
        const options = this.getOptions();

        return this.httpClient.get<Assignee>(url, options).pipe(map((data) => new Assignee(data)));
    }

    public isTimerecordOutOfPeriod(params: { id: number; startDate: Date; endDate: Date }): Observable<boolean> {
        const url = this.getApiPath() + '/assignee/timerecordOutOfPeriod';
        const options = this.getOptions();

        return this.httpClient.post<boolean>(url, params, options).pipe();
    }

    public rejectChangeRequest(assigneeId: number, explanation: string) {
        const url = this.getApiPath() + '/assignee/rejectChangeRequest';

        return this.httpClient
            .post<boolean>(
                url,
                { id: assigneeId },
                {
                    params: {
                        rejectionMessage: explanation,
                    },
                }
            )
            .pipe();
    }

    public getChangesAsMetaData(destination: Assignee, original: Assignee) {
        const meta = JSON.parse(original.metaData) || {};

        const compare = (attributeName: string) => {
            const valueDest = destination[attributeName];
            const valueOrig = original[attributeName];

            let normalizedDest = null;
            let normalizedOrig = null;

            if (valueDest !== null) {
                if (valueDest instanceof Date) {
                    normalizedDest = this.datePipe.transform(valueDest, 'MM/dd/yy');
                } else {
                    normalizedDest = valueDest;
                }
            }

            if (valueOrig !== null) {
                if (valueOrig instanceof Date) {
                    normalizedOrig = this.datePipe.transform(valueOrig, 'MM/dd/yy');
                } else {
                    normalizedOrig = valueOrig;
                }
            }

            if (normalizedDest !== normalizedOrig) {
                meta[attributeName] = normalizedDest;
            }
        };

        compare('firstname');
        compare('lastname');
        compare('email');
        compare('birthday');
        compare('gender');
        compare('street');
        compare('zipCode');
        compare('city');
        compare('country');
        compare('citizenship');
        compare('startDate');
        compare('endDate');
        compare('workPermitFrom');
        compare('workPermitTo');
        compare('visaRpFrom');
        compare('visaRpTo');
        compare('salaryPaymentDeadline');
        compare('workingTimeAustria');
        compare('workingTimeHomeCountry');
        compare('holidayEntitlementAustria');
        compare('holidayEntitlementHomeCountry');

        return meta;
    }

    public saveNotifyAssigneeAboutTimerecords(assigneeId: number, value: boolean) {
        const url = this.getApiPath() + '/assignee/notifyAssigneeAboutTimerecords' + '?notify=' + value;
        return this.httpClient.post<any>(url, {
            id: assigneeId,
        });
    }

    public saveNotifyOrgAdminsAboutTimerecords(assigneeId: number, value: boolean) {
        const url = this.getApiPath() + '/assignee/notifyOrgAdminsAboutTimerecords' + '?notify=' + value;
        return this.httpClient.post<any>(url, {
            id: assigneeId,
        });
    }

    public saveBilledDate(assigneeId: number, billedDate: Date) {
        let dateParam;

        if (billedDate) {
            dateParam = '?billedDate=' + this.mainService.dateToString(billedDate);
        } else {
            dateParam = '';
        }

        const url = this.getApiPath() + '/assignee/assigneeBilledDate' + dateParam;
        return this.httpClient.post<any>(url, {
            id: assigneeId,
        });
    }

    public saveAssigneeSalary(assigneeSalary: AssigneeSalary): Observable<AssigneeSalary> {
        const url = this.getApiPath() + '/assigneeSalary';

        return this.httpClient.post<any>(url, {
            assignee: { id: assigneeSalary.assignee.id },
            salary: assigneeSalary.salary,
            dailyGrossUp: assigneeSalary.dailyGrossUp,
            endDate: assigneeSalary.endDate,
        });
    }

    public getAssigneeSalaries(assigneeId: number) {
        //return of([]); // TODO: REMOVE THIS

        const url = this.getApiPath() + '/assigneeSalary/assigneeSalaries/' + assigneeId;
        return this.httpClient.get<AssigneeSalary[]>(url);
    }

    public deleteSalary(salaryId) {
        return this.httpClient.delete(this.getApiPath() + '/assigneeSalary/' + salaryId, this.getOptions());
    }
    public saveHowToData(howTo) {
        return this.httpClient.post<any>(
            this.getApiPath() + '/assignee/saveAssigneeHowToData',
            howTo,
            this.getOptions()
        );
    }

    public archiveAssignee(id) {
        return this.httpClient.get(`${this.getApiPath()}/assignee/archive/${id}`, this.getOptions());
    }

    public deleteAssignee(id) {
        return this.httpClient.delete(this.getApiPath() + '/assignee/' + id, this.getOptions());
    }

    public deleteMongoAssignee(id) {
        return this.httpClient.delete(this.getApiPath() + '/reporting/assignee/delete/' + id, this.getOptions());
    }

    public getCategory(assignee) {
        const mode = assignee.userInfo && assignee.userInfo.reportingModeInfo ? assignee.userInfo.reportingModeInfo.reportingMode : ""
        if (!mode) {
            return "-"
        }
        return mode.replace(" - Category", "<br>Category").replace(" – Category", "<br>Category")
    }

    public getBgColorByState(assignee) {
        if (!assignee) {
            return ''
        }
        if (assignee.exempted) {
            return '#ff00001a'
        }
        if (assignee.alienActOnly) {
            return '#36a8ff1a'
        }
        if (assignee.userInfo && assignee.userInfo.reportingModeInfo && assignee.userInfo.reportingModeInfo.extensionFile) {
            return 'aliceblue'
        }
        if (document.referrer && document.referrer.includes("/assignee")) {
            const split = document.referrer.split("/")
            const id = split[split.length - 1]
            if (assignee && assignee.id === Number(id)) {
                return '#32a86b1a'
            } 
        }
        return ''
    }
}
