import { HrAgent } from './hr-agent';
export class ReceivingEntity {
    public id: number;
    public name: string;
    public street: string;
    public zipCode: string;
    public city: string;
    public country: string;
    public logoFileName: string;
    public hrAgents: HrAgent[];
    public company: any;

    public constructor() {
        this.company = {
            representationOfInterests: ""
        }
    }
}
