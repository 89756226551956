import { MessageService } from 'src/app/services/messageService';
import { DocumentService } from './../../../../services/document.service';
import { Assignee } from './../../../../models/assignee';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalComponent } from './../../../modal/modal.component';
import { Component, OnInit, ViewChild, Input } from '@angular/core';

@Component({
    selector: 'app-send-doc',
    templateUrl: './send-doc.component.html',
    styleUrls: ['./send-doc.component.scss']
})
export class SendDocComponent implements OnInit {

    public formGroup: FormGroup;

    @ViewChild(ModalComponent, {
        static: true
    })
    private modal: ModalComponent;

    @Input()
    public assignee: Assignee;

    constructor(
        private formBuilder: FormBuilder,
        private documentService: DocumentService,
        private messageService: MessageService
    ) {
        this.formGroup = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            subject: ['', [Validators.required]],
            message: ['', [Validators.required]]
        });
    }

    ngOnInit() {
    }

    public show() {
        this.modal.show();
    }

    public clickedCancel() {
        this.modal.hideModal();
    }

    public clickedSend() {
        const values = this.formGroup.value;
        this.documentService.sendDocuments(this.assignee.id, values.email, values.subject, values.message).subscribe(() => {
            this.modal.hideModal();
            this.messageService.basicToast('Email has been sent successfully.');
        });
    }

}
