import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tv-button',
  templateUrl: './tv-button.component.html',
  styleUrls: ['./tv-button.component.scss', '../../css/webflow.css', '../../css/oberhammer-payslip.webflow.css'],
  encapsulation: ViewEncapsulation.None
})
export class TvButtonComponent implements OnInit {

  @Input("accepted")
  accepted: boolean;

  @Input("text")
  text: string;

  constructor() {
  }

  ngOnInit() {
  }

}
