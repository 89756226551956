import { UserGroup } from './user-group';
import { Deserializable } from './deserializable.model';

export class User implements Deserializable {

    public id: number;
    public username: string;
    public firstname: string;
    public lastname: string;
    public email: string;
    public password: string;
    public userGroups: UserGroup[];
    public allPermissions: any[];
    public assignee: any;

    deserialize(input: any): this {

        Object.assign(this, input);

        if (!input.userGroups) {
            this.userGroups = [];
        } else {
            this.userGroups = input.userGroups.map(userGroup => new UserGroup().deserialize(userGroup));
        }

        return this;
    }

}
