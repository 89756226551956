import { AbsencePeriod } from './../../../models/absence-period';
import { ModalComponent } from './../../modal/modal.component';
import { TimeRecordService } from './../../../services/time-record.service';
import { TimeRecord } from './../../../models/time-record';
import { Document } from './../../../models/document';
import { DocumentTypeMap } from 'typings';
import { MessageService } from './../../../services/messageService';
import { MainService } from 'src/app/services/main.service';
import { AppData } from './../../../services/app-data.service';
import { AssigneeService } from './../../../routes/assignee/assignee.service';
import { ProjectService } from './../../../routes/project/project.service';
import { Project } from 'src/app/models/project';
import { orderBy } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Assignee } from 'src/app/models/assignee';
import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { SendingEntity } from 'src/app/models/sending-entity';
import { SendingEntityService } from 'src/app/routes/sending-entity/sending-entity.service';
import { ReceivingEntity } from 'src/app/models/receiving-entity';
import { ReceivingEntityService } from 'src/app/routes/receiving-entity/receiving-entity.service';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { DocumentService } from 'src/app/services/document.service';
import { DocumentType } from 'src/app/models/document-type';
import { DocumentTypeElement } from 'src/app/models/document-type-element';
import { TimeRecordsService } from './time-records.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-assignee-time-records',
    templateUrl: './time-records.component.html',
    styleUrls: ['./time-records.component.scss'],
})
export class TimeRecordsComponent implements OnInit, OnChanges {
    @Input()
    public assignee: Assignee;

    public supportsITT = false;

    public busy = false;
    public howToData;

    @ViewChild('fillTimeRecords', {
        static: true,
    })
    public fillTimeRecordsModal: ModalComponent;

    @ViewChild('fillAbsencePeriod', {
        static: true,
    })
    public fillAbsencePeriodModal: ModalComponent;
    public absencePeriodForm;
    public fillForm;
    public absenceOptions = [
        { value: 'notInAustria', text: 'Not physically present in Austria / working outside of Austria' },
        { value: 'leave', text: 'On leave / holiday' },
        { value: 'sick', text: 'Sick day' },
    ];

    public absenceError = false;
    public absenceSubmit = false;
    public openMe = false;

    public allOverTimeComfirmed = false;

    public disabledDates: Date[] = [];
    public howToUseText;
    constructor(
        private formBuilder: FormBuilder,
        private sendingEntityService: SendingEntityService,
        private receivingEntityService: ReceivingEntityService,
        private projectService: ProjectService,
        private assigneeService: AssigneeService,
        public appData: AppData,
        private mainService: MainService,
        private messageService: MessageService,
        private documentService: DocumentService,
        public trData: TimeRecordsService,
        private timeRecordService: TimeRecordService,
        private userService: UserService
    ) {
        this.absencePeriodForm = this.formBuilder.group({
            from: [null, [Validators.required]],
            till: [null, [Validators.required]],
            absence: [null, [Validators.required]],
        });

        this.fillForm = this.formBuilder.group({
            start: [null, [Validators.required]],
            end: [null, [Validators.required]],
            break: [null],
        });
        try {
            const input = document.createElement('input');
            input.type = 'time';

            if (input.type === 'time') {
                this.supportsITT = false;
            } else {
                this.supportsITT = false;
            }
        } catch (e) {
            this.supportsITT = false;
        }
        this.processHowToData('timerecords');
        this.AllOverTimeComplaint();
        this.getNationalHolidays();
        this.howToUseText = this.appData.getHowToData("records");
    }

    ngOnInit() {}

    ngOnChanges() {
        if (this.assignee) {
            const storedAssignee = this.trData.assignee;
            const currentAssignee = this.assignee;

            if (currentAssignee) {
                if (storedAssignee) {
                    if (currentAssignee.id !== storedAssignee.id) {
                        this.trData.setAssignee(currentAssignee);
                    }
                } else {
                    this.trData.setAssignee(currentAssignee);
                }
            } else {
                this.trData.setAssignee(currentAssignee);
            }
        }
        if (this.trData.currentSelectionMonth) {
            this.onMonthChange(this.trData.currentSelectionMonth);
            this.AllOverTimeComplaint();
        }
        this.AllOverTimeComplaint();
    }

    private getNationalHolidays() {
        const today = new Date();

        const selectionStart = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);
        const selectionEnd = new Date(today.getFullYear(), 11, 31, 0, 0, 0, 0);
        this.timeRecordService.getHolidayDaysBetweenDates(selectionStart, selectionEnd).subscribe((dates) => {
            // console.log(dates);
            const trsarray: Date[] = [];
            for (const element in dates) {
                if (dates.hasOwnProperty(element)) {
                    trsarray.push(dates[element]);
                }
            }
            // console.log(trsarray);
            this.disabledDates = trsarray;
        });
    }
    public detectIsDateDisabled(date) {
        const found = this.disabledDates.find((entry) => entry.getTime() === date.getTime());

        if (found) {
            return true;
        }
        return false;
    }

    public onMonthChange(value) {
        const date = value.date;
        this.trData.currentSelectionMonth = value;
        this.trData.initTimeRecordsMonth(date.getFullYear(), date.getMonth());
        this.AllOverTimeComplaint();
    }

    public formatMillis(milis: number) {
        const secNum = Math.floor(milis / 1000);

        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor((secNum - hours * 3600) / 60);

        return (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm' : '') + '';
    }

    public onBlur(tr: TimeRecord) {
        this.prepareTimeRecord(tr);
    }

    public onBlurTime(tr: TimeRecord) {
        if (tr.beginTime) {
            const hhmm = tr.beginTime.split(':');
            tr.beginTimeDt = new Date();
            tr.beginTimeDt.setHours(parseInt(hhmm[0], 10), parseInt(hhmm[1], 10), 0, 0);
        }
        if (tr.endTime) {
            const hhmm = tr.endTime.split(':');
            tr.endTimeDt = new Date();
            tr.endTimeDt.setHours(parseInt(hhmm[0], 10), parseInt(hhmm[1], 10), 0, 0);
        }
        this.prepareTimeRecord(tr);
    }

    private prepareTimeRecord(tr) {
        const begin = tr.beginTimeDt;
        const end = tr.endTimeDt;
        const pauseMinutes = tr.pause || 0;

        let beginTime;
        let endTime;

        if (begin) {
            beginTime =
                this.mainService.leadingZero(begin.getHours()) + ':' + this.mainService.leadingZero(begin.getMinutes());
        }

        if (end) {
            endTime =
                this.mainService.leadingZero(end.getHours()) + ':' + this.mainService.leadingZero(end.getMinutes());
        }

        tr.beginTime = beginTime;
        tr.endTime = endTime;

        let diffMilli;

        if (!end || !begin) {
            return;
        }

        diffMilli = end.getTime() - begin.getTime() - pauseMinutes * 60 * 1000;

        if (diffMilli < 0) {
            this.busy = true;
            this.mainService.prompt(
                'Did you work overnight, past midnight?',
                (val, action) => {
                    this.busy = false;
                    if (action === 'yes') {
                        this.saveTimeRecord(tr);
                    }
                },
                'confirm'
            );
        } else {
            this.saveTimeRecord(tr);
        }
        this.AllOverTimeComplaint();
    }

    private resetTimeRecord(tr: TimeRecord) {
        tr.beginTime = null;
        tr.endTime = null;
        tr.beginTimeDt = null;
        tr.endTimeDt = null;
        tr.pause = null;
        tr.hoursWorked = null;
        tr.onLeave = false;
        tr.exempt = false;
        tr.sick = false;
    }

    public onChangeCheckbox(event, type: 'exempt' | 'onLeave' | 'sick', tr: TimeRecord) {
        const msg = 'You have already recorded a time entry. Are you sure you want to override this?';
        const types = ['exempt', 'onLeave', 'sick'];
        const finalValue: boolean = tr[type];

        tr.optionsOpen = false;

        if (tr.beginTime || tr.endTime || tr.pause) {
            if (!tr[type]) {
                this.saveTimeRecord(tr);
                return;
            }

            this.mainService.prompt(
                msg,
                (val, action) => {
                    if (action === 'yes') {
                        this.resetTimeRecord(tr);
                        types.forEach((t) => {
                            tr[t] = t === type ? finalValue : false;
                        });

                        this.saveTimeRecord(tr);
                    } else {
                        tr[type] = !finalValue;
                    }
                },
                'confirm'
            );
        } else {
            types.forEach((t) => {
                tr[t] = t === type ? finalValue : false;
            });

            let allDisabled = true;
            for (const t of types) {
                allDisabled = allDisabled && !tr[t];
            }

            if (allDisabled) {
                tr.loading = true;
                this.timeRecordService.deleteTimeRecord(tr.id).subscribe(
                    (savedTr) => {
                        tr.loading = false;
                        tr.id = null;
                    },
                    () => {
                        tr.loading = false;
                    }
                );
            } else {
                this.saveTimeRecord(tr);
            }
        }
    }

    private saveTimeRecord(tr: TimeRecord) {
        tr.loading = true;
        this.busy = true;

        this.timeRecordService.saveTimeRecord(tr).subscribe(
            (savedTr) => {
                this.busy = false;
                tr.loading = false;
                tr.id = savedTr.id;
                tr.hoursWorked = savedTr.hoursWorked;
                tr.workedTimeInMillis = savedTr.workedTimeInMillis;
                this.trData.calcWeekSummary();
            },
            (err) => {
                this.busy = false;
                tr.loading = false;

                if (err.error.content.message) {
                    const message = err.error.content.message;
                    this.messageService.basicToast(message, 'error');
                }
                if (err.error.content.extraData && !err.error.content.extraData.handleable) {
                    return;
                }

                if (err.error.content.type === 'support.comic.exceptions.MaxDailyTimeExceededException') {
                    this.mainService.prompt(
                        err.error.content.message,
                        (val, action) => {
                            if (action === 'yes') {
                                tr.saveWithWorkedTimeOverMaxPerDay = true;
                                this.saveTimeRecord(tr);
                            } else {
                                tr.beginTime = null;
                                tr.endTime = null;
                                tr.beginTimeDt = null;
                                tr.endTimeDt = null;
                                tr.hoursWorked = null;
                            }
                        },
                        'confirm'
                    );
                }
            }
        );
    }

    public onClickFillAbsencePeriod() {
        this.fillAbsencePeriodModal.show();
    }

    public exportPdfAll() {
        window.location.href = '/comic/timerecords?assigneeId=' + this.trData.assignee.id;
    }

    public exportPdfMonth() {
        const firstDate = this.trData.firstDate;
        window.location.href =
            '/comic/timerecords?assigneeId=' +
            this.trData.assignee.id +
            '&year=' +
            firstDate.getFullYear() +
            '&month=' +
            firstDate.getMonth();
    }

    public onChangeNotifyAssigneeAboutTimerecords(state: boolean) {
        this.assigneeService.saveNotifyAssigneeAboutTimerecords(this.trData.assignee.id, state).subscribe(() => {
            this.assignee.notifyAssigneeAboutTimerecords = state;
        });
    }

    public onChangeNotifyOrgAdminsAboutTimerecords(state: boolean) {
        this.assigneeService.saveNotifyOrgAdminsAboutTimerecords(this.trData.assignee.id, state).subscribe(() => {
            this.assignee.notifyOrgAdminsAboutTimerecords = state;
        });
    }

    public onClickFillTimeRecord() {
        this.fillTimeRecordsModal.show();
    }

    public onClickDeleteAbsencePeriod(absencePeriod: AbsencePeriod) {
        this.timeRecordService
            .deleteAbsenceTimeRecords(
                this.assignee.id,
                absencePeriod.startDate,
                absencePeriod.endDate,
                absencePeriod.absenceType as any
            )
            .subscribe(
                (success) => {
                    this.trData.setAssignee(this.assignee);
                },
                (error) => {}
            );
    }

    public async onChangeOvertimeComplaint(week: number) {
        const trs = this.trData.timeRecordsMonth;
        const trCount = trs.length;
        const overtimeCompliantValue = this.trData.weekSummary[week].overtimeCompliant;

        for (let i = 0; i < trCount; i++) {
            if (trs[i].week === week) {
                trs[i].overtimeCompliant = overtimeCompliantValue;
                if (trs[i].id) {
                    await this.timeRecordService.saveTimeRecord(trs[i]).toPromise();
                }
            }
        }
        this.AllOverTimeComplaint();
    }
    public AllOverTimeComplaint() {
        const trsarray = [];
        for (var element in this.trData.weekSummary) {
            if (this.trData.weekSummary.hasOwnProperty(element)) {
                if (this.trData.weekSummary[element].sumOvertimeMillis > 0) {
                    trsarray.push(this.trData.weekSummary[element]);
                }
            }
        }
        if (trsarray) {
            let isFalse = this.detectOvertimeComplaint(trsarray);
            if (isFalse === true) {
                this.trData.sumOvertimeCompliant = true;
            } else {
                this.trData.sumOvertimeCompliant = false;
            }
        }
    }

    private detectOvertimeComplaint(trsarray) {
        let falseone = false;
        let isFalse = trsarray.find((week) => !week['overtimeCompliant']);
        if (isFalse) {
            return false;
        } else {
            return true;
        }
    }

    public isclickedOne(tr) {
        // console.log(tr);
    }

    public submitAbsence() {
        this.absenceSubmit = true;
        const formData = this.absencePeriodForm.getRawValue();
        this.timeRecordService
            .fillAbsenceTimeRecords(this.assignee.id, formData.from, formData.till, formData.absence)
            .subscribe(
                (success) => {
                    // toast that it was success full
                    this.messageService.basicToast('Time records has been filled successfully.');
                    this.absenceSubmit = false;
                    this.fillAbsencePeriodModal.hideModal();
                    this.trData.setAssignee(this.assignee);
                },
                (error) => {
                    this.absenceSubmit = false;
                }
            );
    }

    public fillTimeRecordsFunction() {
        const fillValues = this.fillForm.value;
        this.timeRecordService
            .fillTimeRecords(this.assignee.id, fillValues.start, fillValues.end, fillValues.break)
            .subscribe(
                (res) => {
                    this.messageService.basicToast('Time records has been filled successfully.');
                    // refresh the data
                    const date = this.trData.currentSelectionMonth['date'];
                    this.trData.initTimeRecordsMonth(date.getFullYear(), date.getMonth());
                    this.fillTimeRecordsModal.hideModal();
                },
                (error) => {
                    this.messageService.basicToast('Time records could not be filled successfully.', 'error');
                    this.fillTimeRecordsModal.hideModal();
                }
            );
    }

    selectedTabIndex: number = 0;
    selectedIndexChange(index: number) {
        this.selectedTabIndex = index;
    }
    public OnFocusOut(e) {
        // const inputChar = String.fromCharCode(e.charCode);

        let el = e.srcElement;

        if (e.charCode !== 13) {
            // invalid character, prevent input
            e.preventDefault();
        }
        const currentElement = e; // ID set by OnFOcusIn
        const curIndex = currentElement.tabIndex; // get current elements tab index
        // if (curIndex == lastTabIndex) { // if we are on the last tabindex, go back to the beginning
        //         curIndex = 0;
        // }
        const tabbables = document.querySelectorAll('input'); // get all tabable elements
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < tabbables.length; i++) {
            // loop through each element
            if (tabbables[i].tabIndex == curIndex + 1) {
                // check the tabindex to see if it's the element we want
                tabbables[i].focus(); // if it's the one we want, focus it and exit the loop
                break;
            }
        }
    }
    public onClickOpenModal() {
        this.openMe = true;
        // this.fillAbsencePeriodModal.hideModal();
    }
    public onCloseOpenModal() {
        this.openMe = false;
    }
    private processHowToData(howToName) {
        if (!this.appData.loggedInAssignee||!this.appData.loggedInUser['assignee']) {
            return;
        }
        this.howToData = this.appData.loggedInUser['assignee'].howToData;
        this.howToData = this.howToData
            ? JSON.parse(this.howToData)
            : { home: false, profile: false, documents: false, timerecords: false, payslips: false };

        const assigneeId = this.appData.loggedInUser['assignee'].id;

        if (this.howToData[howToName]) {
            return;
        }
        this.howToData[howToName] = true;
        this.onClickOpenModal();
        // this.saveHowTo = true;

        const buildsave = {
            id: assigneeId,
            howToData: JSON.stringify(this.howToData),
        };

        this.saveHowToData(buildsave);
    }

    private async saveHowToData(howTo: any) {
        await this.assigneeService.saveHowToData(howTo).toPromise();
        await this.userService.getCurrentUserData();
    }
}
