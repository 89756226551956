import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-info-button',
    templateUrl: './info-button.component.html',
    styleUrls: ['./info-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InfoButtonComponent implements OnInit {
    @Input('html')
    html: string;
    @Input('show')
    showOverlay = false;
    @Output() callback: EventEmitter<any> = new EventEmitter();
    @Input('buttonText')
    buttonText: string = 'How to Use'

    constructor() {}

    ngOnInit() {}

    public onClickOpenModal() {
        this.showOverlay = true;
        // this.fillAbsencePeriodModal.hideModal();
    }
    public onCloseOpenModal() {
        this.showOverlay = false;
        this.callback.emit();
    }
}
