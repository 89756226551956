export default {
    home: `Welcome to the online portal of our Corporate Migration Tool. 
    <br>
    <br>
    This start page provides you with a general overview about this assignment, alerts you to missing data which need to be provided in order to keep the employer compliant with legal requirements. You find also an option to send or download all documents provided for this account and assignment period which are kept at display for the case of an inspection by the immigration police. 
    <br>
    <br>
    Please DO NOT utilize the “send”-function to send documents to the authorities prior to consulting with our team and/or your employer. 
    <br>
    <br>
    !!! The KIAB ALERT button serves to inform your employer and our team that you are involved in a control by authorities, i.e. the immigration police, tax authorities or social security authorities (or a combination of these). Such a control can normally happen at the place of deployment (at the site where you are deployed on assignment). If you are involved in such a control, please press the KIAB ALERT button, enter a phone number at which you can be reached and either your employer or our team will get in touch with you on short notice to assist you. 
    <br>
    <br>
    To avoid “false alarms” upon pressing the KIAB ALERT button you will be prompted to confirm by pressing a button “send notification” and prior to sending the notification you can provide your phone number at which you can be reached at that moment. 
    <br>
    <br>
    Your corporate migration center team.
    `,
    profile: `This page shows the data which has been entered and reported for this assignment. Please enter any changes you may require/suggest and press the “request changes” button. The requests will be reviewed by our team or your employer. 
    <br>
    <br>
    Your corporate migration center team.
    `,
    documents: `This page shows a list of documents which need to be readily available during controls by the immigration police and which are of a nature that they need to be provided once (typically that can be your Assignment Agreement, Secondment Agreement, KIAB/ZKO form, the A1 Social Insurance Certificate, etc.) 
    <br>
    <br>
    !!! Please make sure to upload all required documents in a timely manner. Once you have uploaded a document, it will be reviewed. In case of rejection, an explanation will be provided. !!!
    <br>
    <br>
    If the requested general documents are not fully uploaded, regular reminders will be sent to the responsible persons by e-mail requesting the completion of this task. 
    <br>
    <br>
    Your corporate migration center team.
    `,
    records: `The employer is required to maintain daily time records for every day on which a posted employee is actually deployed to Austria (i.e. in-country days) during the assignment period. 
    <br>
    <br>
    Please insert the start time (START) and end time (END) as well as breaks (in minutes) at the end of each working day.
    <br>
    <br>
    .- 	For days in which the employee is not physically in Austria, please click “Not physically present in Austria / working outside of Austria”. <br>
    .- 	For holidays during the assignment period, please click “On leave / holiday”.<br>
    .- 	If the employee is sick during the assignment period, please click “Sick day”.<br>
    .- 	In case of a planned absence period, an absence period can be defined in advance by using the check-box “Fill Absence Period”.  <br>
    <br>
    <br>
    !!! Please make sure to keep the time records up to date. Even if the working time corresponds to a fixed time frame and no extra-hours are worked please fill out the time records including the total time for breaks. !!!
    <br>
    <br>
    For convenience purposes you can enter the time record on your mobile device by downloading the COMIC GO application – see the COMIC GO APP MANUAL which can downloaded on ‘the top right corner’ of this sub-page.
    <br>
    <br>
    Based on the entries a PDF-File can be generated reflecting the entered working time. Such file may have to be provided or sent to the immigration police upon a respective request. 
    <br>
    <br>
    If the entered time records are not up to date, regular reminders will be sent by e-mail requesting the completion of this task. 
    <br>
    <br>
    Your corporate migration center team.
    `,
    payslips: `It is required to maintain salary and wage related information for each month of the assignment. This page collects salary and wage related documents for each month of the assignment.
    <br>
    <br>
    Once the payslip has been uploaded in the required language(es), please process to section II and upload the payment confirmation. If you upload the bank-transfer-statement, this task is completed and the employer and employee confirmations are not required. If a bank-transfer-statement cannot be uploaded, please use the employer and employee confirmations (see section III) below, have them signed and upload them in the respective boxes in this section II.
    <br>
    <br>
    <span style="color: red;">Attention</span>: the amounts stated in the comic pre-generated employer/employee declarations correspond to the data provided to the system and such data must correspond to the data shown on the payslip. If not, the comic pre-generated documents cannot be used and declarations stating the correct amounts must be created, signed and uploaded. If such case happens, please contact the corporate migration center team as soon as possible as in such case it is likely the changes to the reported information have not been communicated to the system und to the authorities.
    <br>
    <br>
    This task is completed once the information in the boxes I. II. and IV. are uploaded.  
    <br>
    <br>
    !!! Please make sure to upload the requested documents for every month of the assignment as soon as they are due. By uploading the documents, you confirm that the declared remuneration has been actually paid/received as declared to the Austrian immigration authorities. !!! 
    <br>
    <br>
    <b>If the documents requested on this page are not fully provided, regular reminders will be sent to the responsible persons by e-mail requesting the completion of this task. </b>
    <br>
    <br>
    Your corporate migration center team.
    `
}