import { Payslip } from "./payslip";

export class PayslipEntry {

    constructor(data?: any) {

        if (data) {
            Object.assign(this, data);

            if (this.fileUploadDate) {
                this.fileUploadDate = new Date(this.fileUploadDate);
            }
        }
    }

    public id: number;
    public name: string;
    public label: string;
    public staticUrl: string;
    public isForEmployer: boolean;
    public required: boolean;
    public fileName: string;
    public fileUploadDate: Date;
    public payslip: Payslip;
}
