import { TooltipComponent } from './tooltip.component';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Directive, Input, ElementRef, OnDestroy, HostListener,
    ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef,
    ReflectiveInjector, ViewContainerRef, Renderer2, TemplateRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
    selector: '[appTooltip]'
})
export class TooltipDirective implements OnDestroy {

    private content;
    public toolElRef;
    public toolElDom;
    private subscription: Subscription;
    private _tooltipEvent: 'hover' | 'click' = 'hover';

    constructor(
        private el: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private resolver: ComponentFactoryResolver,
        private renderer: Renderer2,
        private vcr: ViewContainerRef,
        @Inject(PLATFORM_ID) private platformId
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.subscription = fromEvent(window, 'resize').pipe(
                debounceTime(500)
            ).subscribe(() => {
                this.repositionTooltip();
            });
        }
    }

    @Input('appTooltip')
    set tooltip(tooltip) {
        this.content = tooltip;
    }

    @Input('tooltip-event')
    set tooltipEvent(tooltipEvent: 'hover' | 'click') {
        this._tooltipEvent = tooltipEvent;
    }

    @HostListener('click')
    private onClick() {
        if (this._tooltipEvent !== 'click') {
            return;
        }

        if (this.toolElRef) {
            this.ngOnDestroy();
        } else {
            this.appendComponent();
        }
    }

    @HostListener('document:touchstart', ['$event'])
    @HostListener('document:click', ['$event'])
    private clickout(event) {
        if (this._tooltipEvent !== 'click') {
            return;
        }

        const target = event.target;

        if (this.toolElDom && this.toolElDom.contains(target)) {
            return;
        }

        if (this.el.nativeElement.contains(target)) {
            return;
        }
        this.ngOnDestroy();
    }

    @HostListener('touchstart')
    private onTouchstart() {
        if (this._tooltipEvent !== 'hover') {
            return;
        }

        if (this.toolElRef) {
            this.ngOnDestroy();
        } else {
            this.renderer.setStyle(this.el.nativeElement, '-webkit-user-select', 'none');
            this.appendComponent();
        }
    }

    @HostListener('mouseenter')
    private onMouseenter() {
        if (this._tooltipEvent !== 'hover') {
            return;
        }

        if (this.toolElRef) {
            this.ngOnDestroy();
        } else {
            this.appendComponent();
        }
    }

    @HostListener('mouseleave')
    @HostListener('touchend')
    private onMouseleave() {
        if (this._tooltipEvent !== 'hover') {
            return;
        }

        setTimeout(() => {
            this.renderer.setStyle(this.el.nativeElement, '-webkit-user-select', '');
            this.ngOnDestroy();
        }, 50);
    }

    private appendComponent() {
        const main = document.getElementById('main');
        const factory = this.resolver.resolveComponentFactory(TooltipComponent);

        const injector = Injector.create({
            providers: [{
                provide: 'tooltipConfig',
                useValue: {
                    host: this.el.nativeElement
                }
            }]
        });
        this.toolElRef = this.vcr.createComponent<TooltipComponent>(factory, 0, injector, this.generateNgContent());

        this.toolElDom = (this.toolElRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        main.appendChild(this.toolElDom);
        this.repositionTooltip();
    }


    generateNgContent() {
        if (typeof this.content === 'string') {
            const el = document.createElement('div');
            el.innerHTML = this.content;
            return [[el]];
        }

        if (this.content instanceof TemplateRef) {
            const context = {};
            const viewRef = this.content.createEmbeddedView(context);
            return [viewRef.rootNodes];
        }
    }

    private repositionTooltip() {
        const toolEl = this.toolElDom;
        if (!toolEl) {
            return;
        }

        const main = document.getElementById('main-content');
        const el = this.el.nativeElement;

        const offsets = this.getOffset(el, main);
        toolEl.style.top = offsets.y + 'px';
        toolEl.style.left = (offsets.x + el.offsetWidth / 2) + 'px';

        const toolPosition = this.toolElRef.location.nativeElement.getBoundingClientRect();

        if (toolPosition.x < 0) {
            toolEl.style.left = (toolPosition.width / 2) + 'px';
            // set pseudo style
            const triggerPosition = el.getBoundingClientRect();
            const relativeTriggerMarker = triggerPosition.left + triggerPosition.width / 2;
            if (toolEl.childNodes[0]) {
                toolEl.childNodes[0].style.left = relativeTriggerMarker + 'px';
            }
            if (toolEl.childNodes[2]) {
                toolEl.childNodes[2].style.left = relativeTriggerMarker + 'px';
            }
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.toolElRef) {
            this.renderer.setStyle(this.toolElDom, 'opacity', '0');
            this.appRef.detachView(this.toolElRef.hostView);
            this.toolElRef.destroy();
            this.toolElRef = undefined;
        }
    }

    private getOffset(el, maxParent?) {
        let x = 0;
        let y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            if (el === maxParent) {
                break;
            }
            x += el.offsetLeft - el.scrollLeft;
            y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { y, x };
    }

}
