import { User } from './user';
import { Assignee } from './assignee';

export class Payslip {

    public constructor(data?: any) {
        if (data) {
            Object.assign(this, data);

            if (this.rejectedDate) {
                this.rejectedDate = new Date(this.rejectedDate);
            }

            if (this.date) {
                this.date = new Date(this.date);
            }

            if (this.nonGermanRejectedDate) {
                this.nonGermanRejectedDate = new Date(this.nonGermanRejectedDate);
            }

            if (this.reviewedDate) {
                this.reviewedDate = new Date(this.reviewedDate);
            }

            if (this.fileUploadDate) {
                this.fileUploadDate = new Date(this.fileUploadDate);
            }
        }
    }

    public id: number;
    public assignee: Assignee;
    public fileName: string;
    public date: Date;
    public year: number;
    public month: number;
    public rejectedDate: Date;
    public rejectReason: string;
    public nonGermanRejectedDate: Date;
    public nonGermanRejectedReason: string;
    public reviewedDate: Date;
    public reviewedBy: User;
    public confirmedPaymentReceived: boolean;
    public employerConfirmedPaymentSent: boolean;
    public employerConfirmation: string;
    public employeeConfirmation: string;
    public nonGermanPayslip: string;
    public originalBankTransferStatement: string;
    public missing: boolean;
    public wasSetToCompliantInPreviousLayout: boolean;
    public isModel: boolean;
    public accepted: boolean;
    public fileUploadDate: Date;

    public info: Object;

    get isPhantom(): boolean {
        return this.id == null;
    }

    get isMultiLangPayslip(): boolean {
        return this.info && this.info["type"] === 'NON_GERMAN';
    }
}
