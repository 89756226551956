import { Assignee } from './../models/assignee';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { Injectable } from '@angular/core';

import AdminsHowTo from 'src/assets/admins-how-to'
import EmployeesHowTo from 'src/assets/employees-how-to'

@Injectable({
    providedIn: 'root',
})
export class AppData {
    loggedInUser: User = null;
    startPage: string;
    authkey: string;
    myAssignee: any;
    config: any;

    showLoading = false;
    loadingEvents = new Subject<'start' | 'end'>();

    // tenantNamepreset_ = 'comic';
    tenantNamepreset_ = 'accenture';

    permissions = {
        ASSIGNEE_EDIT: false,
        DOCUMENT_EDIT: false,
        ORGANISATION: false,
        HR_AGENT: false,
        PROJECT_ADMIN: false,
        EMPLOYEE: false,
        ASSIGNEE_PAYSLIP_REJECT: false,
        ASSIGNEE_PAYSLIP_UPLOAD: false,
        ASSIGNEE_PAYSLIP_UPLOAD_DOCUMENTS: false,
        EMPLOYER_PAYMENT_SENT_CONFIRM: false,
    };

    isMobileApplicationView = false;
    /**
     * The current logged in assignee, if loggedInUser happens to be an assignee
     */
    loggedInAssignee: Assignee = undefined;

    /**
     * Highest permission (see @this.permissions)
     */
    highestPermission: string;

    constructor() {}

    /**
     * Tells from the users userGroup, if he is an admin in any form
     * @returs `true` or `false`
     */
    userIsAdmin(): boolean {
        if (!this.loggedInUser || !this.loggedInUser.userGroups) {
            return false;
        }
        const isAdmin = this.loggedInUser.userGroups.find(
            (g) =>
                g.name === 'Administrators' ||
                g.name === 'Organisation administrators' ||
                g.name === 'HR-Agents' ||
                g.name === 'Project administrators' ||
                g.name === 'Super administrators'
        );
        return this.loggedInUser && isAdmin != undefined;
    }

    userISSuperAdmin(): boolean {
        return  this.loggedInUser && (this.loggedInUser.userGroups.find(g => g.name === 'Super administrators') != undefined)
    }

    /**
     * Tells from the users userGroup, if he is an assignee
     * @returs `true` or `false`
     */
    userIsAssignee(): boolean {
        const hasUserGroup = this.loggedInUser.userGroups.find((g) => g.name === 'Employees');
        return this.loggedInUser && hasUserGroup != undefined;
    }

    getHowToData(member): String {
        if (this.userIsAdmin()) {
            return AdminsHowTo[member]
        }
        return EmployeesHowTo[member]
    }

    isReportingMode(): Boolean {
        return this.config && this.config.reportingMode
    }
}
