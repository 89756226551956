import { AppData } from './../../services/app-data.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { take } from 'rxjs/operators';
import { User } from 'src/app/models/user';

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
    private id: number;
    public user: User = new User();

    constructor(
        private appData: AppData,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService
    ) {
        this.id = this.route.snapshot.params.id * 1;
    }

    ngOnInit() {
        this.userService.getUser(this.id).subscribe(
            (user) => {
                this.user = user;
            },
            () => {}
        );
    }
    public onTabSelect(evt) {}

    public onClickCancel() {
        this.router.navigateByUrl('/user');
    }
}
