import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /*req = req.clone({
            url: req.url.replace('/comic/', environment.baseUrl),
        });*/
        //console.log('REQ', req);
        return next.handle(req);
    }
}
