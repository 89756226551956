import { User } from './user';
import { Assignee } from 'src/app/models/assignee';
import { DocumentType } from './document-type';

export class Document {
    public id: number;
    public documentType: DocumentType;
    public assignee: Assignee;
    public declinedText: string;
    public fileUploadDate: Date;
    public declinedDate: Date;
    public confirmedDate: Date;
    public fileName: string;
    public customName: string;
    public confirmedBy: User;
    public uploadedBy: User;
    public notifyAssignee: boolean;
    public notifyOrganisationAdmins: boolean;
    public status: string;
    public currentUserMayConfirm: boolean;
}
