import { HrAgent } from './../models/hr-agent';
import { MessageService } from './messageService';
import { BaseHttpService } from './base-http.service';

import { catchError, map, delay, share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { MainService } from './main.service';
import { DocumentType } from '../models/document-type';
import { Document } from '../models/document';
import { DocumentTypeMap } from 'typings';

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends BaseHttpService {

    private tenantName: string;

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService,
        private mainService: MainService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    public sendDocuments(assigneeId: number, email: string, subject: string, message: string): Observable<HrAgent> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/document/sendDocuments';

        return this.httpClient.post<any>(url, { id: assigneeId }, this.getOptions({
            recipientEmail: email,
            subject,
            message
        }));
    }

    public getDocumentTypes(): Observable<DocumentType[]> {
        const url = this.getApiPath() + '/document/documentTypes';
        return this.httpClient.get<DocumentType[]>(url).pipe(
            share()
        );
    }

    public getDocumentTypesMap(): Observable<DocumentTypeMap> {
        return this.getDocumentTypes().pipe(map(dataList => {
            const documentTypes: DocumentTypeMap = {};
            dataList.forEach(data => {

                if (!data.template) {
                    return;
                }

                // template names are comma separated by
                data.template.split(',').forEach(templateName => {

                    if (!documentTypes[templateName]) {
                        documentTypes[templateName] = [];
                    }

                    const documentType: DocumentType = this.mainService.clone(data);
                    documentType.template = templateName;

                    documentTypes[templateName].push(documentType);
                });

            });

            // Sortiert einfach die Positionen in einer bestimmten reihe.
            let arraySorted = {};
            if (documentTypes['EU-Normal']) {
                arraySorted['EU-Normal'] = documentTypes['EU-Normal'];
            }
            if (documentTypes['EU-Vanderelst']) {
                arraySorted['EU-Vanderelst'] = documentTypes['EU-Vanderelst'];

            }
            if (documentTypes['Third Country']) {
                arraySorted['Third Country'] = documentTypes['Third Country'];

            }
            if (documentTypes['Alien Act Only']) {
                arraySorted['Alien Act Only'] = documentTypes['Alien Act Only'];

            }
            if (documentTypes['Alien Act Only - Austria Employee']) {
                arraySorted['Alien Act Only - Austria Employee'] = documentTypes['Alien Act Only - Austria Employee'];

            }

            // return documentTypes;
            return arraySorted;
        }));
    }

    public deleteDocument(documentId: number): Observable<any> {
        return this.httpClient.delete(this.getApiPath() + '/document/' + documentId, this.getOptions());
    }

    public saveDocument(document: Document): Observable<Document> {

        if (this.tenantName === 'accenture') {
            document.notifyAssignee = false;
        }

        if (document.id) {
            return this.httpClient.put<Document>(this.getApiPath() + '/document', document);
        } else {
            return this.httpClient.post<Document>(this.getApiPath() + '/document', document);
        }
    }

    public upload(data) {
        const uploadUrl = `/comic/fileupload`;

        const formData = new FormData();
        formData.append('_upid', '1');
        formData.append(data.name, data);

        return this.httpClient.post<any>(uploadUrl, formData);
    }

    public setTenantName(name: string) {
        this.tenantName = name;
    }
}
