import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-tv-upload',
  templateUrl: './tv-upload.component.html',
  styleUrls: ['./tv-upload.component.scss', '../../css/webflow.css', '../../css/oberhammer-payslip.webflow.css']
})
export class TvUploadComponent implements OnInit {

  @Input("text") 
  text: string;
  @Input("upload") 
  upload: boolean;
  @Input("reject") 
  reject: boolean;
  @Input("review") 
  review: boolean;
  @Output('downloadClicked')
  downloadClicked = new EventEmitter<MouseEvent>();
  @Output('rejectClicked')
  rejectClicked = new EventEmitter<MouseEvent>();
  @Output('markedClicked')
  markedClicked = new EventEmitter<MouseEvent>();
  @Output('onChangeFile')
  onChangeFile = new EventEmitter();

  @Input("downloadText")
  downloadText: string;
  @Input("uploadText")
  uploadText: string;

  constructor() { }

  ngOnInit() {
    if (!this.uploadText) {
      this.uploadText = "UPLOAD"
    }
    if (!this.downloadText) {
      this.downloadText = "V Download"
    }
  }

  downloadClick(event?: MouseEvent) {
    if (event) {
      event.preventDefault()
    }
    this.downloadClicked.emit(event)
    return false;
  } 
  
  rejectClick(event?: MouseEvent) {
    if (event) {
      event.preventDefault()
    }
    this.rejectClicked.emit(event)
    return false;
  }

  reviewedClick(event?: MouseEvent) {
    if (event) {
      event.preventDefault()
    }
    this.markedClicked.emit(event)
    return false;
  } 

  uploadFile(event) {
    this.onChangeFile.emit(event)
  }

}
