import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './special/layout/layout.component';
import { LoginComponent } from './routes/login/login.component';
import { ForgotComponent } from './routes/forgot/forgot.component';
import { AuthGuard } from './guards/auth.guard';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { HomeComponent } from './routes/employee/home/home.component';
import { ProfileComponent } from './routes/employee/profile/profile.component';
import { GeneralDocumentsComponent } from './routes/employee/general-documents/general-documents.component';
import { PayslipsComponent } from './routes/employee/payslips/payslips.component';
import { TimeRecordsComponent } from './routes/employee/time-records/time-records.component';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
    {
        path: 'login',
        canActivate: [IsLoggedInGuard],
        component: LoginComponent,
    },
    {
        path: 'forgot',
        canActivate: [IsLoggedInGuard],
        component: ForgotComponent,
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./routes/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'home',
                canActivate: [AdminGuard],
                component: HomeComponent,
            },
            {
                path: 'profile',
                component: ProfileComponent,
            },
            {
                path: 'general-documents',
                component: GeneralDocumentsComponent,
            },
            {
                path: 'payslips',
                component: PayslipsComponent,
            },
            {
                path: 'time-records',
                component: TimeRecordsComponent,
            },
            // {
            //     path: '',
            //     loadChildren: () => import('./routes/employee/employee.module').then((m) => m.EmployeeModule),
            // },
            {
                path: 'assignee',
                loadChildren: () => import('./routes/assignee/assignee.module').then((m) => m.AssigneeModule),
            },
            {
                path: 'localhires',
                loadChildren: () => import('./routes/localhires/localhire.module').then((m) => m.AssigneeModule),
            },
            {
                path: 'sending-entity',
                loadChildren: () =>
                    import('./routes/sending-entity/sending-entity.module').then((m) => m.SendingEntityModule),
            },
            {
                path: 'receiving-entity',
                loadChildren: () =>
                    import('./routes/receiving-entity/receiving-entity.module').then((m) => m.ReceivingEntityModule),
            },
            {
                path: 'project',
                loadChildren: () => import('./routes/project/project.module').then((m) => m.ProjectModule),
            },
            {
                path: 'organisation-admin',
                loadChildren: () =>
                    import('./routes/organisation-admin/organisation-admin.module').then(
                        (m) => m.OrganisationAdminModule
                    ),
            },
            {
                path: 'userprofile',
                loadChildren: () => import('./routes/userprofile/userprofile.module').then((m) => m.UserProfileModule),
            },
        ],
    },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
