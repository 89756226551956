import { Component, NgZone, Renderer2, ViewChild, ElementRef } from '@angular/core';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError,
    RouterEvent,
} from '@angular/router';
import { AppData } from './services/app-data.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private firstNav = true; // when we remove support for legacy links we can add this to true
    private startTimerId = undefined;
    private startTime;
    private manualLoading = false;

    // Instead of holding a boolean value for whether the spinner
    // should show or not, we store a reference to the spinner element,
    // see template snippet below this script
    @ViewChild('spinnerElement', { static: true })
    private spinnerElement: ElementRef;

    @ViewChild('loadingBar', { static: true })
    private loadingBar: ElementRef;

    constructor(public appData: AppData, private router: Router, private ngZone: NgZone, private renderer: Renderer2) {
        const isBrowser = true; // currently no server side rendering is supported

        this.router.events.subscribe((event) => {
            this.navInterceptor(event);

            if (event instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                // this way if you route to the same link eg. routerLink="/page" the page
                // will load again
                this.router.navigated = false;

                if (isBrowser) {
                    // scroll back to top after "page load"
                    window.scrollTo(0, 0);
                }
            }
        });

        this.appData.loadingEvents.subscribe((evt) => {
            if (evt === 'start') {
                this.keepLoadingManually();
            }

            if (evt === 'end') {
                this._hideSpinner();
            }
        });
    }

    /**
     * Returns the current timestamp
     */
    private getCurrentTime() {
        return new Date().getTime();
    }

    private _hideSpinner(immediately = false): void {
        this.startTime = undefined;
        this.manualLoading = false;

        // We wanna run this function outside of Angular's zone to
        // bypass change detection,
        this.ngZone.runOutsideAngular(() => {
            if (this.startTimerId !== undefined) {
                clearTimeout(this.startTimerId);
                this.startTimerId = undefined;
            }

            if (immediately) {
                this.renderer.setStyle(this.spinnerElement.nativeElement, 'transition', 'opacity 0s ease-out');
                this.renderer.setStyle(this.spinnerElement.nativeElement, 'opacity', '0');
                return;
            }

            this.renderer.setStyle(this.spinnerElement.nativeElement, 'transition', 'opacity 0.5s ease-out');
            this.renderer.setStyle(this.spinnerElement.nativeElement, 'opacity', '0');

            this.renderer.setStyle(this.loadingBar.nativeElement, 'transition', 'transform 0s');
            this.renderer.setStyle(this.loadingBar.nativeElement, 'transform', 'scaleX(1)');
        });
    }

    private keepLoadingManually() {
        this.manualLoading = true;

        if (this.startTime) {
            return;
        }

        this._showSpinner();
    }

    private hideSpinner(immediately = false): void {
        if (immediately) {
            this._hideSpinner(true);
            return;
        }

        const rest = Math.max(300 - (this.getCurrentTime() - this.startTime), 30);

        setTimeout(() => {
            if (this.manualLoading) {
                return;
            }

            this._hideSpinner();
        }, rest);
    }

    private _showSpinner() {
        // We wanna run this function outside of Angular's zone to
        // bypass change detection
        this.ngZone.runOutsideAngular(() => {
            this.startTime = this.getCurrentTime();

            this.renderer.setStyle(this.loadingBar.nativeElement, 'transition', 'transform 0s');
            this.renderer.setStyle(this.loadingBar.nativeElement, 'transform', 'scaleX(0)');

            this.startTimerId = setTimeout(() => {
                this.renderer.setStyle(this.loadingBar.nativeElement, 'transition', 'transform 2s ease-out');
                this.renderer.setStyle(this.loadingBar.nativeElement, 'transform', 'scaleX(0.75)');

                this.renderer.setStyle(this.spinnerElement.nativeElement, 'transition', 'opacity 0s');
                this.renderer.setStyle(this.spinnerElement.nativeElement, 'opacity', '1');
            }, 30);
        });
    }

    private navInterceptor(event: any): void {
        if (event instanceof NavigationStart) {
            if (this.firstNav) {
                this.firstNav = false;
                return;
            }

            this._showSpinner();
        }

        if (event instanceof NavigationEnd) {
            this.hideSpinner();
        }

        // Set loading state to false in both of the below events to
        // hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.hideSpinner(true);
        }

        if (event instanceof NavigationError) {
            this.hideSpinner();
        }
    }
}
