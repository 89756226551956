import { SharedModule } from './../../shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list.component';
import { UserDetailsComponent } from './user-details.component';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'user',
        component: UserListComponent
    },
    {
        path: 'user/:id',
        component: UserDetailsComponent
    }
];

@NgModule({
    declarations: [
        UserListComponent,
        UserDetailsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes)
    ]
})
export class UserModule { }
