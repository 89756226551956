import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import localeGb from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ToastrModule } from 'ngx-toastr';
import 'hammerjs';

import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { ApiUrlInterceptor } from './interceptors/httpapiurl.interceptor';
import { ErrorComponent } from './special/error/error.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared.module';
import { UserModule } from './routes/user/user.module';
import { WrapperComponent } from './special/wrapper/wrapper.component';
import { LayoutComponent } from './special/layout/layout.component';
import { LoadingComponent } from './special/loading/loading.component';
import { LoginComponent } from './routes/login/login.component';
import { ForgotComponent } from './routes/forgot/forgot.component';
import { ProfileComponent } from './routes/employee/profile/profile.component';
import { HomeComponent } from './routes/employee/home/home.component';
import { GeneralDocumentsComponent } from './routes/employee/general-documents/general-documents.component';
import { PayslipsComponent } from './routes/employee/payslips/payslips.component';
import { TimeRecordsComponent } from './routes/employee/time-records/time-records.component';

registerLocaleData(localeGb, 'en-GB');
registerLocaleData(localeDe, 'de');

@NgModule({
    entryComponents: [ErrorComponent],
    declarations: [
        AppComponent,
        ErrorComponent,
        WrapperComponent,
        LayoutComponent,
        LoadingComponent,
        LoginComponent,
        ForgotComponent,
        HomeComponent,
        ProfileComponent,
        GeneralDocumentsComponent,
        PayslipsComponent,
        TimeRecordsComponent,
    ],
    imports: [
        AppRoutingModule,
        SharedModule,
        UserModule,
        NoopAnimationsModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        DropDownsModule,
        UploadModule,
        HttpClientModule,
    ],
    exports: [],
    providers: [
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'en-GB' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
