export default {
    Profile: 'Profil',
    "First name": 'Vorname',
    "Last name": 'Familienname',
    "Firstname": 'Vorname',
    "Lastname": 'Familienname',
    Citizenship: 'Staatsbürgerschaft',
    'ZKO start date': 'ZKO Beginn',
    'ZKO end date': 'ZKO Ende',
    'Work permit': 'Beschäftigungsbewilligung',
    'Visa / RP': 'Visum / Aufenthaltstitel',
    'Sending Entity': 'Entsendendes Unternehmen',
    'Receiving Entity': 'Aufnehmendes Unternehmen',
    'Employee Type': 'Art der Bewilligung',
    'Social Security Number': 'SV-Nummer',
    Birthday: 'Geburtstag',
    Gender: 'Geschlecht',
    'Is this a hiring-out / body - leasing case': 'Überlassung',
    'Is this a hiring-out / body - leasing case?' : 'Überlassung?',
    'Assignment Details': 'Bewilligung',
    'Permit Details': 'Bewilligung',
    'Type': 'Typ',
    'Case Number': 'Aktenzahl',
    'Extension application filed on': 'Verlängerung eingereicht am',
    'Dependents': 'Angehörige',
    'Add dependent': 'weitere Angehörige',
    'From / To': 'Von / Bis',

    'Local Hire – Category 6': {
        RP: 'Aufenthaltstitel',
    },
    'Private Client - Category 7': {
        RP: 'Aufenthaltstitel',
    },
};
