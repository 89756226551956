import { MessageService } from './../../../services/messageService';
import { MainService } from 'src/app/services/main.service';
import { AppData } from './../../../services/app-data.service';
import { AssigneeService } from './../../../routes/assignee/assignee.service';
import { ProjectService } from './../../../routes/project/project.service';
import { Project } from 'src/app/models/project';
import { orderBy } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Assignee } from 'src/app/models/assignee';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SendingEntity } from 'src/app/models/sending-entity';
import { SendingEntityService } from 'src/app/routes/sending-entity/sending-entity.service';
import { ReceivingEntity } from 'src/app/models/receiving-entity';
import { ReceivingEntityService } from 'src/app/routes/receiving-entity/receiving-entity.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Component({
    selector: 'app-general-documents',
    templateUrl: './general-documents.component.html',
    styleUrls: ['./general-documents.component.scss']
})
export class GeneralDocumentsComponent implements OnInit {

    public assignee: Assignee = this.appData.loggedInAssignee;

    constructor(
        private appData: AppData
    ) { }

    ngOnInit() {
    }

}
