// Angular
import { Injectable, PLATFORM_ID, NgZone, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
// Moment.js
import * as moment from 'moment';
// Custom
import { BaseHttpService } from './base-http.service';
import { MessageService } from './messageService';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DashboardService extends BaseHttpService {
    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    getTotalValueMetrics(): Observable<any> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/dashboard/total';
        const options = this.getOptions();

        return this.httpClient.get<any>(url, options).pipe(
            map((res) => res.content),
            catchError(this.handleError)
        );
    }

    getAssigneesByCountry(): Observable<any[]> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/dashboard/assigneesByCountry';
        const options = this.getOptions();

        return this.httpClient.get<any[]>(url, options).pipe(
            map((res) => {
                let asArray = [];

                for (let key of Object.keys(res['content'])) {
                    asArray.push({ countryCode: key, assignees: res['content'][key] });
                }

                return asArray;
            }),
            catchError(this.handleError)
        );
    }

    /**
     *
     * @param filter The display option in the chart (e.g "Last 6 months", "Current quatert" ...)
     * @param monthsConfig The correct order of labels in the timeline of the chart (e.g. NOV, DEZ, JAN)
     */
    getAssigneesByStatus(filter: string, monthsConfig: string[]): Observable<any> {
        let startDate = null;
        let endDate = null;

        if (filter === 'CURRENT_MONTH') {
            startDate = moment().utc().startOf('month');
            endDate = moment().utc().endOf('month');
        } else if (filter === 'LAST_MONTH') {
            startDate = moment().utc().subtract(1, 'months').startOf('month');
            endDate = moment().utc().subtract(0, 'months').endOf('month');
        } else if (filter === 'LAST_2_MONTHS') {
            startDate = moment().utc().utc().subtract(2, 'months').startOf('month');
            endDate = moment().utc().utc().subtract(1, 'months').endOf('month');
        } else if (filter === 'CURRENT_QUARTER') {
            startDate = moment().utc().quarter(moment().utc().quarter()).startOf('quarter');
            endDate = moment().utc().quarter(moment().utc().quarter()).endOf('quarter');
        } else if (filter === 'LAST_QUARTER') {
            startDate = moment().utc().quarter(moment().utc().quarter()).subtract(1, 'quarter').startOf('quarter');
            endDate = moment().utc().quarter(moment().utc().quarter()).subtract(1, 'quarter').endOf('quarter');
        } else if (filter === 'LAST_6_MONTHS') {
            startDate = moment().utc().subtract(6, 'months').startOf('month');
            endDate = moment().utc().subtract(1, 'months').endOf('month');
        } else if (filter === 'LAST_12_MONTHS') {
            startDate = moment().utc().subtract(1, 'year').startOf('year');
            endDate = moment().utc().subtract(1, 'year').endOf('year');
        }

        const queryParams = {
            startDate: startDate.valueOf(),
            endDate: endDate.valueOf(),
        };

        this.checkRequestTime();

        const url = this.getApiPath() + '/dashboard/assigneesByStatus';
        const options = this.getOptions(queryParams);

        return this.httpClient.get<any>(url, options).pipe(
            map((res) => {
                let mapped = res.content;

                let closed = [];
                let open = [];
                let starting = [];
                let totalClosed = 0;
                let totalOpen = 0;
                let totalStarting = 0;

                // Iterate through monthsConfig, so we don't mess up the order of the month's values
                for (let month of monthsConfig) {
                    const monthClosed = res.content[month].closed ? res.content[month].closed : 0;
                    const monthOpen = res.content[month].open ? res.content[month].open : 0;
                    const monthStarting = res.content[month].starting ? res.content[month].starting : 0;

                    closed.push(monthClosed);
                    open.push(monthOpen);
                    starting.push(monthStarting);

                    if (totalClosed < monthClosed) {
                        totalClosed = monthClosed;
                    }
                    if (totalOpen < monthOpen) {
                        totalOpen = monthOpen;
                    }
                    if (totalStarting < monthStarting) {
                        totalStarting = monthStarting;
                    }
                }

                mapped['closed'] = closed;
                mapped['open'] = open;
                mapped['starting'] = starting;

                mapped['totalClosed'] = totalClosed;
                mapped['totalOpen'] = totalOpen;
                mapped['totalStarting'] = totalStarting;

                return mapped;
            }),
            catchError(this.handleError)
        );
    }

    getXAxisValues(filter?: string): string[] {
        let values: string[] = [];

        if (filter === 'CURRENT_MONTH') {
            values = [moment().utc().format('MMM').toUpperCase()];
        } else if (filter === 'LAST_MONTH') {
            values = [moment().utc().subtract(1, 'months').format('MMM').toUpperCase()];
        } else if (filter === 'LAST_2_MONTHS') {
            values = [
                moment().utc().subtract(2, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(1, 'months').format('MMM').toUpperCase(),
            ];
        } else if (filter === 'CURRENT_QUARTER') {
            values = [
                moment().utc().quarter(moment().utc().quarter()).startOf('quarter').format('MMM').toUpperCase(),
                moment()
                    .utc()
                    .quarter(moment().utc().quarter())
                    .endOf('quarter')
                    .subtract(1, 'months')
                    .format('MMM')
                    .toUpperCase(),
                moment().utc().quarter(moment().utc().quarter()).endOf('quarter').format('MMM').toUpperCase(),
            ];
        } else if (filter === 'LAST_QUARTER') {
            values = [
                moment()
                    .utc()
                    .quarter(moment().utc().quarter())
                    .subtract(1, 'quarter')
                    .startOf('quarter')
                    .format('MMM')
                    .toUpperCase(),
                moment()
                    .utc()
                    .quarter(moment().utc().quarter())
                    .subtract(1, 'quarter')
                    .endOf('quarter')
                    .subtract(1, 'months')
                    .format('MMM')
                    .toUpperCase(),
                moment()
                    .utc()
                    .quarter(moment().utc().quarter())
                    .subtract(1, 'quarter')
                    .endOf('quarter')
                    .format('MMM')
                    .toUpperCase(),
            ];
        } else if (filter === 'LAST_6_MONTHS') {
            values = [
                moment().utc().subtract(6, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(5, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(4, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(3, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(2, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(1, 'months').format('MMM').toUpperCase(),
            ];
        } else if (filter === 'LAST_12_MONTHS') {
            values = [
                moment().utc().subtract(12, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(11, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(10, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(9, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(8, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(7, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(6, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(5, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(4, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(3, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(2, 'months').format('MMM').toUpperCase(),
                moment().utc().subtract(1, 'months').format('MMM').toUpperCase(),
            ];
        }

        return values;
    }

    getAssigneesByProfile(): Observable<any> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/dashboard/assigneesByProfile';
        const options = this.getOptions();

        return this.httpClient.get<any>(url, options).pipe(map((res) => res.content));
    }

    handleError(error: HttpErrorResponse) {
        // Set default error message if not provided
        if (!error.error.message || error.status === 500) {
            error.error.message = 'Ein unbekannter Fehler ist aufgetreten!';
            error.error.code = 500;
        }
        // Let the app keep running by returning an empty result.
        return throwError(error.error);
    }
}
