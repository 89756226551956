import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {

    transform(millis: number): any {
        const secNum = Math.floor(millis / 1000);

        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor((secNum - (hours * 3600)) / 60);

        return (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm' : '') + '';
    }

}
