import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ElementRef, Renderer2, Input, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { AppData } from '../../services/app-data.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

    public objectKeys = Object.keys;
    public visible = false;
    public visibleAnimate = false;
    private oldPos;
    public _content;
    public _type;
    public _buttons: any;
    public _showOnInit: boolean;
    public _allowOutsideClickClose: boolean = true;
    public model = '';
    public maxlength = 256;


    @Output()
    private outsideClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    private hide: EventEmitter<any> = new EventEmitter<any>();

    @Output() checked: boolean;

    @Input()
    public multiline = false;

    @Input()
    set allowOutsideClickClose(allowOutsideClickClose: boolean) {
        this._allowOutsideClickClose = allowOutsideClickClose;
    }

    constructor(
        public appData: AppData,
        public el: ElementRef,
        private renderer: Renderer2,
        private httpService: HttpService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {

    }

    private isBrowser() {
        return isPlatformBrowser(this.platformId);
    }

    @Input()
    set content(content: string) {
        this._content = content;
    }

    @Input()
    set buttons(buttons: any) {
        this._buttons = buttons;
    }

    @Input()
    set type(type: 'prompt' | undefined) {
        this._type = type;
    }

    @Input()
    set showOnInit(showOnInit: boolean) {
        this._showOnInit = showOnInit;
    }

    public show(): void {
        const native = this.el.nativeElement as HTMLElement;
        this.visible = true;
        setTimeout(() => this.visibleAnimate = true, 0);

        if (this.isBrowser()) {
            const layout = document.getElementsByTagName('app-layout')[0] as HTMLElement;
            const mainWr = document.getElementById('main');
            this.oldPos = window.pageYOffset || document.documentElement.scrollTop;

            this.renderer.addClass(document.body, 'modal-mode');
            mainWr.style.marginTop = '-' + this.oldPos + 'px';

            if (native.parentNode !== document.body) {
                document.body.appendChild(native);
            }
        }

        setTimeout(() => {
            const textfields = native.querySelector('.textfield') as HTMLElement;
            if (textfields) {
                textfields.focus();
            }
        }, 300);
    }

    public hideModal(): void {
        this.visibleAnimate = false;
        this.hide.emit();
        setTimeout(() => {
            this.visible = false;

            if (this.isBrowser()) {
                const layout = document.getElementsByTagName('app-layout')[0] as HTMLElement;
                const mainWr = document.getElementById('main');

                this.renderer.removeClass(document.body, 'modal-mode');
                mainWr.style.marginTop = '0';
                window.scrollTo(0, this.oldPos);
            }
        }, 0);

    }

    public onContainerClicked(event: MouseEvent): void {
        const classList = (event.target as HTMLElement).classList;
        if (classList.contains('modal') || classList.contains('modal-dialog')) {
            if (this._allowOutsideClickClose) {
                if (this._buttons && this._buttons.cancel) {
                    this._buttons.cancel.callback();
                }

                this.outsideClick.emit();
                this.hideModal();
            }
        }
    }

    /**
     * Called if type === 'prompt'
     */
    public onSubmit(evt) {
        evt.preventDefault();

        if (!this._buttons || !this._buttons.ok || !this._buttons.ok.callback) {
            return;
        }

        evt.preventDefault();
        this.hideModal();
        this._buttons.ok.callback(this.model);
    }
}
