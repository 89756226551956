import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig, ActiveToast } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    private defaultConfig: Partial<IndividualConfig> = {
        positionClass: 'toast-bottom-center',
        enableHtml: true,   // this has to be enabled to display FontAwesome icons as <i> tags
        // closeButton: true,
        // disableTimeOut: true,
    }

    constructor(
        private toastr: ToastrService,
        private router: Router
    ) { }

    /** Show a simple toast with no special behaviour */
    public basicToast(message: string, type: ToastType = 'info') {
        return this.showToast({ type, message, config: this.defaultConfig });
    }

    /** Show a toast which redirects the user when clicked */
    public actionToast(message: string, link: string, type: ToastType = 'success') {
        const config = Object.assign({}, this.defaultConfig, {
            toastClass: 'toast has-action'
        });

        this.showToast({ type, message, config, link });
    }

    /**
     * All public methods of MessageService should call this function to display a toastr message with correct behaviour
     * @param options see ToastParams
     */
    private showToast(options: ToastParams): ActiveToast<any> {
        /** Prepend corresponding FontAwesome icon to message. (Toastr won't compile angular templates/directives) */

        switch (options.type) {
            // case 'info': options.message = `<i class="fas fa-info-circle"></i> ${options.message}`; break;
            case 'info': options.message = `${options.message}`; break;
            case 'success': options.message = `<i class="fas fa-check-circle"></i> ${options.message}`; break;
            case 'warning': options.message = `<i class="fas fa-exclamation-triangle"></i> ${options.message}`; break;
            // case 'error': options.message = `<i class="fas fa-exclamation-circle"></i> ${options.message}`; break;
            case 'error': options.message = `${options.message}`; break;
        }

        /** create toastr message popup on page */
        const toast = this.toastr[options.type](options.message, options.title, options.config);

        /** handle routing action on message click */
        if (options.link) {
            toast.onTap.subscribe(() => {
                this.router.navigate([options.link]);
            });
        }

        return toast;
    }
}

/** currently only 'info' & 'error' should be used */
declare type ToastType = 'success' | 'error' | 'info' | 'warning';

declare type ToastParams = {
    type: ToastType
    message: string
    config: Partial<IndividualConfig>
    title?: string
    link?: string
};
