import { KiabAlertComponent } from './kiab-alert/kiab-alert.component';
import { SendDocComponent } from './send-doc/send-doc.component';
import { AppData } from './../../../services/app-data.service';
import { PayslipService } from './../../../services/payslips.service';
import { AssigneeService } from './../../../routes/assignee/assignee.service';
import { Assignee } from 'src/app/models/assignee';
import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { TimeRecordService } from 'src/app/services/time-record.service';
import { MainService } from 'src/app/services/main.service';
import { MessageService } from 'src/app/services/messageService';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-assignee-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnChanges {
    @Input()
    assignee: Assignee;

    documentsConfirmed = 0;
    documentsWaiting = 0;
    documentsNecessary = 0;
    timerecordsMissing;
    payslipsMissing;
    payslipsWaitingForReview;
    permissions;

    tenantName_;
    // tenantNamepreset_ = 'comic';
    // // tenantNamepreset_ = 'accenture';

    saveHowTo = false;

    openMe = false;
    private howToData;

    @ViewChild(SendDocComponent, {
        static: true,
    })
    private sendDocComponent: SendDocComponent;

    @ViewChild(KiabAlertComponent, {
        static: true,
    })
    private kiabAlertComponent: KiabAlertComponent;

    /*
     * how to use text
     */
    public howToUseText;

    constructor(
        public assigneeService: AssigneeService,
        public timeRecordService: TimeRecordService,
        public payslipService: PayslipService,
        public appData: AppData,
        private mainService: MainService,
        private messageService: MessageService,
        private userService: UserService
    ) {
        this.permissions = appData.permissions;
        this.processHowToData('home');
        this.getTenant();
        this.howToUseText = this.appData.getHowToData("home");
    }

    async ngOnInit() {
        console.log("ON INIT")
        if (this.assignee && this.assignee.id) {
            this.assignee = await this.assigneeService.getAssignee(this.assignee.id).toPromise();
        }
    }

    ngOnChanges() {
        if (!this.assignee) {
            return;
        }
        this.getTenant().then((response) => {
            const assigneeId = this.assignee.id;

            this.assigneeService.getDocumentsByAssignee(assigneeId).subscribe((documents) => {
                // we need this to reset the counter.
                this.documentsNecessary = 0;
                this.documentsConfirmed = 0;
                this.documentsWaiting = 0;
                if (this.tenantName_ === this.appData.tenantNamepreset_) {
                    if (this.appData.permissions.DOCUMENT_EDIT) {
                        documents.forEach((doc) => {
                            if (doc.fileName && !doc.declinedDate && !doc.confirmedDate) {
                                this.documentsWaiting++;
                            } else if (doc.declinedDate) {
                                this.documentsNecessary++;
                            } else if (doc.confirmedDate) {
                                this.documentsConfirmed++;
                            } else {
                                this.documentsNecessary++;
                            }
                        });
                    } else {
                        documents.forEach((doc) => {
                            if (doc.fileName && !doc.declinedDate && !doc.confirmedDate) {
                                this.documentsWaiting++;
                            } else if (doc.declinedDate) {
                                if (doc.documentType.id === 9 || doc.documentType.id === 12) {
                                    this.documentsNecessary++;
                                }
                            } else if (doc.confirmedDate) {
                                this.documentsConfirmed++;
                            } else {
                                if (doc.documentType.id === 9 || doc.documentType.id === 12) {
                                    this.documentsNecessary++;
                                }
                            }
                        });
                    }
                } else {
                    documents.forEach((doc) => {
                        if (doc.fileName && !doc.declinedDate && !doc.confirmedDate) {
                            this.documentsWaiting++;
                        } else if (doc.declinedDate) {
                            this.documentsNecessary++;
                        } else if (doc.confirmedDate) {
                            this.documentsConfirmed++;
                        } else {
                            this.documentsNecessary++;
                        }
                    });
                }
            });

            this.timeRecordService.getDaysOverdue(assigneeId).subscribe((missingDays) => {
                this.timerecordsMissing = missingDays;
            });

            this.payslipService.getMissingMonths(assigneeId).subscribe((missingMonths) => {
                this.payslipsMissing = missingMonths;
            });

            if (!this.appData.permissions.ASSIGNEE_EDIT) {
                this.payslipService.payslipsByAssignee(assigneeId).subscribe((payslips) => {
                    payslips.forEach((payslip) => {
                        const cur = new Date();
                        const month = payslip.month - 1;
                        const year = payslip.year;

                        if (year > cur.getFullYear() || (year === cur.getFullYear() && month >= cur.getMonth())) {
                            this.payslipsWaitingForReview--;
                        } else {
                            this.payslipsWaitingForReview -=
                                payslip.rejectedDate || !payslip.fileName || !!payslip.reviewedDate ? 1 : 0;
                        }
                    });
                    this.payslipsWaitingForReview = 0;
                });
            } else {
                this.payslipsWaitingForReview = 0;
            }
        });
    }
    private getTenant() {
        return new Promise((resolve, reject) => {
            this.userService.getTenant().subscribe(
                (success) => {
                    this.tenantName_ = success;
                    resolve(true);
                },
                (error) => {
                    resolve(true);
                }
            );
        });
    }
    private async saveHowToData(howTo: any) {
        await this.assigneeService.saveHowToData(howTo).toPromise();
        await this.userService.getCurrentUserData();
    }

    resendCredentials() {
        this.mainService.prompt(
            'Do you really want to resend the credentials?',
            (value, action) => {
                if (action === 'yes') {
                    this.assigneeService.sendCredentials(this.assignee.id).subscribe(() => {
                        this.messageService.basicToast('Credentials have been sent successfully');
                    });
                }
            },
            'confirm'
        );
    }

    updateLastConfirmation() {
        this.assigneeService.confirmUser(this.assignee.id).subscribe(() => {
            this.messageService.basicToast('The “last confirmation date” for this assignee has been updated.');
        });
    }

    clickedSendDocuments() {
        this.sendDocComponent.show();
    }

    clickedDownloadDocuments() {
        window.location.href = '/comic/documents?assigneeId=' + this.assignee.id;
    }

    showAppManual() {
        window.location.href = '/comic/filedownload?fileName=comic_go.pdf&isManualFile=true';
    }

    clickedKiabAlert() {
        this.kiabAlertComponent.show();
    }
    onClickOpenModal() {
        this.openMe = true;
        // this.fillAbsencePeriodModal.hideModal();
    }
    onCloseOpenModal() {
        this.openMe = false;
    }

    private processHowToData(howToName) {
        if (!this.appData.loggedInAssignee||!this.appData.loggedInUser['assignee']) {
            return;
        }
        this.howToData = this.appData.loggedInUser['assignee'].howToData;
        this.howToData = this.howToData
            ? JSON.parse(this.howToData)
            : { home: false, profile: false, documents: false, timerecords: false, payslips: false };
        const assigneeId = this.appData.loggedInUser['assignee'].id;
        if (this.howToData[howToName]) {
            return;
        }
        this.howToData[howToName] = true;
        this.onClickOpenModal();
        this.saveHowTo = true;

        const buildsave = {
            id: assigneeId,
            howToData: JSON.stringify(this.howToData),
        };

        this.saveHowToData(buildsave);
    }
}
