import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AppData } from './../../services/app-data.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    invalidCredentials = false;
    loading = false;
    errorShake = false;

    private redirectPath: string;

    constructor(
        private userService: UserService,
        private appData: AppData,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.loginForm = new FormGroup({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
        });

        this.redirectPath = this.route.snapshot.queryParams.r;
    }

    ngOnInit() {
        console.log('login component init', location.href);
        if (location.href.includes('home.comic.support')) {
            location.assign('https://home.eur1.comic.support/');
            return;
        }

        const domainMappings = {
            'aab.comic.support': 'https://andritz-aktiebolag.eur2.comic.support/',
            'andritz.comic.support': 'https://andritz-aktiengesellschaft.eur2.comic.support/',
            'aoy.comic.support': 'https://andritz-oy.eur2.comic.support/',
            'andritzfiedler.comic.support': 'https://andritz-fiedler-gmbh.eur2.comic.support/',
            'andritzhydro.comic.support': 'https://andritz-hydro-gmbh.eur2.comic.support/',
            'applied.comic.support': 'https://applied-materials-gmbh.eur2.comic.support/',
            'danieli.comic.support': 'https://danieli-c-officine-meccaniche-s-p-a.eur2.comic.support/',
            'infosys.comic.support': 'https://infosys-austria-gmbh.eur2.comic.support/',
            'itc.comic.support': 'https://itc-infotech-india-limited-zweigniederlassung-sterreich.eur2.comic.support/',
            'lenzing.comic.support': 'https://lenzing-aktiengesellschaft.eur2.comic.support/',
            'marchesini.comic.support': 'https://marchesini-group-s-p-a.eur2.comic.support/',
            'modomec.comic.support': 'https://modomec-s-r-l.eur2.comic.support/',
            'forindustry.comic.support': 'https://for-industry-engineering-sp-zoo.eur2.comic.support/',
            'nagarro.comic.support': 'https://nagarro-gmbh.eur2.comic.support/',
            'redecam.comic.support': 'https://redecam-group-spa.eur2.comic.support/',
            'sap.comic.support': 'https://sap-se.eur2.comic.support/',
            'sll.comic.support': 'https://sii-sp-zoo.eur2.comic.support/',
            'tiptopelbe.comic.support': 'https://tip-top-elbe.eur2.comic.support/',
            'guest.comic.support': 'https://guest.eur2.comic.support/',
        };

        const domain = location.host;
        const domainMapping = domainMappings[domain];
        if (domainMapping) {
            location.assign(domainMapping);
            return;
        }
    }

    async submitForm() {
        if (this.loginForm.invalid) {
            this.progErrorAnimation();
            return;
        }

        this.loading = true;
        const user = this.loginForm.getRawValue();

        try {
            // login
            await this.userService.login(user).toPromise();
            // get the loggin user and its data (permissions, start page, etc...)
            await this.userService.getCurrentUserData();
            this.appData.isMobileApplicationView = await this.userService.getMobileAppicationView().toPromise();

            if (this.redirectPath) {
                this.router.navigate([this.redirectPath]);
            } else {
                this.router.navigate([this.appData.startPage]);
            }
        } catch (error) {
            this.loading = false;
            this.progErrorAnimation();
        }
    }

    goToForgot() {
        this.router.navigate(['/forgot']);
    }

    private progErrorAnimation() {
        this.invalidCredentials = true;
        this.errorShake = true;
        setTimeout(() => {
            this.errorShake = false;
        }, 1000);
    }

    private subtitle() {
        console.log(location.href);
        if (
            location.href.includes('oberhammer.comic.support') ||
            location.href.includes('localhost:4200') ||
            location.href.includes('comic-stage.teamvienna.dev')
        ) {
            
        }
        return 'TRACK MANAGEMENT TOOL';
    }
}
