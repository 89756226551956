import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { faUserCircle, faChevronDown, faUser, faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { share } from 'rxjs/operators';

import { AppData } from './../../services/app-data.service';
import { DocumentService } from './../../services/document.service';
import { MenuItem } from '../../models/general';
import { MessageService, UserService } from '../../services';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    layout: 'default' | 'empty' = 'default';
    mainMenu: MenuItem[];
    menuOpen = false;
    tenantName = null;
    userType = null;
    userNameDisplayed = null;

    navOpen = false;

    profileDropdown = [
        {
            icon: faSignOut,
            name: 'Logout',
            click: {
                indicator: 'logout',
                name: null,
                key: null,
            },
        },
    ];
    showAssigneUserTitel = false;

    faUserCircle = faUserCircle;
    faChevronDown = faChevronDown;
    faUser = faUser;
    faSignOut = faSignOut;

    isAssigneEmail = false;

    constructor(
        private router: Router,
        private appData: AppData,
        private renderer: Renderer2,
        private userService: UserService,
        private documentService: DocumentService,
        private messageService: MessageService
    ) {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            this.preparePage(evt.url);
        });
    }

    ngOnInit() {}

    private async preparePage(routerEventUrl: string) {
        this.decideMenu();
        this.decideLayout(routerEventUrl);
        this.findActiveMenu(routerEventUrl);

        this.tenantName = await this.userService.getTenant().toPromise();
        this.documentService.setTenantName(this.tenantName);
        this.userType = await this.userService.getUserType().toPromise();
    }


    private buildMenuAdmin() {
        const reportingMode = this.appData.config && this.appData.config.reportingMode;
        const menuAdmin: MenuItem[] = []
        console.log({appData: this.appData})
        let localhireOnly = false
        if (this.appData && this.appData.loggedInUser && this.appData.loggedInUser.allPermissions) {
            const localHireOnlyPermissions = this.appData.loggedInUser.allPermissions.filter(p => p.name === 'LOCAL_HIRE_ONLY')
            localhireOnly = localHireOnlyPermissions.length > 0;
        }
        menuAdmin.push({
            routerLink: '/userprofile',
            icon: 'fas fa-user',
            text: 'Profile',
            isMobile: true,
        })

        if (localhireOnly) {
            menuAdmin.push({
                routerLink: '/dashboard',
                icon: 'fas fa-chart-line',
                text: 'Dashboard',
            })
            menuAdmin.push({
                routerLink: '/localhires',
                icon: 'fas fa-users',
                text: 'Local Hires',
            })
            return menuAdmin;
        }

        if (!reportingMode) {
            menuAdmin.push({
                routerLink: '/dashboard',
                icon: 'fas fa-chart-line',
                text: 'Dashboard',
            })
        }
        menuAdmin.push({
            routerLink: '/assignee',
            icon: 'fas fa-users',
            text: 'Assignees',
        })
        menuAdmin.push({
            routerLink: '/localhires',
            icon: 'fas fa-users',
            text: 'Local Hires',
        })
        menuAdmin.push({
            routerLink: '/sending-entity',
            icon: 'fas fa-layer-group',
            text: 'Sending Entities',
        })
        menuAdmin.push({
            routerLink: '/receiving-entity',
            icon: 'fas fa-layer-group',
            text: 'Receiving Entities',
        })
        if (!reportingMode) {
            menuAdmin.push({
                routerLink: '/project',
                icon: 'fas fa-suitcase',
                text: 'Projects',
            })
        } 

        menuAdmin.push({
            routerLink: '/organisation-admin',
            icon: 'fas fa-project-diagram',
            text: 'Organisation Admins',
        })

        return menuAdmin;
    }

    private buildMenuAssignee() {
        const reportingMode = this.appData.config && this.appData.config.reportingMode;
        if (reportingMode) {
            return [
                {
                    routerLink: '/profile',
                    icon: 'fas fa-user',
                    text: 'Profile',
                    isMobile: true,
                }
            ]
        }
        return [
            {
                routerLink: '/profile',
                icon: 'fas fa-user',
                text: 'Profile',
                isMobile: true,
            },
            {
                icon: 'fas fa-home',
                text: 'Home',
                routerLink: '/home',
            },
            {
                icon: 'fas fa-id-card',
                text: 'Profile',
                routerLink: '/profile',
            },
            {
                icon: 'fas fa-file-alt',
                text: 'General Documents',
                routerLink: '/general-documents',
            },
            {
                icon: 'fas fa-file-alt',
                text: 'Time Records',
                routerLink: '/time-records',
            },
            {
                icon: 'fas fa-file-alt',
                text: 'Payslips',
                routerLink: '/payslips',
            },
        ];
    } 

    /**
     * Set the menu depending on the currently logged in user
     */
    private decideMenu() {
        const menuAdmin: MenuItem[] = this.buildMenuAdmin();

        const menuAssignee: MenuItem[] = this.buildMenuAssignee();

        if (this.appData.loggedInUser) {
            if (this.appData.userIsAdmin()) {
                this.mainMenu = menuAdmin;
            } else {
                this.mainMenu = menuAssignee;
                if (this.appData.myAssignee) {
                    const assignees = this.appData.myAssignee.assigneeList;
                    const myId = this.appData.loggedInUser.id; //TODO:
                    const array = [];

                    this.showAssigneUserTitel = true;

                    for (const key in assignees) {
                        if (assignees.hasOwnProperty(key)) {
                            const element = assignees[key];
                            if (myId !== element.id) {
                                const entry = {
                                    icon: faUserCircle,
                                    name: element.projects[0].name,
                                    startDate: element.startDate,
                                    endDate: element.endDate,
                                    status: element.status,
                                    click: {
                                        indicator: 'potted',
                                        name: element.username,
                                        // name: null,
                                        key: this.appData.authkey,
                                    },
                                };
                                array.push(entry);
                            }
                        }
                    }

                    this.isAssigneEmail = true;
                    this.profileDropdown = array;
                }
            }

            this.userNameDisplayed = this.appData.loggedInUser.firstname + ' ' + this.appData.loggedInUser.lastname;
        }
    }

    /**
     * Switch the layout according to the url
     * @param url - the current url
     */
    private decideLayout(url: string) {
        const path = url.split('?')[0];

        if (path === '/login' || path === '/forgot') {
            this.layout = 'empty';
        } else {
            this.layout = 'default';
        }
    }

    private findActiveMenu(url: string) {
        const path = url.split('?')[0];

        if (this.mainMenu) {
            this.mainMenu.forEach((menuItem) => {
                menuItem.active = path.indexOf(menuItem.routerLink) >= 0;
            });
        }
    }

    onClickMenuItem() {
        this.renderer.removeClass(document.body, 'menu-active');
    }

    toggleMobileMenu() {
        this.menuOpen = !this.menuOpen;
        if (document.body.className.indexOf('menu-active') === -1) {
            this.renderer.addClass(document.body, 'menu-active');
        } else {
            this.renderer.removeClass(document.body, 'menu-active');
        }
    }

    clickedProfil() {
        this.router.navigateByUrl('/userprofile');
    }

    clickHome() {
        this.router.navigate([this.appData.startPage]);
    }

    activateDropdown() {
        this.navOpen = !this.navOpen;
    }

    clickedSubtree(indicator: string, username?: string, key?: string) {
        switch (indicator) {
            case 'logout':
                this.logout();
                break;
            case 'potted':
                this.clickedAssignee(username, key);
                break;
            default:
                break;
        }
    }

    private async clickedAssignee(username: string, authKey: string) {
        await this.userService.changeToOtherAssignee(username, authKey).toPromise();
        // Redirect to
        this.router.navigate(['/']);
    }

    async logout() {
        try {
            await this.userService.logout(this.appData.authkey).toPromise();
            this.router.navigate(['/login']);
            this.appData.loggedInUser = null;
        } catch (error) {
            this.messageService.basicToast('Beim Abmelden ist ein Fehler aufgetreten!', 'error');
        }
    }
}
