import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AppData } from 'src/app/services/app-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from 'src/app/validators/must-match.validator';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
    public loginForm: FormGroup;
    public changePasswordForm: FormGroup;
    public user: { username: string; password: string };
    private redirectPath: string;
    public invalidCredentials = false;
    public errorCredentials = false;
    public successCredentials = false;
    // For reseting password
    public invalidtoken = false;
    public successPasswrd = false;
    public errorPasswrd = false;
    public errorText = 'An unexpected error occurred, please try again later.';

    public loading = false;
    public errorShake = false;
    public switchForm = false;
    constructor(
        private userService: UserService,
        private appData: AppData,
        private router: Router,
        private route: ActivatedRoute,
        private mainService: MainService,
        private formBuilder: FormBuilder
    ) {
        this.loginForm = new FormGroup({
            username: new FormControl('', [Validators.required]),
        });

        if (this.route.snapshot.queryParams.token) {
            this.switchForm = true;
        }
        this.changePasswordForm = this.formBuilder.group(
            {
                password: new FormControl('', [
                    Validators.required,
                    Validators.pattern(
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$§%^&()`€=<>*.,";\[\]+{}~|\/'°´\\_:-]).{12,}$/
                    ),
                ]),
                confirmpassword: new FormControl('', [
                    Validators.required,
                    Validators.pattern(
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$§%^&()`€=<>*.,";\[\]+{}~|\/'°´\\_:-]).{12,}$/
                    ),
                ]),
                token: new FormControl(this.route.snapshot.queryParams.token),
            },
            {
                validator: MustMatch('password', 'confirmpassword'),
            }
        );
        this.redirectPath = this.route.snapshot.queryParams.r;
    }

    ngOnInit() {}

    public submitForm(evt) {
        if (this.loading) {
            return false;
        }
        if (this._isBlank(this.loginForm.value.username)) {
            this.invalidCredentials = true;
            this.errorShake = true;
            setTimeout(() => {
                this.errorShake = false;
            }, 1000);
            return false;
        }
        const query = {
            userIdentity: encodeURIComponent(this.loginForm.value.username),
        };

        this.loading = true;

        this.userService.forgot(query).subscribe(
            (usr) => {
                this.successCredentials = true;
                this.errorCredentials = false;
                this.invalidCredentials = false;

                this.loading = false;
            },
            (err) => {
                this.loading = false;
                switch (err.status) {
                    case 412:
                        this.invalidCredentials = true;
                        this.errorCredentials = false;
                        this.successCredentials = false;
                        break;
                    default:
                        this.errorCredentials = true;
                        this.invalidCredentials = false;
                        this.successCredentials = false;
                        break;
                }

                this.errorShake = true;
                setTimeout(() => {
                    this.errorShake = false;
                }, 1000);
            }
        );
    }

    public submitPassForm(evt) {
        const query = {
            password: encodeURIComponent(this.changePasswordForm.value.password),
            token: encodeURIComponent(this.changePasswordForm.value.token),
        };

        if (this.changePasswordForm.invalid) {
            return;
        }
        this.userService.resetPassowrd(query).subscribe(
            (success) => {
                if (success['success'] === true) {
                    this.invalidtoken = false;
                    this.successPasswrd = true;
                    this.errorPasswrd = false;
                } else {
                    // this.invalidtoken = false;
                    // this.successPasswrd = false;
                    // this.errorPasswrd = true;

                    if (success['msg'] === 'The reset password token you have provided has expired.') {
                        this.invalidtoken = false;
                        this.successPasswrd = false;
                        this.errorPasswrd = true;
                        this.errorText = success['msg'];
                    } else {
                        this.invalidtoken = true;
                        this.successPasswrd = false;
                        this.errorPasswrd = false;
                    }

                    this.errorShake = true;
                    setTimeout(() => {
                        this.errorShake = false;
                    }, 1000);
                }
            },
            (error) => {
                this.invalidtoken = false;
                this.successPasswrd = false;
                this.errorPasswrd = true;
                this.errorText = 'An unexpected error occurred, please try again later.';

                this.errorShake = true;
                setTimeout(() => {
                    this.errorShake = false;
                }, 1000);
            }
        );
    }

    private _isBlank(string: string) {
        return typeof string === 'undefined' || string == null || string.trim() === '';
    }
    public onInputField() {
        this.invalidCredentials = false;
        this.invalidtoken = false;
        this.successPasswrd = false;
        this.errorPasswrd = false;
    }

    get password() {
        return this.changePasswordForm.get('password');
    }
    get confirmpassword() {
        return this.changePasswordForm.get('confirmpassword');
    }
}
