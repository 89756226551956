export class DocumentType {
    public id: number;
    public name: string;
    public nameGerman: string;
    public template: string;
    public notifyAssignee: boolean;
    public notifyOrganisationAdmins: boolean;
    public referencedDocumentTypes: DocumentType[];
    public position: number;

    public constructor(data?) {
        if (data) {
            Object.assign(this, data);
        }
    }

}
