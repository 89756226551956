import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'css-loader',
    templateUrl: './css-loader.component.html',
    styleUrls: ['./css-loader.component.scss']
})
export class CssLoaderComponent implements OnInit {

    constructor() { }

    ngOnInit() {}
}
