import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AppData } from './../services';

@Injectable({
    providedIn: 'root',
})
/**
 * This guard helps to protect routes, which are NOT allowed to be viewed by admins
 * If the current user is an admin, redirect him to the /dashboard
 */
export class AdminGuard implements CanActivate {
    constructor(private appData: AppData, private router: Router) {}

    canActivate() {
        if (this.appData.userIsAdmin()) {
            this.router.navigate(['/dashboard']);
        } else {
            return true;
        }
    }
}
