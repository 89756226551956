import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

import { AppData } from '../services/app-data.service';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root',
})
/**
 * This guard will be called on every route, which needs user authentication
 */
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService, private appData: AppData) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const destination = state.url.split('?')[0];
        const userIsLoggedIn = await this.userService.getCurrentUserData();

        // Handle determination of
        if (userIsLoggedIn) {
            if (destination === '/') {
                this.router.navigate([this.appData.startPage]);
            } else {
                return true;
            }
        } else {
            this.logoutUser(destination);
            return false;
        }
    }

    private logoutUser(destination: string) {
        this.appData.loggedInUser = null;
        for (const permissionName in this.appData.permissions) {
            if (this.appData.permissions.hasOwnProperty(permissionName)) {
                this.appData.permissions[permissionName] = false;
            }
        }

        // Add the route, the user was trying to access as a query param, so he will be redirected back to it after a successful login
        // e.g `/login?r=assignee`
        if (destination !== '/login' && destination !== '/') {
            this.router.navigate(['/login'], { queryParams: { r: destination } });
        } else {
            this.router.navigate(['login']);
        }
    }
}
