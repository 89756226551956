export default [
  {
    "cc": "AF",
    "country": "AFGHANISTAN"
  },
  {
    "cc": "AL",
    "country": "ALBANIA"
  },
  {
    "cc": "DZ",
    "country": "ALGERIA"
  },
  {
    "cc": "AS",
    "country": "AMERICAN SAMOA"
  },
  {
    "cc": "AD",
    "country": "ANDORRA"
  },
  {
    "cc": "AO",
    "country": "ANGOLA"
  },
  {
    "cc": "AI",
    "country": "ANGUILLA"
  },
  {
    "cc": "AG",
    "country": "ANTIGUA AND BARBUDA"
  },
  {
    "cc": "AR",
    "country": "ARGENTINA"
  },
  {
    "cc": "AM",
    "country": "ARMENIA"
  },
  {
    "cc": "AW",
    "country": "ARUBA"
  },
  {
    "cc": "AU",
    "country": "AUSTRALIA"
  },
  {
    "cc": "AT",
    "country": "AUSTRIA"
  },
  {
    "cc": "AZ",
    "country": "AZERBAIJAN"
  },
  {
    "cc": "BS",
    "country": "BAHAMAS"
  },
  {
    "cc": "BH",
    "country": "BAHRAIN"
  },
  {
    "cc": "BD",
    "country": "BANGLADESH"
  },
  {
    "cc": "BB",
    "country": "BARBADOS"
  },
  {
    "cc": "BY",
    "country": "BELARUS"
  },
  {
    "cc": "BE",
    "country": "BELGIUM"
  },
  {
    "cc": "BZ",
    "country": "BELIZE"
  },
  {
    "cc": "BJ",
    "country": "BENIN"
  },
  {
    "cc": "BT",
    "country": "BHUTAN"
  },
  {
    "cc": "BO",
    "country": "BOLIVIA"
  },
  {
    "cc": "BA",
    "country": "BOSNIA AND HERZEGOVINA"
  },
  {
    "cc": "BW",
    "country": "BOTSWANA"
  },
  {
    "cc": "BR",
    "country": "BRAZIL"
  },
  {
    "cc": "BN",
    "country": "BRUNEI DARUSSALAM"
  },
  {
    "cc": "BG",
    "country": "BULGARIA"
  },
  {
    "cc": "BF",
    "country": "BURKINA FASO"
  },
  {
    "cc": "BI",
    "country": "BURUNDI"
  },
  {
    "cc": "KH",
    "country": "CAMBODIA"
  },
  {
    "cc": "CM",
    "country": "CAMEROON"
  },
  {
    "cc": "CA",
    "country": "CANADA"
  },
  {
    "cc": "CV",
    "country": "CAPE VERDE"
  },
  {
    "cc": "KY",
    "country": "CAYMAN ISLANDS"
  },
  {
    "cc": "CF",
    "country": "CENTRAL AFRICAN REPUBLIC"
  },
  {
    "cc": "TD",
    "country": "CHAD"
  },
  {
    "cc": "CL",
    "country": "CHILE"
  },
  {
    "cc": "CN",
    "country": "CHINA"
  },
  {
    "cc": "CO",
    "country": "COLOMBIA"
  },
  {
    "cc": "KM",
    "country": "COMOROS"
  },
  {
    "cc": "CG",
    "country": "CONGO"
  },
  {
    "cc": "CD",
    "country": "CONGO, THE DEMOCRATIC REPUBLIC OF"
  },
  {
    "cc": "BM",
    "country": "Cook"
  },
  {
    "cc": "CK",
    "country": "COOK ISLANDS"
  },
  {
    "cc": "CR",
    "country": "COSTA RICA"
  },
  {
    "cc": "CI",
    "country": "COTE D'IVOIRE"
  },
  {
    "cc": "HR",
    "country": "CROATIA"
  },
  {
    "cc": "CU",
    "country": "CUBA"
  },
  {
    "cc": "CY",
    "country": "CYPRUS"
  },
  {
    "cc": "CZ",
    "country": "CZECH REPUBLIC"
  },
  {
    "cc": "DK",
    "country": "DENMARK"
  },
  {
    "cc": "DJ",
    "country": "DJIBOUTI"
  },
  {
    "cc": "DM",
    "country": "DOMINICA"
  },
  {
    "cc": "DO",
    "country": "DOMINICAN REPUBLIC"
  },
  {
    "cc": "EC",
    "country": "ECUADOR"
  },
  {
    "cc": "EG",
    "country": "EGYPT"
  },
  {
    "cc": "SV",
    "country": "EL SALVADOR"
  },
  {
    "cc": "GQ",
    "country": "EQUATORIAL GUINEA"
  },
  {
    "cc": "ER",
    "country": "ERITREA"
  },
  {
    "cc": "EE",
    "country": "ESTONIA"
  },
  {
    "cc": "ET",
    "country": "ETHIOPIA"
  },
  {
    "cc": "FO",
    "country": "FAROER ISLAND"
  },
  {
    "cc": "FJ",
    "country": "FIJI"
  },
  {
    "cc": "FI",
    "country": "FINLAND"
  },
  {
    "cc": "FR",
    "country": "FRANCE"
  },
  {
    "cc": "GF",
    "country": "FRENCH GUIANA"
  },
  {
    "cc": "PF",
    "country": "FRENCH POLYNESIA"
  },
  {
    "cc": "GA",
    "country": "GABON"
  },
  {
    "cc": "GM",
    "country": "GAMBIA"
  },
  {
    "cc": "GE",
    "country": "GEORGIA"
  },
  {
    "cc": "DE",
    "country": "GERMANY"
  },
  {
    "cc": "GH",
    "country": "GHANA"
  },
  {
    "cc": "GI",
    "country": "GIBRALTAR"
  },
  {
    "cc": "GR",
    "country": "GREECE"
  },
  {
    "cc": "GL",
    "country": "GREENLAND"
  },
  {
    "cc": "GD",
    "country": "GRENADA"
  },
  {
    "cc": "GP",
    "country": "GUADELOUPE"
  },
  {
    "cc": "GU",
    "country": "GUAM"
  },
  {
    "cc": "GT",
    "country": "GUATEMALA"
  },
  {
    "cc": "GG",
    "country": "GUERNSEY"
  },
  {
    "cc": "GN",
    "country": "GUINEA"
  },
  {
    "cc": "GW",
    "country": "GUINEA-BISSAU"
  },
  {
    "cc": "GY",
    "country": "GUYANA"
  },
  {
    "cc": "HT",
    "country": "HAITI"
  },
  {
    "cc": "HN",
    "country": "HONDURAS"
  },
  {
    "cc": "HK",
    "country": "Hongkong"
  },
  {
    "cc": "HU",
    "country": "HUNGARY"
  },
  {
    "cc": "IS",
    "country": "ICELAND"
  },
  {
    "cc": "IN",
    "country": "INDIA"
  },
  {
    "cc": "ID",
    "country": "INDONESIA"
  },
  {
    "cc": "IR",
    "country": "IRAN, ISLAMIC REPUBLIC OF"
  },
  {
    "cc": "IQ",
    "country": "IRAQ"
  },
  {
    "cc": "IE",
    "country": "IRELAND"
  },
  {
    "cc": "IM",
    "country": "ISLE OF MAN"
  },
  {
    "cc": "IL",
    "country": "ISRAEL"
  },
  {
    "cc": "IT",
    "country": "ITALY"
  },
  {
    "cc": "JM",
    "country": "JAMAICA"
  },
  {
    "cc": "JP",
    "country": "JAPAN"
  },
  {
    "cc": "JE",
    "country": "JERSEY"
  },
  {
    "cc": "JO",
    "country": "JORDAN"
  },
  {
    "cc": "KZ",
    "country": "KAZAKHSTAN"
  },
  {
    "cc": "KE",
    "country": "KENYA"
  },
  {
    "cc": "KI",
    "country": "KIRIBATI"
  },
  {
    "cc": "KP",
    "country": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"
  },
  {
    "cc": "KR",
    "country": "KOREA, REPUBLIC OF"
  },
  {
    "cc": "XK",
    "country": "Kosovo"
  },
  {
    "cc": "KW",
    "country": "KUWAIT"
  },
  {
    "cc": "KG",
    "country": "KYRGYZSTAN"
  },
  {
    "cc": "LA",
    "country": "LAO PEOPLE'S DEMOCRATIC REPUBLIC"
  },
  {
    "cc": "LV",
    "country": "LATVIA"
  },
  {
    "cc": "LB",
    "country": "LEBANON"
  },
  {
    "cc": "LS",
    "country": "LESOTHO"
  },
  {
    "cc": "LR",
    "country": "LIBERIA"
  },
  {
    "cc": "LY",
    "country": "LIBYAN ARAB JAMAHIRIYA"
  },
  {
    "cc": "LI",
    "country": "LIECHTENSTEIN"
  },
  {
    "cc": "LT",
    "country": "LITHUANIA"
  },
  {
    "cc": "LU",
    "country": "LUXEMBOURG"
  },
  {
    "cc": "MO",
    "country": "Macao"
  },
  {
    "cc": "MK",
    "country": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF"
  },
  {
    "cc": "MG",
    "country": "MADAGASCAR"
  },
  {
    "cc": "MW",
    "country": "MALAWI"
  },
  {
    "cc": "MY",
    "country": "MALAYSIA"
  },
  {
    "cc": "MV",
    "country": "MALDIVES"
  },
  {
    "cc": "ML",
    "country": "MALI"
  },
  {
    "cc": "MT",
    "country": "MALTA"
  },
  {
    "cc": "MH",
    "country": "MARSHALL ISLANDS"
  },
  {
    "cc": "MQ",
    "country": "MARTINIQUE"
  },
  {
    "cc": "MR",
    "country": "MAURITANIA"
  },
  {
    "cc": "MU",
    "country": "MAURITIUS"
  },
  {
    "cc": "YT",
    "country": "MAYOTTE"
  },
  {
    "cc": "MX",
    "country": "MEXICO"
  },
  {
    "cc": "FM",
    "country": "MICRONESIA, FEDERATED STATES OF"
  },
  {
    "cc": "MD",
    "country": "MOLDOVA, REPUBLIC OF"
  },
  {
    "cc": "MC",
    "country": "MONACO"
  },
  {
    "cc": "MN",
    "country": "MONGOLIA"
  },
  {
    "cc": "MS",
    "country": "MONTSERRAT"
  },
  {
    "cc": "MA",
    "country": "MOROCCO"
  },
  {
    "cc": "MZ",
    "country": "MOZAMBIQUE"
  },
  {
    "cc": "MM",
    "country": "MYANMAR"
  },
  {
    "cc": "NA",
    "country": "NAMIBIA"
  },
  {
    "cc": "NR",
    "country": "NAURU"
  },
  {
    "cc": "NP",
    "country": "NEPAL"
  },
  {
    "cc": "NL",
    "country": "NETHERLANDS"
  },
  {
    "cc": "AN",
    "country": "NETHERLANDS ANTILLES"
  },
  {
    "cc": "NC",
    "country": "NEW CALEDONIA"
  },
  {
    "cc": "NZ",
    "country": "NEW ZEALAND"
  },
  {
    "cc": "NI",
    "country": "NICARAGUA"
  },
  {
    "cc": "NE",
    "country": "NIGER"
  },
  {
    "cc": "NG",
    "country": "NIGERIA"
  },
  {
    "cc": "NU",
    "country": "Niue (Au§engebiet Neuseeland)"
  },
  {
    "cc": "MP",
    "country": "NORTHERN MARIANA ISLANDS"
  },
  {
    "cc": "NO",
    "country": "NORWAY"
  },
  {
    "cc": "OM",
    "country": "OMAN"
  },
  {
    "cc": "PK",
    "country": "PAKISTAN"
  },
  {
    "cc": "PW",
    "country": "PALAU"
  },
  {
    "cc": "PS",
    "country": "PALESTINIAN TERRITORY, OCCUPIED"
  },
  {
    "cc": "PA",
    "country": "PANAMA"
  },
  {
    "cc": "PG",
    "country": "PAPUA NEW GUINEA"
  },
  {
    "cc": "PY",
    "country": "PARAGUAY"
  },
  {
    "cc": "PE",
    "country": "PERU"
  },
  {
    "cc": "PH",
    "country": "PHILIPPINES"
  },
  {
    "cc": "PL",
    "country": "POLAND"
  },
  {
    "cc": "PT",
    "country": "PORTUGAL"
  },
  {
    "cc": "PR",
    "country": "PUERTO RICO"
  },
  {
    "cc": "QA",
    "country": "QATAR"
  },
  {
    "cc": "RE",
    "country": "REUNION"
  },
  {
    "cc": "RO",
    "country": "ROMANIA"
  },
  {
    "cc": "RU",
    "country": "RUSSIAN FEDERATION"
  },
  {
    "cc": "RW",
    "country": "RWANDA"
  },
  {
    "cc": "WS",
    "country": "SAMOA"
  },
  {
    "cc": "SM",
    "country": "SAN MARINO"
  },
  {
    "cc": "ST",
    "country": "SAO TOME AND PRINCIPE"
  },
  {
    "cc": "SA",
    "country": "SAUDI ARABIA"
  },
  {
    "cc": "SN",
    "country": "SENEGAL"
  },
  {
    "cc": "RS",
    "country": "SERBIA & MONTENEGRO"
  },
  {
    "cc": "SC",
    "country": "SEYCHELLES"
  },
  {
    "cc": "SL",
    "country": "SIERRA LEONE"
  },
  {
    "cc": "SG",
    "country": "SINGAPORE"
  },
  {
    "cc": "SK",
    "country": "SLOVAKIA"
  },
  {
    "cc": "SI",
    "country": "SLOVENIA"
  },
  {
    "cc": "SB",
    "country": "SOLOMON ISLANDS"
  },
  {
    "cc": "SO",
    "country": "SOMALIA"
  },
  {
    "cc": "ZA",
    "country": "SOUTH AFRICA"
  },
  {
    "cc": "ES",
    "country": "SPAIN"
  },
  {
    "cc": "LK",
    "country": "SRI LANKA"
  },
  {
    "cc": "KN",
    "country": "St. Kitts und Nevis"
  },
  {
    "cc": "LC",
    "country": "St. Lucia"
  },
  {
    "cc": "VC",
    "country": "St. Vincent und Grenadinen"
  },
  {
    "cc": "SD",
    "country": "SUDAN"
  },
  {
    "cc": "SR",
    "country": "SURINAME"
  },
  {
    "cc": "SJ",
    "country": "SVALBARD AND JAN MAYEN"
  },
  {
    "cc": "SZ",
    "country": "SWAZILAND"
  },
  {
    "cc": "SE",
    "country": "SWEDEN"
  },
  {
    "cc": "CH",
    "country": "SWITZERLAND"
  },
  {
    "cc": "SY",
    "country": "SYRIAN ARAB REPUBLIC"
  },
  {
    "cc": "TW",
    "country": "TAIWAN, PROVINCE OF CHINA"
  },
  {
    "cc": "TJ",
    "country": "TAJIKISTAN"
  },
  {
    "cc": "TZ",
    "country": "Tansania"
  },
  {
    "cc": "TH",
    "country": "THAILAND"
  },
  {
    "cc": "TL",
    "country": "TIMOR-LESTE"
  },
  {
    "cc": "TG",
    "country": "TOGO"
  },
  {
    "cc": "TK",
    "country": "Tokelau-Inseln (Neuseeland)"
  },
  {
    "cc": "TO",
    "country": "TONGA"
  },
  {
    "cc": "TT",
    "country": "TRINIDAD AND TOBAGO"
  },
  {
    "cc": "TN",
    "country": "TUNISIA"
  },
  {
    "cc": "TR",
    "country": "TURKEY"
  },
  {
    "cc": "TM",
    "country": "TURKMENISTAN"
  },
  {
    "cc": "TV",
    "country": "Tuvalu"
  },
  {
    "cc": "UG",
    "country": "UGANDA"
  },
  {
    "cc": "UA",
    "country": "UKRAINE"
  },
  {
    "cc": "AE",
    "country": "UNITED ARAB EMIRATES"
  },
  {
    "cc": "GB",
    "country": "UNITED KINGDOM"
  },
  {
    "cc": "US",
    "country": "UNITED STATES"
  },
  {
    "cc": "UY",
    "country": "URUGUAY"
  },
  {
    "cc": "UZ",
    "country": "UZBEKISTAN"
  },
  {
    "cc": "VU",
    "country": "VANUATU"
  },
  {
    "cc": "VA",
    "country": "Vatikanstadt"
  },
  {
    "cc": "VE",
    "country": "VENEZUELA"
  },
  {
    "cc": "VN",
    "country": "VIET NAM"
  },
  {
    "cc": "VG",
    "country": "VIRGIN ISLANDS, BRITISH"
  },
  {
    "cc": "YE",
    "country": "YEMEN"
  },
  {
    "cc": "ZM",
    "country": "ZAMBIA"
  },
  {
    "cc": "ZW",
    "country": "ZIMBABWE"
  }
]