import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AppData, UserService } from '../services';

@Injectable({
    providedIn: 'root',
})
/**
 * This guard will be called on every route, which does NOT need user authentication, and redirect the user away from it
 * e.g. an already logged in user cannot see the /login page
 */
export class IsLoggedInGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService, private appData: AppData) {}

    async canActivate() {
        const userIsLoggedIn: boolean = await this.userService.getCurrentUserData();

        if (userIsLoggedIn) {
            this.router.navigate([this.appData.startPage]);
        } else {
            return true;
        }
    }
}
