import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subject, Subscription } from 'rxjs';
import { share, distinctUntilChanged, debounceTime, take } from 'rxjs/operators';

import { AppData } from './../../services/app-data.service';
import { MessageService } from './../../services/messageService';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnDestroy {
    private queryChanged = new Subject<string>();
    private querySubscription: Subscription;

    data: any;
    pageSize = 20;
    offset = 0;
    sort: SortDescriptor[] = [];
    query: string;
    filter: any;
    loading = false;

    constructor(
        private appData: AppData,
        private userService: UserService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.appData.loadingEvents.next('start');

        const currentPage = Math.max(parseInt(this.route.snapshot.queryParams.p, 10), 1);
        const pageSize = parseInt(this.route.snapshot.queryParams.s, 10);
        const query = this.route.snapshot.queryParams.q;

        if (pageSize) {
            this.pageSize = pageSize;
        }
        if (currentPage) {
            this.offset = this.pageSize * (currentPage - 1);
        }
        if (query) {
            this.query = query;
        }

        this.handleFilter();
        this.loadList();
    }

    ngOnDestroy() {
        this.querySubscription.unsubscribe();
    }

    /**
     * Handles everything that has to do with the query filter
     */
    private handleFilter() {
        const obs$ = this.queryChanged.pipe(share(), distinctUntilChanged(), debounceTime(300));
        this.querySubscription = obs$.subscribe((query) => {
            this.offset = 0;
            this.query = query;
            this.loadList();
        });
    }

    /**
     * Adds ?p=<currentPage>&s=<pageSize> according to pageSize and offset
     */
    private updateQueryParams() {
        let currentPage = Math.floor(this.offset / this.pageSize) + 1;

        if (currentPage === 1) {
            currentPage = null;
        }

        // update url query
        // this.router.navigate([], {
        //     relativeTo: this.route,
        //     queryParams: {
        //         p: currentPage,
        //         s: pageSize,
        //         q: this.query || null
        //     },
        //     queryParamsHandling: 'merge',
        //     skipLocationChange: true
        // });
    }

    pageNumChange(num: number) {
        this.offset = (this.pageSize - 1) * num;
        this.loadList();
    }

    changedPageSize(pageSize: number) {
        this.pageSize = pageSize;
        this.offset = 0;
        this.loadList();
    }

    onPageChange(state: any): void {
        this.pageSize = state.take;
        this.offset = state.skip;
        this.loadList();
    }

    onSortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadList();
    }

    onEnterCurrentPage(evt) {
        const currentPage = parseInt(evt.target.value, 10);
        this.offset = (currentPage - 1) * this.pageSize;
        this.loadList();
    }

    onKeyupSearch(evt) {
        this.queryChanged.next(evt.target.value);
    }
    onClickLoadlist() {
        this.loadList();
    }

    onClickDelete(item) {
        this.userService
            .delete('user', item.id)
            .pipe(take(1))
            .subscribe(
                (res) => {
                    this.messageService.basicToast('Eintrag wurde gelöscht', 'success');
                },
                () => {
                    this.messageService.basicToast('Eintrag konnte nicht gelöscht werden', 'error');
                }
            );
    }

    onClickOpen(item) {
        this.router.navigateByUrl('/user/' + item.id);
    }

    private loadList(): void {
        this.updateQueryParams();

        this.appData.loadingEvents.next('start');
        this.userService
            .getList('user', this.offset, this.pageSize)
            .pipe(take(1))
            .subscribe(
                (res) => {
                    this.appData.loadingEvents.next('end');
                    this.data = {
                        data: res,
                        total: 100,
                    };
                },
                () => {
                    this.appData.loadingEvents.next('end');
                    this.messageService.basicToast('Data could not be loaded.', 'error');
                }
            );
    }
}
