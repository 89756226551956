import { AssigneeSalary } from './../../../../models/assignee-salary';
import { MessageService } from './../../../../services/messageService';
import { MainService } from 'src/app/services/main.service';
import { AppData } from './../../../../services/app-data.service';
import { AssigneeService } from './../../../../routes/assignee/assignee.service';
import { ProjectService } from './../../../../routes/project/project.service';
import { Project } from 'src/app/models/project';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Assignee } from 'src/app/models/assignee';
import { Component, OnInit, Input, OnChanges, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SendingEntity } from 'src/app/models/sending-entity';
import { SendingEntityService } from 'src/app/routes/sending-entity/sending-entity.service';
import { ReceivingEntity } from 'src/app/models/receiving-entity';
import { ReceivingEntityService } from 'src/app/routes/receiving-entity/receiving-entity.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { EventEmitter, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { countries } from 'countries-list/dist';
import { ViewChild } from '@angular/core';
import HelpTexts from 'src/assets/help-texts.js';
import CC from 'src/assets/cc.js';

import LanguageDe from 'src/assets/lang.de.js';

@Component({
    selector: 'app-assignee-reporting-profile',
    templateUrl: './profile.report.component.html',
    styleUrls: ['./profile.report.component.scss'],
})
export class ProfileReportComponent implements OnInit, OnChanges {
    @Input()
    public assignee: Assignee;
    @Input()
    public useDependents: Boolean;
    @Input()
    public disabled: Boolean;
    @Output('dependentDelete')
    public dependentDelete = new EventEmitter<Assignee>();

    @ViewChild('citizenship', { static: false }) public citizenshipAc: any;
    @ViewChild('caseWorkerAc', { static: false }) public caseWorkerAc: any;
    @ViewChild('validatorNameAc', { static: false }) public validatorNameAc: any;
    @ViewChild('lawyerAc', { static: false }) public lawyerAc: any;

    public showMail = false;

    public profileForm: FormGroup;
    public sendingEntities: SendingEntity[];
    public receivingEntities: ReceivingEntity[];
    public projects: Project[];
    public isVisibleExempted$: Observable<boolean> = this.assigneeService.getVisibleExempted();
    public permissions = this.appData.permissions;
    public assigneeSalaries: AssigneeSalary[];

    public faInfoCircle = faInfoCircle;

    public openMe = false;
    public howToData;

    public genders = [
        {
            value: 'DIVERSE',
            text: 'Diverse',
        },
        {
            value: 'MALE',
            text: 'Male',
        },
        {
            value: 'FEMALE',
            text: 'Female',
        },
    ];

    public exemptedCategories = [];
    public employeeReportingType = [];
    public assigneeReportingMode = [];
    public superUser = [];
    public reviewer = [];
    public lawyer = [];
    public caseWorker = [];
    public dependents = [];

    public howToUseText;

    public countries: any;

    public texts = HelpTexts;

    public ccs = CC;
    public filteredCCs = [];

    @Input()
    public selectedLanguage = 'en';

    public showHistory = false;

    public importantUsers = ['e.oberhammer@oberhammer.co.at', 'v.arnez@oberhammer.co.at', 't.nunner@oberhammer.co.at'];

    constructor(
        private formBuilder: FormBuilder,
        private sendingEntityService: SendingEntityService,
        private receivingEntityService: ReceivingEntityService,
        private projectService: ProjectService,
        private assigneeService: AssigneeService,
        private appData: AppData,
        private mainService: MainService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private httpClient: HttpClient
    ) {
        this.assignee = new Assignee();
        this.filteredCCs = this.ccs;
        this.countries = Object.keys(countries).map((cc) => ({
            cc: cc,
            label: countries[cc].name + ' - ' + countries[cc].emoji,
        }));
        this.sendingEntityService
            .getSendingEntities({
                start: 0,
                limit: 1000,
                orderBy: { field: 'name', dir: 'asc' },
            })
            .subscribe((data) => {
                this.sendingEntities = data.list;
            });

        this.receivingEntityService
            .getReceivingEntities({
                start: 0,
                limit: 1000,
                orderBy: { field: 'name', dir: 'asc' },
            })
            .subscribe((data) => {
                this.receivingEntities = data.list;
            });

        httpClient
            .get<any>('/comic/rest/constants')
            .toPromise()
            .then((res) => {
                const data = res.content;
                this.exemptedCategories = data.exemptedProfilesCategories.map((v) => ({ value: v, text: v }));
                this.employeeReportingType = data.employeeReportingType.map((v) => ({ value: v, text: v }));
                this.assigneeReportingMode = data.assigneeReportingMode.map((v) => ({ value: v, text: v }));

                const comboBoxValues = (arr) => {
                    return arr.map((v) => ({ value: v.toLowerCase(), text: v.toLowerCase() }));
                };
                if (data.superUser) {
                    this.superUser = comboBoxValues(data.superUser);
                }
                if (data.lawyer) {
                    this.lawyer = comboBoxValues(data.lawyer);
                }
                if (data.caseWorker) {
                    this.caseWorker = comboBoxValues(data.caseWorker);
                }
                if (data.reviewer) {
                    this.reviewer = comboBoxValues(data.reviewer);
                }
            });
    }

    public ngOnChanges() {
        if (this.assignee) {
            if (this.assignee.id) {
                this.assigneeService.getAssigneeSalaries(this.assignee.id).subscribe((assigneeSalaries) => {
                    this.assigneeSalaries = assigneeSalaries;
                });
            }
            this.createForm();
        }
    }

    private createForm() {
        this.profileForm = this.formBuilder.group({
            firstname: [this.assignee.firstname, [Validators.required]],
            lastname: [this.assignee.lastname, [Validators.required]],
            birthday: [this.assignee.birthday],
            gender: [this.assignee.gender],
            street: [this.assignee.street],
            zipCode: [this.assignee.zipCode],
            city: [this.assignee.city],
            country: [this.assignee.country],
            citizenship: [this.assignee.citizenship],
            startDate: [this.assignee.startDate, [Validators.required]],
            endDate: [this.assignee.endDate, [Validators.required]],
            workPermitFrom: [this.assignee.workPermitFrom],
            workPermitTo: [this.assignee.workPermitTo],
            visaRpFrom: [this.assignee.visaRpFrom],
            visaRpTo: [this.assignee.visaRpTo],

            salary: [this.assignee.salary],
            dailyGrossUp: [this.assignee.dailyGrossUp],
            effectiveFrom: [null],

            salaryPaymentDeadline: [this.assignee.salaryPaymentDeadline, [Validators.required]],
            sendingEntity: [{ value: this.assignee.sendingEntity }],
            receivingEntity: [{ value: this.assignee.receivingEntity }],
            projects: [
                { value: this.assignee.projects, disabled: !this.permissions.ASSIGNEE_EDIT },
                [Validators.required],
            ],
            workingTimeAustria: [this.assignee.workingTimeAustria],
            workingTimeHomeCountry: [this.assignee.workingTimeHomeCountry],
            holidayEntitlementAustria: [this.assignee.holidayEntitlementAustria],
            holidayEntitlementHomeCountry: [this.assignee.holidayEntitlementHomeCountry],
            exempted: [this.assignee.exempted],
            billingCode: [this.assignee.billingCode],
            personnelNumber: [this.assignee.personnelNumber],
            accessCardNumber: [this.assignee.accessCardNumber],
            alienActOnly: [this.assignee.alienActOnly],
            activeMonitoring: [this.assignee.activeMonitoring],
            internalNote: [this.assignee.internalNote],
        });
        const body = this.profileForm.getRawValue();

        if (!body['salaryPaymentDeadline']) {
            this.profileForm.controls['salaryPaymentDeadline'].setValue(0);
        }
    }

    async ngOnInit() {
        if (!this.useDependents) {
            this.assignee = this.assignee as Assignee;
            return;
        }
        const split = this.router.url.split('/');
        const key = split[split.length - 1];
        console.log('ON INIT');
        if (key !== 'new') {
            this.assignee = await this.assigneeService.getAssigneeByMongoKey(key).toPromise();
            if (this.assignee.visaRpFrom.getTime() <= 946681200000) {
                delete this.assignee.visaRpFrom;
            }
            if (this.assignee.visaRpTo.getTime() <= 946681200000) {
                delete this.assignee.visaRpTo;
            }
            console.log('ASSIGNEE', this.assignee);
            this.dependents = this.assignee.userInfo.reportingModeInfo.dependents.map((d) => new Assignee(d));
            this.createForm();
        }
    }

    public addDependent() {
        const ass = new Assignee();
        ass.mongoKey = uuidv4();
        ass.userInfo.reportingModeInfo.reportingMode = 'Private Client - Category 7';
        this.dependents.push(ass);
    }

    public removeDependent(ass, event) {
        if (this.useDependents) {
            console.log('FILTER AND SET', ass);
            const ids = this.dependents.map((d) => d.mongoKey);
            console.log(ids, ass.mongoKey);
            this.dependents = this.dependents.filter((d) => d.mongoKey !== ass.mongoKey);
            console.log('Dependents', this.dependents);
        } else {
            if (event) {
                event.preventDefault();
            }
            console.log('FURTHER EMIT ASSIGNEE', ass.mongoKey);
            console.log(this.dependents, ass);
            this.dependentDelete.emit(ass);
        }
    }

    public addMail() {
        this.showMail = !this.showMail;
    }

    public preSave() {
        const firstName = this.assignee.firstname;
        const lastName = this.assignee.lastname;
        const citizenship = this.assignee.citizenship;
        Object.assign(this.assignee, this.profileForm.value);
        this.assignee.citizenship = citizenship;
        this.assignee.userInfo.reportingModeInfo.dependents = this.dependents;
        this.assignee.firstname = firstName;
        this.assignee.lastname = lastName;
    }

    public async onClickSave() {
        this.preSave();
        const url = '/comic/rest/reporting/assignee';
        const validator = this.assignee.userInfo.reportingModeInfo.validatorName;
        this.setStartDate();
        this.setEndDate();
        const id = this.route.snapshot.params.id * 1;
        const copyId = this.route.snapshot.params.copyId * 1;

        console.log('id', id);
        console.log('copy id', copyId);
        if (isNaN(id)) {
            if (!isNaN(copyId)) {
                console.log('clear assignee id...');
                this.assignee.id = null;
                this.assignee.userInfo.userId = null;
                this.assignee.userInfo.id = null;
                this.assignee.userInfo.isArchived = false
            }
        }
        try {
            this.appData.showLoading = true;
            const res = await this.httpClient.post<any>(url, this.assignee).toPromise();
            const hasId = res.content.assignee.id;
            this.appData.showLoading = false;
            if (hasId) {
                this.messageService.basicToast('Entity saved successfully.!');
            } else {
                this.messageService.basicToast(
                    `This assignee will be reviewed by ${validator}.<br>After review the assignee will be available in the assignee list!`
                );
            }
            this.router.navigate(['/assignee']);
        } catch (e) {
            this.appData.showLoading = false;
            this.messageService.basicToast(e.error.content.message, 'error');
        }
    }

    public async onClickReject() {
        this.preSave();
        const url = '/comic/rest/reporting/assignee/reject';
        this.setStartDate();
        this.setEndDate();
        try {
            this.appData.showLoading = true;
            await this.httpClient.post<any>(url, this.assignee).toPromise();
            this.appData.showLoading = false;
            this.messageService.basicToast('Assignee rejected!');
            this.router.navigate(['/assignee']);
        } catch (e) {
            this.appData.showLoading = false;
            this.messageService.basicToast(e.error.content.message, 'error');
        }
    }

    public async onClickConfirm() {
        this.preSave();
        const url = '/comic/rest/reporting/assignee/confirm';
        this.setStartDate();
        this.setEndDate();
        try {
            this.appData.showLoading = true;
            await this.httpClient.post<any>(url, this.assignee).toPromise();
            this.appData.showLoading = false;
            this.messageService.basicToast('Assignee confirmed!');
            this.router.navigate(['/assignee']);
        } catch (e) {
            this.appData.showLoading = false;
            this.messageService.basicToast(e.error.content.message, 'error');
        }
    }

    public disableFields() {
        return !this.editMode();
    }

    public editMode() {
        //console.log(this.disabled)
        if (this.disabled) {
            return true;
        }
        if (!this.appData || !this.appData.loggedInUser) {
            return false;
        }
        if (this.importantUsers.includes(this.appData.loggedInUser.username.toLowerCase())) {
            //console.log("USER IS OBERHAMMER, return true")
            return true;
        }
        if (
            !this.canReview() &&
            this.assignee.userInfo.reportingModeInfo.caseWorker === this.appData.loggedInUser.username
        ) {
            //console.log("user cannot review and user is case worker")
            return true;
        }
        if (!this.assignee.userInfo.reportingModeInfo.caseWorker) {
            return true;
        }
        return (
            this.assignee.userInfo.reportingModeInfo.caseWorker === this.appData.loggedInUser.username.toLowerCase() ||
            (!this.assignee.mongoKey && !this.assignee.id)
        );
    }

    public canReview() {
        if (!this.appData || !this.appData.loggedInUser) {
            return false;
        }
        if (this.importantUsers.includes(this.appData.loggedInUser.username.toLowerCase())) {
            return true;
        }
        return (
            this.appData.loggedInUser.username.toLowerCase() === this.assignee.getReviewer() &&
            this.assignee.status === 'UNDER VALIDATION'
        );
    }

    private setStartDate() {
        if (!this.assignee.startDate) {
            if (this.assignee.workPermitFrom) {
                this.assignee.startDate = this.assignee.workPermitFrom;
            } else if (this.assignee.visaRpFrom) {
                this.assignee.startDate = this.assignee.visaRpFrom;
            }
        }
    }

    private setEndDate() {
        if (!this.assignee.endDate) {
            if (this.assignee.workPermitTo) {
                this.assignee.endDate = this.assignee.workPermitTo;
            } else if (this.assignee.visaRpTo) {
                this.assignee.endDate = this.assignee.visaRpTo;
            }
        }
    }

    private toggleAc(ac) {
        ac.toggle(true);
    }

    private filterCountryCodes(txt) {
        console.log(txt);
        this.filteredCCs = this.ccs.filter(
            (cc) => cc.cc.toLowerCase() === txt.toLowerCase() || cc.country.toLowerCase().startsWith(txt.toLowerCase())
        );
    }

    private toggleLanguage() {
        if (this.selectedLanguage === 'en') {
            this.selectedLanguage = 'de';
        } else {
            this.selectedLanguage = 'en';
        }
    }

    private toggleHistory() {
        this.showHistory = !this.showHistory;
    }

    private getTranslatedString(str) {
        if (this.selectedLanguage === 'en') {
            return str;
        }
        if (LanguageDe[str]) {
            return LanguageDe[str];
        }
        return str;
    }
}
