import { Guid } from 'guid-typescript';
import { Assignee } from 'src/app/models/assignee';

export class TimeRecord {
    public id: number;
    public assignee: Assignee;
    public day: Date;
    public beginTime: string;
    public endTime: string;
    public beginTimeDt: Date; // temp
    public endTimeDt: Date; // temp
    public pause: number = null;
    public overtimeCompliant: boolean;
    public exempt: boolean;
    public onLeave: boolean;
    public sick: boolean;
    public workedTimeInMillis: number;
    public hoursWorked: string;
    public saveWithWorkedTimeOverMaxPerDay: boolean;
    public week: number;

    public calculatedOvertime: number;
    public loading: boolean; // temp
    public internalId: string; // temp
    public optionsOpen = false;
    public austriaHoliday = false;
}
