import { Component, Input, ViewChild } from '@angular/core';
import { Assignee } from '../../../models/assignee';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AssigneeService } from '../../../routes/assignee/assignee.service';
import { MessageService } from '../../../services/messageService';
import { PayslipService } from '../../../services/payslips.service';
import { AppData } from '../../../services/app-data.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MainService } from '../../../services/main.service';
import { ModalComponent } from '../../modal/modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../services/user.service';
import { Payslip } from '../../../models/payslip';

@Component({
    selector: 'app-assignee-payslips',
    templateUrl: './payslips.component.html',
    styleUrls: ['./payslips.component.scss', '../../../css/webflow.css', '../../../css/oberhammer-payslip.webflow.css'],
})
export class PayslipsComponent {
    public assignee: Assignee;

    // When that component gets constructed, we need to wait for the parent component to pass the date via HTML
    // When we receive the data, prepare the view
    @Input('assignee')
    set internalAssignee(a: Assignee) {
        if (a) {
            this.assignee = a;
            this.initView();
        }
    }

    public payslipsForm: FormGroup;
    public assigneeForm: FormGroup;

    public payslips: Payslip[] = [];
    public currentPayslip: Payslip;

    public monthFilter: object;
    public monthFilterOptions: any[] = [];
    public layout = 0;

    public openMe = false;

    @ViewChild('howToUseModal', {
        static: true,
    })
    public howToUseModal: ModalComponent;
    @ViewChild('downloadPayslipModal', {
        static: true,
    })
    public downloadPayslipModal: ModalComponent;
    public payslipFileName;
    @ViewChild('downloadNonGermanPayslipModal', {
        static: true,
    })
    public downloadNonGermanPayslipModal: ModalComponent;
    public payslipNonGermanFileName;

    @ViewChild('rejectPayslipModal', {
        static: true,
    })
    public rejectPayslipModal: ModalComponent;

    @ViewChild('rejectNonGermanPayslipModal', {
        static: true,
    })
    public rejectNonGermanPayslipModal: ModalComponent;

    public sampleData = [1, 2, 3, 4, 5];
    public howToData;
    public tenantName_;

    public section2InfoModal: ModalComponent;
    public section2Info = `
    Once the payslip has been uploaded in the required language(es), please process to section II
    and upload the payment confirmation.
    <br>
    <br>
    If you upload the bank transfer-statement, this task is completed and the employer and
    employee confirmations are not required. If a bank transfer-statement cannot be uploaded,
    please use the employer and employee confirmations (see section III) below, have them signed
    and upload them in the respective boxes in this section II.
    <br>
    <br>
    <span class="important">Attention</span>: the amounts stated in the comic pre-generated employer/employee declarations
    correspond to the data provided to the system and such data must correspond to the data shown
    on the payslip. If not, the comic pre-generated documents cannot be used and declarations
    stating the correct amounts must be created, signed and uploaded. If such case happens, please
    contact the <span class="important">corporate migration center team</span> as soon as possible as in such case it is
    likely the changes to the reported information have not been communicated to the system und to
    the authorities.
    <br>
    <br>
    <span class="important">This task is completed once the information in the boxes I. II. and IV. are uploaded.</span>
    
    `

     /*
     * how to use text
     */
    public howToUseText;

    private payslipIsCompliant;

    constructor(
        private formBuilder: FormBuilder,
        private assigneeService: AssigneeService,
        private messageService: MessageService,
        public payslipService: PayslipService,
        private appData: AppData,
        public datePipe: DatePipe,
        public mainService: MainService,
        private sanitizer: DomSanitizer,
        public userService: UserService,
        private httpClient: HttpClient
    ) {
        this.processHowToData('payslips');
        this.howToUseText = this.appData.getHowToData("payslips");
    }

    /**
     * Perform all initial actions and requests to receive the data in the correct order and build the view
     */
    private initView(): void {
        this.getTenant();
        /** Construct the forms and fill in the default values */
        this.assigneeForm = this.formBuilder.group({
            notifyAssigneeAboutPayslips: [this.assignee.notifyAssigneeAboutPayslips],
            notifyOrgAdminsAboutPayslips: [this.assignee.notifyOrgAdminsAboutPayslips],
        });
        // this.payslipsForm = this.formBuilder.group({
        //     fileUploadDate: [null],
        //     accepted: [false]
        // });
        this.payslipsForm = this.formBuilder.group({
            fileUploadDate: [null],
            accepted: [false],
        });

        /** Check if called by mobile device */
        this.userService.getMobileAppicationView().subscribe(
            (res) => {
                this.appData.isMobileApplicationView = res;
            },
            (error) => {
                console.error('PayslipsComponent.prepareView() - Error getting mobile application view', error);
            }
        );

        /** Check if a flag for appending an addional month in the filter options is set in the DB for that assignee */
        this.payslipService.addMonthForDailyGrossUp(this.assignee).subscribe(
            (additionalMonth) => {
                /** get the layout (returns just a number) */
                this.payslipService.payslipsLayout().subscribe(
                    (layout) => {
                        this.layout = layout;

                        /** process of getting the the payslip data */
                        this.buildPayslipFilterOptions(additionalMonth);
                    },
                    (error) => {
                        console.error('PayslipsComponent.prepareView() - Error getting payslips layout', error);
                    }
                );
            },
            (error) => {
                console.error('PayslipsComponent.prepareView() - Error @ addMonthForDailyGrossUp', error);
            }
        );
    }

    /**
     * Build an array of filter options base on all payslips
     * e.g. if startDate = Oct 2012 & endDate = Feb 2013, then add all the following entries
     * (Oct 2012, Nov 2012, Dec 2012, Jan 2013, Feb 2013)
     * @param additionalMonth Determines if an additional month is appended to the list of filter options
     */
    private buildPayslipFilterOptions(additionalMonth: boolean): void {
        const startDate = new Date(this.assignee.startDate);
        const endDate = new Date(this.assignee.endDate);
        const today = new Date();

        let monthsBetween = this.mainService.getDateDifferencesInMonths(startDate, endDate);

        /** If the flag additionalMoth has been passed, and the endDate is past, append an additional month */
        if (additionalMonth && today >= endDate) {
            monthsBetween++;
        }

        let filterOption = this.mainService.clone(startDate);

        while (monthsBetween > 0) {
            if (filterOption.getMonth() > 11) {
                filterOption = new Date(filterOption.getFullYear() + 1, 0, 1);
            } else {
                this.monthFilterOptions.push({
                    year: filterOption.getFullYear(),
                    month: filterOption.getMonth() + 1,
                    text: filterOption.getFullYear() + ' - ' + this.datePipe.transform(new Date(filterOption), 'MMMM'),
                });

                filterOption = new Date(filterOption.getFullYear(), filterOption.getMonth() + 1, 1);
            }

            monthsBetween--;
        }

        /**
         * Get all payslips and update filter options
         * Enrich filter options with properties like reviewedBy, reviewedDate, missing
         */
        this.payslipService.payslipsByAssignee(this.assignee.id).subscribe(
            (payslips) => {
                if (payslips.length && this.monthFilterOptions.length) {
                    payslips.forEach((p) => {
                        this.monthFilterOptions.forEach((o) => {
                            let matchedPayslip = p;

                            /** If NOT matching, continue with next iteration */
                            if (p.month !== o.month || p.year !== o.year) {
                                return true;
                            }

                            if (this.layout === 2) {
                                o.missing = matchedPayslip.isModel ? matchedPayslip.missing : true;
                                o.reviewedDate = matchedPayslip.reviewedDate;

                                if (matchedPayslip.reviewedBy != null && matchedPayslip.reviewedBy.firstname.length) {
                                    o.reviewedBy =
                                        matchedPayslip.reviewedBy.firstname + ' ' + matchedPayslip.reviewedBy.lastname;
                                }
                            } else {
                                if (matchedPayslip != null && matchedPayslip.fileName && !matchedPayslip.rejectedDate) {
                                    o.reviewedDate = matchedPayslip.reviewedDate;

                                    if (matchedPayslip.reviewedBy != null && matchedPayslip.reviewedBy.firstname) {
                                        o.reviewedBy =
                                            matchedPayslip.reviewedBy.firstname +
                                            ' ' +
                                            matchedPayslip.reviewedBy.lastname;
                                    }
                                    return true; // continue
                                }
                                o.missing = true;
                            }
                        });
                    });
                }

                /** Pre-Select the last filter option */
                if (this.monthFilterOptions.length) {
                    const d = new Date()
                    const year = d.getFullYear();
                    const month = d.getMonth() + 1;
                    const option = this.monthFilterOptions.find(f => f.year === year && f.month === month)
                    if (option) {
                        this.monthFilter = option    
                    } else {
                        this.monthFilter = this.monthFilterOptions[this.monthFilterOptions.length - 1];
                    }
                }

                /** Get the the current payslip by the current filter selection  */
                this.reloadPayslipByFilter(this.monthFilter);
            },
            (error) => {
                console.error(
                    'PayslipsComponent.buildPayslipFilterOptions() - Error getting all payslips from assignee',
                    error
                );
            }
        );
    }

    /**
     * Request the current payslip by a given filter and update the view with the result.
     * Writes the result of the request into this.currentPayslip.
     * @param event (optional)
     */
    public reloadPayslipByFilter(dateFilter?: any): void {
        this.payslipService.payslipByAssignee(this.assignee.id, dateFilter.year, dateFilter.month).subscribe(
            (payslip) => {
                /** Payslip found, update it */
                if (payslip) {
                    this.currentPayslip = payslip;
                    /** No Payslip found for that month => create one */
                } else {
                    this.currentPayslip = new Payslip({
                        assignee: this.assignee,
                        year: dateFilter.year,
                        month: dateFilter.month,
                    });
                }

                this.checkCompliance(this.currentPayslip)

                /** Patch the new payslip data into the form */
                this.updateFormData();

                /** Check if payslip has an id so we know if there is an existing record in the database. If not, create a new paylip */
                if (this.currentPayslip.isPhantom) {
                    this.payslipService.createPayslip(this.currentPayslip).subscribe(
                        (res) => {
                            this.currentPayslip = res;

                            this.createPayslipEntry();
                        },
                        (error) => {
                            console.error('PayslipsComponent.reloadPayslipByFilter() - Error creating payslip', error);
                        }
                    );

                    /** Check if payslip has an id so we know if there is an existing record in the database */
                } else {
                    this.createPayslipEntry();
                }
            },
            (error) => {
                console.error(
                    'PayslipsComponent.reloadPayslipByFilter() - Error getting payslip by assigne and date filter',
                    error
                );
            }
        );
    }

    /**
     * @TODO Document this one
     */
    private createPayslipEntry() {
        /*
        let payslipEntry = new PayslipEntry({
            payslip: this.currentPayslip,
            isForEmployer: true
        });

        this.payslipService.getPayslipEntries(this.currentPayslip.id, true).subscribe((payslipEntries) => {

            let accepted = false;
            payslipEntries.forEach(p => {

                if (!p.fileName) {
                    return true;
                }
                accepted = true;
        		return false;
            });

            this.currentPayslip.accepted = accepted;

            // Here is some code missing
        });
        */
    }

    /**
     * Update the forms with the data of this.currentPayslip
     */
    private updateFormData(): void {
        switch (this.layout) {
            case 3:
            case 2:
            case 1:
                // nonGermanPayslip wurde geändert, dass es standardgemäß auf false ist
                // acceptedGerman: ist für die Checkbox, ob es checked oder non-checked ist
                // let nongerman_ = this.currentPayslip.nonGermanPayslip != '' || this.currentPayslip.nonGermanPayslip != 'false' ? 'true' : 'false';

                let nongerman_;
                let booltest = 'false';
                if (this.currentPayslip.nonGermanPayslip !== '' || this.currentPayslip.nonGermanPayslip !== booltest) {
                    nongerman_ = this.currentPayslip.nonGermanPayslip;
                } else {
                    nongerman_ = false;
                }
                this.payslipsForm = this.formBuilder.group({
                    fileUploadDate: [this.currentPayslip.fileUploadDate],
                    fileName: [this.currentPayslip.fileName],
                    rejectedDate: [this.currentPayslip.rejectedDate],
                    rejectReason: [this.currentPayslip.rejectReason],
                    nonGermanRejectedDate: [this.currentPayslip.nonGermanRejectedDate],
                    nonGermanRejectedReason: [this.currentPayslip.nonGermanRejectedReason],
                    reviewedDate: [this.currentPayslip.reviewedDate],
                    accepted: [false],
                    confirm: [this.currentPayslip.employerConfirmedPaymentSent ? true : false],
                    nonGermanPayslip: [nongerman_],
                    acceptednonGermanPayslip: [false],
                    // nonGermanPayslip: [false],
                    // acceptedGerman: [this.currentPayslip.nonGermanPayslip ? true : false],
                    acceptedGerman: [false],
                    employerConfirmation: [this.currentPayslip.employerConfirmation],
                    employeeConfirmation: [this.currentPayslip.employeeConfirmation],
                    originalBankTransferStatement: [this.currentPayslip.originalBankTransferStatement],
                    employerConfirmedPaymentSent: [this.currentPayslip.employerConfirmedPaymentSent],
                    confirmedPaymentReceived: [this.currentPayslip.confirmedPaymentReceived],
                });
                break;
            case 0:
            default:
                this.payslipsForm = this.formBuilder.group({
                    fileUploadDate: [this.currentPayslip.fileUploadDate],
                    fileName: [this.currentPayslip.fileName],
                    rejectedDate: [this.currentPayslip.rejectedDate],
                    rejectReason: [this.currentPayslip.rejectReason],
                    reviewedDate: [this.currentPayslip.reviewedDate],
                    accepted: [this.currentPayslip.fileUploadDate ? true : false],
                    nonGermanRejectedDate: [this.currentPayslip.nonGermanRejectedDate],
                    nonGermanRejectedReason: [this.currentPayslip.nonGermanRejectedReason],
                });
                break;
        }
    }

    public onClickRequestChanges() {
        this.assigneeService.getAssignee(this.assignee.id).subscribe((originalAssignee) => {
            const changes = this.assigneeService.getChangesAsMetaData(this.payslipsForm.value, originalAssignee);

            const changesString = JSON.stringify(changes);

            this.assignee.metaData = changesString;
            this.assigneeService.saveAssignee(this.assignee).subscribe((assignee) => {
                this.assignee = assignee;
                this.updateFormData();
                this.messageService.basicToast('Your changes will be applied after review.');
            });
        });
    }

    public onAccepted(event?: any) {
        if (this.tenantName_ !== 'accenture') {
            this.mainService.prompt(
                'Please confirm that you are now uploading a German version of your payslip or the payslip together with a German translation as a single file!',
                (val, action) => {
                    if (action === 'yes') {
                        // continue, to display some fields
                        try {
                            this.payslipsForm.get('accepted').patchValue(true);
                        } catch (e) {
                            console.error(e);
                        }
                    } else {
                        // reset the accepted
                        this.payslipsForm.get('accepted').patchValue(false);
                    }
                },
                'confirm'
            );
        } else {
            /*this.mainService.prompt(
                'Please confirm that you are now uploading a German version of your payslip!',
                (val, action) => {
                    if (action === 'yes') {
                        // continue, to display some fields
                        try {
                            this.payslipsForm.get('accepted').patchValue(true);
                        } catch (e) {
                            console.error(e)
                        }
                    } else {
                        // reset the accepted
                        this.payslipsForm.get('accepted').patchValue(false);
                    }
                },
                'confirm'
            );*/
            try {
                this.payslipsForm.get('accepted').patchValue(true);
            } catch (e) {
                console.error(e);
            }
        }
    }

    public clickedAcceptNonGermanPayslipTermsBeforeUpload(event?: any) {
        this.mainService.prompt(
            'Please confirm that you are now uploading a Non-German version of your payslip!',
            (val, action) => {
                if (action === 'yes') {
                    // continue, to display some fields
                    this.payslipsForm.get('acceptedGerman').patchValue(true);

                    this.payslipsForm.get('acceptednonGermanPayslip').patchValue(true);
                } else {
                    // reset the accepted
                    this.payslipsForm.get('acceptedGerman').patchValue(false);

                    this.payslipsForm.get('acceptednonGermanPayslip').patchValue(false);
                }
            },
            'confirm'
        );

        // console.log(this.payslipsForm.get('nonGermanPayslip'));
    }

    public showAppManual() {
        window.location.href = '/comic/filedownload?fileName=comic_go.pdf&isManualFile=true';
    }

    public showHowTo() {
        // console.log('show how to');
        let content;
        switch (this.layout) {
            case 0:
                content =
                    'You and your employer are required to maintain on record your ' +
                    'payslips for each month for the duration of a project.';
                content += '<br/><br/>';
                content +=
                    '!!! Please make sure to upload your payslips for every month within ' +
                    'the first days after it is issued to you by your employer. Upon upload, ' +
                    'you will be required to confirm that you have actually received the ' +
                    'salary declared to the Austrian immigration authorities on your ' +
                    'bank account (that is done by activating a click-box).';
                content += '<br/><br/>';
                content +=
                    'Based on your upload three additional documents are generated: ' +
                    'A receipt of payment confirmation to your employer, a payment confirmation ' +
                    'by your employer to you and a bilingual payslip covering the amount ' +
                    'declared to the Austrian authorities (typically in your Assignment Agreement).';
                content += '<br/><br/>';
                content +=
                    '<b>If your payslips are not up to date you will receive regular prompts ' +
                    'per e-mail reminding you to update the payslips.</b>';
                content += '<br/><br/>';
                content += 'Your corporate migration center team.';
                break;
            case 1:
                content =
                    'You and your employer are required to maintain on record your ' +
                    'payslips for each month for the duration of a project for which you have been on assignment to Austria.';
                content += '<br/><br/>';
                content +=
                    '!!! Please make sure to enter the tool at the start of each month and to confirm ' +
                    'that you have received your salary for the previous month (or months). Upon your confirmation ' +
                    'the system generates a confirmation addressed by you to your employer which is available for viewing ' +
                    'and printing via the “view”-button. Please download, print and sign the confirmation and upload it ' +
                    'via the button “Employee Confirmation”.';
                content += '<br/><br/>';
                content +=
                    '!!! Also please use the button “Bank transfer statement with German translation” to upload your ' +
                    'bank statement which exhibits the arrival of your relevant net salary. If you are unable to set your banking ';
                'program to issue the statement in German, please kindly manually translate (by pen) on the bank transfer ' +
                    'statement: Salary = Gehalt; net = netto; gross = brutto. Please kindly paraph the manual translations. The ' +
                    'bank transfer statement (with a German translation) should exhibit your name and the salary payment including ' +
                    'description and amount (all other items concerning payments made or received can be anonymized).';
                content += '<br/><br/>';
                content +=
                    'In the configuration chosen by your employer it is your employer’s purview to upload your original ' +
                    'payslip in German or your original non-German payslip together with a German translation for each relevant ' +
                    'month within the first days after it is issued to you by your employer. If you notice that your employer has ' +
                    'not uploaded a payslip please kindly address the issue with him/her.';
                content += '<br/><br/>';
                content +=
                    'Based on your and your employer’s uploads three additional documents are generated: A receipt of ' +
                    'payment confirmation issued by you to your employer, a payment confirmation issued by your employer to you ' +
                    'and a bilingual tool-generated payslip covering the amount declared to the Austrian authorities (typically ' +
                    'in your Assignment Agreement). This automatically generated payslip cannot substitute the upload of the ' +
                    'original payslip and its translation into German.';
                content += '<br/><br/>';
                content +=
                    '<b>If your payslips are not up to date you and your employer will receive regular prompts per e-mail ' +
                    'reminding you to update the payslips. We strongly advise against taking action to eliminate the reminders ' +
                    'as they keep you up to date about missing documents and compliance actions (e.g. inputting time records).</b>';
                content += '<br/><br/>';
                content += 'Your corporate migration center team.';
                break;
            case 3:
                content =
                    'You and your employer are required to maintain on record your payslips for each month for ' +
                    'the duration of a project for which you have been on assignment to Austria.';
                content += '<br/><br/>';
                content +=
                    '!!! Please make sure to enter the tool at the start of each month and to upload your German ' +
                    'language payslip or your orginal payslip with a German translation for the previous month (or months). ' +
                    'By uploading the payslip you also confirm that your employer has transferred your salary to your private ' +
                    'bank account. Upon your upload the system generates a confirmation addressed by you to your employer which ' +
                    'is available for viewing and printing via the “view”-button. Please download, print and sign the ' +
                    'confirmation and upload it via the button “Employee Confirmation”.';
                content += '<br/><br/>';
                content +=
                    '!!! Also please use the button “Bank transfer statement with German translation” to upload your ' +
                    'bank statement which exhibits the arrival of your relevant net salary. If you are unable to set your banking ' +
                    'program to issue the statement in German, please kindly manually translate (by pen) on the bank transfer ' +
                    'statement: Salary = Gehalt; net = netto; gross = brutto. Please kindly paraph the manual translations. The ' +
                    'bank transfer statement (with a German translation) should exhibit your name and the salary payment including ' +
                    'description and amount (all other items concerning payments made or received can be anonymized).';
                content += '<br/><br/>';
                content +=
                    'In the configuration chosen by your employer it is your responsibility to upload your original payslip in ' +
                    'German or your original non-German payslip together with a German translation for each relevant month within the ' +
                    'first days after it is issued to you by your employer.';
                content += '<br/><br/>';
                content +=
                    'Based on your and your employer’s uploads three additional documents are generated: A receipt of payment ' +
                    'confirmation issued  by you to your employer, a payment confirmation issued by your employer to you and a bilingual ' +
                    'tool-generated payslip covering the amount declared to the Austrian authorities (typically in your Assignment ' +
                    'Agreement). This automatically generated payslip cannot substitute the upload of the original payslip and its ' +
                    'translation into German.';
                content += '<br/><br/>';
                content +=
                    '<b>If your payslips are not up to date you and your employer will receive regular prompts per e-mail reminding ' +
                    'you to update the payslips. We strongly advise against taking action to eliminate the reminders as they keep you ' +
                    'up to date about missing documents and compliance actions (e.g. inputting time records).</b>';
                content += '<br/><br/>';
                content += 'Your corporate migration center team.';
                break;
        }
        this.howToUseText = content;
        this.howToUseModal.show();
    }

    public showDownloadModal() {
        this.payslipFileName = this.downloadFile(this.payslipsForm.get('fileName').value, true);
        const url = this.payslipFileName.changingThisBreaksApplicationSecurity;
        this.payslipService.checkFile(url).subscribe(
            (res) => {
                // console.log('file found', res);
                if (res && res.success === false) {
                    // console.log('File not found', this.payslipFileName);
                    this.messageService.basicToast('Payslip could not be downloaded.', 'error');
                    return;
                }

                this.downloadPayslipModal.show();
            },
            (error) => {
                // console.log('file could not be opend');
                this.downloadPayslipModal.show();
            }
        );
    }

    public showDownloadGermanModal() {
        this.payslipNonGermanFileName = this.downloadFile(this.payslipsForm.get('nonGermanPayslip').value, true);
        this.downloadNonGermanPayslipModal.show();
    }

    public downloadFile(fileName, disposition = false) {
        const url = '/comic/filedownload?fileName=' + fileName + (disposition ? '&content-disposition=inline' : '');
        if (!disposition) {
            window.location.href = url;
        } else {
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    }

    public downloadConfirmation(field, name) {
        const file = this.payslipsForm.get(field).value;
        const downloadName =
            name +
            '-' +
            this.currentPayslip.year +
            '-' +
            this.datePipe.transform(new Date(this.currentPayslip.year, this.currentPayslip.month - 1), 'MMMM');
        const url = '/comic/filedownload?fileName=' + file + '&downloadName=' + downloadName;

        window.location.href = url;
    }

    public deleteConfirmation(field) {
        this.payslipsForm.get(field).patchValue(null);
        const payslipToSave = this.preparePayslipToSave();
        this.payslipService.savePayslip(payslipToSave).subscribe(
            (s) => {
                // console.log('saved rejecting payslip', s);
                this.messageService.basicToast('Successfully deleted!');
            },
            (e) => {
                // console.log('tried not saved!', e);
                this.messageService.basicToast('Something went wrong', 'error');
            }
        );
    }

    public rejectPayslip() {
        //
        this.payslipsForm.get('rejectedDate').patchValue(new Date());
        this.payslipsForm.get('fileName').patchValue(null);
        this.payslipsForm.get('fileUploadDate').patchValue(null);
        this.payslipsForm.get('reviewedDate').patchValue(null);
        this.payslipsForm.get('accepted').patchValue(false);

        if (this.payslipsForm.get('acceptedGerman')) {
            this.payslipsForm.get('acceptedGerman').patchValue(false);
        }
        if (this.payslipsForm.get('nonGermanPayslip')) {
            this.payslipsForm.get('acceptednonGermanPayslip').patchValue(false);
            this.payslipsForm.get('nonGermanPayslip').patchValue(false);
        }

        const payslipToSave = this.preparePayslipToSave();
        this.payslipService.savePayslip(payslipToSave).subscribe(
            (s) => {
                // console.log('saved rejecting payslip', s);
                this.checkCompliance(payslipToSave)
                this.messageService.basicToast('Successfully rejected!');
            },
            (e) => {
                // console.log('tried not saved!', e);
                this.messageService.basicToast('Something went wrong', 'error');
            }
        );
    }

    public rejectGermanPayslip() {
        //
        this.payslipsForm.get('nonGermanRejectedDate').patchValue(new Date());
        this.payslipsForm.get('nonGermanRejectedReason').patchValue(null);
        if (this.payslipsForm.get('nonGermanPayslip')) {
            this.payslipsForm.get('acceptednonGermanPayslip').patchValue(false);
            this.payslipsForm.get('nonGermanPayslip').patchValue(false);
        }
        /*
        this.payslipsForm.get('fileUploadDate').patchValue(null);
        this.payslipsForm.get('reviewedDate').patchValue(null);*/
        this.payslipsForm.get('accepted').patchValue(false);

        const payslipToSave = this.preparePayslipToSave();
        this.payslipService.savePayslip(payslipToSave).subscribe(
            (s) => {
                // console.log('saved rejecting payslip', s);
                this.checkCompliance(payslipToSave)
                this.messageService.basicToast('Successfully rejected!');
            },
            (e) => {
                // console.log('tried not saved!', e);
                this.messageService.basicToast('Something went wrong', 'error');
            }
        );
    }

    public employerConfirmedpaymentSent() {
        //
        this.payslipsForm.get('employerConfirmedPaymentSent').patchValue(true);
        /*
        this.payslipsForm.get('fileUploadDate').patchValue(null);
        this.payslipsForm.get('reviewedDate').patchValue(null);*/
        this.payslipsForm.get('confirm').patchValue(true);

        const payslipToSave = this.preparePayslipToSave();
        this.payslipService.savePayslip(payslipToSave).subscribe(
            (s) => {
                // console.log('saved rejecting payslip', s);
                this.messageService.basicToast('Successfully accepted!');
            },
            (e) => {
                // console.log('tried not saved!', e);
                this.messageService.basicToast('Something went wrong', 'error');
            }
        );
    }

    public markReviewed() {
        this.payslipsForm.get('reviewedDate').patchValue(new Date());
        // than save
        // now basically save it
        const payslipToSave = this.preparePayslipToSave();
        this.payslipService.savePayslip(payslipToSave).subscribe(
            (s) => {
                // console.log('saved marked as reviewed', s);
                this.messageService.basicToast('Successfully marked as reviewed!');
            },
            (e) => {
                // console.log('not saved as reviewed', e);
                this.messageService.basicToast('Something went wrong', 'error');
            }
        );
    }

    public confirmationFile(type) {
        window.location.href = '/comic/confirmation?receiver=' + type + '&payslipId=' + this.currentPayslip.id;
    }

    public onChangeFile(evt) {
        const src = evt.srcElement;

        this.payslipService.upload(src.files[0]).subscribe(
            (resp) => {
                if (!resp.success) {
                    this.messageService.basicToast('Could not upload file', 'error');
                } else {
                    this.payslipsForm.get('fileName').patchValue(resp.file.fileName);
                    this.payslipsForm.get('fileUploadDate').patchValue(new Date());
                    this.payslipsForm.get('rejectReason').patchValue(null);
                    this.payslipsForm.get('rejectedDate').patchValue(null);
                    this.payslipsForm.get('reviewedDate').patchValue(null);

                    if (!this.payslipsForm.valid) {
                        // console.log('payslip form is not valid');
                        return;
                    }
                    // now basically save it
                    const payslipToSave = this.preparePayslipToSave();
                    this.payslipService.savePayslip(payslipToSave).subscribe(
                        (s) => {
                            // console.log('saved upload?', s);
                            this.checkCompliance(payslipToSave);
                            this.messageService.basicToast('Successfully uploaded!');
                        },
                        (e) => {
                            // console.log('not saved upload!', e);
                            this.messageService.basicToast('File could not be uploaded!', 'error');
                        }
                    );
                    /*
                this.documentService.saveDocument(document).subscribe(() => {
                    this.messageService.basicToast('Upload successful, please wait for review.');
                }, error => {
                    document.fileUploadDate = null;
                    document.fileName = null;
                });
                */
                }
            },
            (error) => {
                this.messageService.basicToast('Could not upload file', 'error');
            }
        );
    }

    public onEmployerConfirmation(evt) {
        const src = evt.srcElement;

        this.payslipService.upload(src.files[0]).subscribe(
            (resp) => {
                if (!resp.success) {
                    this.messageService.basicToast('Could not upload file', 'error');
                } else {
                    this.payslipsForm.get('employerConfirmation').patchValue(resp.file.fileName);

                    if (!this.payslipsForm.valid) {
                        // console.log('payslip form is not valid');
                        return;
                    }
                    // now basically save it
                    const payslipToSave = this.preparePayslipToSave();
                    this.payslipService.savePayslip(payslipToSave).subscribe(
                        (s) => {
                            // console.log('saved upload?', s);
                            this.messageService.basicToast('Successfully uploaded!');
                        },
                        (e) => {
                            // console.log('not saved upload!', e);
                            this.messageService.basicToast('File could not be uploaded!', 'error');
                        }
                    );
                    /*
                this.documentService.saveDocument(document).subscribe(() => {
                    this.messageService.basicToast('Upload successful, please wait for review.');
                }, error => {
                    document.fileUploadDate = null;
                    document.fileName = null;
                });
                */
                }
            },
            (error) => {
                this.messageService.basicToast('Could not upload file', 'error');
            }
        );
    }

    public onEmployeeConfirmation(evt) {
        const src = evt.srcElement;

        this.payslipService.upload(src.files[0]).subscribe(
            (resp) => {
                if (!resp.success) {
                    this.messageService.basicToast('Could not upload file', 'error');
                } else {
                    this.payslipsForm.get('employeeConfirmation').patchValue(resp.file.fileName);

                    if (!this.payslipsForm.valid) {
                        // console.log('payslip form is not valid');
                        return;
                    }
                    // now basically save it
                    const payslipToSave = this.preparePayslipToSave();
                    this.payslipService.savePayslip(payslipToSave).subscribe(
                        (s) => {
                            // console.log('saved upload?', s);
                            this.messageService.basicToast('Successfully uploaded!');
                        },
                        (e) => {
                            // console.log('not saved upload!', e);
                            this.messageService.basicToast('File could not be uploaded!', 'error');
                        }
                    );
                    /*
                this.documentService.saveDocument(document).subscribe(() => {
                    this.messageService.basicToast('Upload successful, please wait for review.');
                }, error => {
                    document.fileUploadDate = null;
                    document.fileName = null;
                });
                */
                }
            },
            (error) => {
                this.messageService.basicToast('Could not upload file', 'error');
            }
        );
    }

    public onBankConfirmation(evt) {
        const src = evt.srcElement;

        this.payslipService.upload(src.files[0]).subscribe(
            (resp) => {
                if (!resp.success) {
                    this.messageService.basicToast('Could not upload file', 'error');
                } else {
                    this.payslipsForm.get('originalBankTransferStatement').patchValue(resp.file.fileName);

                    if (!this.payslipsForm.valid) {
                        // console.log('payslip form is not valid');
                        return;
                    }
                    // now basically save it
                    const payslipToSave = this.preparePayslipToSave();
                    this.payslipService.savePayslip(payslipToSave).subscribe(
                        (s) => {
                            // console.log('saved upload?', s);
                            this.messageService.basicToast('Successfully uploaded!');
                        },
                        (e) => {
                            // console.log('not saved upload!', e);
                            this.messageService.basicToast('File could not be uploaded!', 'error');
                        }
                    );
                    /*
                this.documentService.saveDocument(document).subscribe(() => {
                    this.messageService.basicToast('Upload successful, please wait for review.');
                }, error => {
                    document.fileUploadDate = null;
                    document.fileName = null;
                });
                */
                }
            },
            (error) => {
                this.messageService.basicToast('Could not upload file', 'error');
            }
        );
    }

    public onChangeFileNonGerman(evt) {
        const src = evt.srcElement;

        this.payslipService.upload(src.files[0]).subscribe(
            (resp) => {
                if (!resp.success) {
                    this.messageService.basicToast('Could not upload file', 'error');
                } else {
                    this.payslipsForm.get('nonGermanPayslip').patchValue(resp.file.fileName);
                    this.payslipsForm.get('rejectReason').patchValue(null);
                    this.payslipsForm.get('rejectedDate').patchValue(null);
                    this.payslipsForm.get('reviewedDate').patchValue(null);

                    if (!this.payslipsForm.valid) {
                        // console.log('payslip form is not valid');
                        return;
                    }
                    // now basically save it
                    const payslipToSave = this.preparePayslipToSave();
                    this.payslipService.savePayslip(payslipToSave).subscribe(
                        (s) => {
                            // console.log('saved upload?', s);
                            this.messageService.basicToast('Successfully uploaded!');
                        },
                        (e) => {
                            // console.log('not saved upload!', e);
                            this.messageService.basicToast('File could not be uploaded!', 'error');
                        }
                    );
                    /*
                this.documentService.saveDocument(document).subscribe(() => {
                    this.messageService.basicToast('Upload successful, please wait for review.');
                }, error => {
                    document.fileUploadDate = null;
                    document.fileName = null;
                });
                */
                }
            },
            (error) => {
                this.messageService.basicToast('Could not upload file', 'error');
            }
        );
    }

    private preparePayslipToSave() {
        const formValue = this.payslipsForm.value;
        const payslipToSave = this.currentPayslip;
        for (const k in formValue) {
            if (!formValue.hasOwnProperty(k)) {
                continue;
            }
            payslipToSave[k] = formValue[k];
        }
        return payslipToSave;
    }

    public updateNotify(event, key) {
        // console.log('update notify', event, key);
        // reverse whats clicked
        // this.assigneeForm.get(key).patchValue(!this.assigneeForm.get(key).value);
        if (key == 'notifyAssigneeAboutPayslips') {
            this.payslipService.saveNotifyAssignee(this.assignee.id, this.assigneeForm.get(key).value).subscribe(
                (s) => {
                    // console.log('save notify assignee', s);
                    this.assignee.notifyAssigneeAboutPayslips = this.assigneeForm.get(key).value;
                },
                (e) => {
                    // console.log('could not save notify asignee', e);
                }
            );
        }

        if (key == 'notifyOrgAdminsAboutPayslips') {
            this.payslipService.saveNotifyOrg(this.assignee.id, this.assigneeForm.get(key).value).subscribe(
                (s) => {
                    // console.log('save notify org', s);
                    this.assignee.notifyOrgAdminsAboutPayslips = this.assigneeForm.get(key).value;
                },
                (e) => {
                    // console.log('couldnot save notify org', e);
                }
            );
        }
    }

    public exportSalaryOverview() {
        window.location.href = '/comic/salary-overview?assignee=' + this.assignee.id;
    }

    public onClickOpenModal() {
        this.openMe = true;
        // this.fillAbsencePeriodModal.hideModal();
    }
    public onCloseOpenModal() {
        this.openMe = false;
    }

    public accessPayslips() {
        console.log("Access: ", this.appData.userISSuperAdmin() || this.appData.permissions.ASSIGNEE_PAYSLIP_UPLOAD_DOCUMENTS)
        return this.appData.userISSuperAdmin() || this.appData.permissions.ASSIGNEE_PAYSLIP_UPLOAD_DOCUMENTS;
    }

    private processHowToData(howToName) {
        if (!this.appData.loggedInAssignee||!this.appData.loggedInUser['assignee']) {
            return;
        }
        this.howToData = this.appData.loggedInUser['assignee'].howToData;
        this.howToData = this.howToData
            ? JSON.parse(this.howToData)
            : { home: false, profile: false, documents: false, timerecords: false, payslips: false };

        const assigneeId = this.appData.loggedInUser['assignee'].id;

        if (this.howToData[howToName]) {
            return;
        }
        this.howToData[howToName] = true;
        this.onClickOpenModal();
        // this.saveHowTo = true;

        const buildsave = {
            id: assigneeId,
            howToData: JSON.stringify(this.howToData),
        };

        this.saveHowToData(buildsave);
    }

    private async saveHowToData(howTo: any) {
        await this.assigneeService.saveHowToData(howTo).toPromise();
        await this.userService.getCurrentUserData();
    }

    private getTenant() {
        this.userService.getTenant().subscribe((success) => {
            this.tenantName_ = success;
        });
    }

    async removeFileName(fileName) {
        this.currentPayslip.info["files"][fileName] = null;
        try {
            await this.payslipService.savePayslip(this.currentPayslip).toPromise();
            await this.checkCompliance(this.currentPayslip)
            this.messageService.basicToast('Successfully rejected!');
        } catch (e) {
            this.messageService.basicToast(`File "${fileName}" could not be rejected: ${e.message}`, 'error');
        }
    }

    downloadFileWithNameAndPayslipInfo(fileName, downloadName) {
        const url = `/comic/filedownload?fileName=${fileName}&downloadName=${downloadName}-${this.currentPayslip.year}-${this.currentPayslip.month}`
        window.location.href = url
    }

    async uploadFileAndSavePayslip($event, fileName) {
        if (!this.currentPayslip.info) {
            this.currentPayslip["info"] = {}
            this.currentPayslip["info"]["files"] = {}
        }
        const src = $event.srcElement
        try {
            const resp = await this.payslipService.upload(src.files[0]).toPromise()
            if (!resp.success) {
                this.messageService.basicToast('Could not upload file' , 'error');
            }
            this.currentPayslip.info["files"][fileName] = resp.file.fileName;
            const payslipToSave = this.preparePayslipToSave();
            const res = await this.payslipService.savePayslip(payslipToSave).toPromise();
            await this.checkCompliance(payslipToSave)
            this.messageService.basicToast('Successfully uploaded!');
        } catch (e) {
            this.messageService.basicToast('Could not upload file: ' + e.message, 'error');
        }
    }

    acceptMultiLangPayslip(type) {
        this.currentPayslip.info["type"] = type
    }

    async checkCompliance(ps) {
        const compliant = await this.httpClient.post<any>("/comic/rest/compliance/payslip", ps).toPromise();
        this.payslipIsCompliant = compliant.content.compliant;
    }
}
