import { Meta, Title, MetaDefinition, TransferState, makeStateKey } from '@angular/platform-browser';
import { Injectable, Inject, PLATFORM_ID, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, getNumberOfCurrencyDigits, formatDate, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ModalComponent } from '../components/modal/modal.component';

@Injectable({
    providedIn: 'root'
})
export class MainService {

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private document,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {

    }

    public isBrowser() {
        return isPlatformBrowser(this.platformId);
    }

    public isServer() {
        return isPlatformServer(this.platformId);
    }

    /**
     * Serialize an object to a string like ?key=value&key2=value2
     *
     * @param obj provide an object
     * @param pref prefix for serialization (default = ?) for urls. Will be omitted if serialized string is empty
     * @param percent20ToPlus true if %20 should be replaced with +
     * @param dontEscapeValues an array containing all values that should not be escaped
     */
    public serialize(obj: any, pref = '?', percent20ToPlus = false, dontEscapeValues = []) {
        const str = [];
        for (const p in obj) {
            if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
                if (dontEscapeValues.indexOf(obj[p]) >= 0) {
                    str.push(p + (obj[p] ? '=' + obj[p] : ''));
                } else {
                    if (percent20ToPlus) {
                        str.push(p + (obj[p] ? '=' + encodeURIComponent(obj[p]).replace(/%20/g, '+') : ''));
                    } else {
                        str.push(p + (obj[p] ? '=' + encodeURIComponent(obj[p]) : ''));
                    }
                }
            }
        }
        const ret = str.join('&');
        return ret ? pref + ret : '';
    }

    public appendComponentToBody<T>(component: any) {
        // 1. Create a component reference from the component 
        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory<T>(component)
            .create(this.injector);

        // 2. Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);

        // 3. Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        // 4. Append DOM element to the body
        document.body.appendChild(domElem);

        // destroy
        // this.appRef.detachView(componentRef.hostView);
        // componentRef.destroy();
        return componentRef;
    }

    public prompt(
        msg: string,
        callback: (val, action: 'ok' | 'no' | 'yes' | 'cancel') => void,
        type: any = 'prompt',
        maxLength = 256,
        multiline = false
    ) {

        const modal = this.appendComponentToBody<ModalComponent>(ModalComponent);
        const modalIns = modal.instance;
        modalIns.content = msg;
        modalIns.type = type;
        modalIns.maxlength = maxLength;
        modalIns.multiline = multiline;

        const destroy = () => {
            this.appRef.detachView(modal.hostView);
            modal.destroy();
        };

        if (type === 'prompt') {

            modalIns.buttons = {
                cancel: {
                    text: 'Cancel',
                    callback: (val) => {
                        destroy();
                        callback(val, 'cancel');
                    }
                },
                ok: {
                    text: 'OK',
                    callback: (val) => {
                        destroy();
                        callback(val, 'ok');
                    },
                    primary: true
                }
            };

        } else if (type === 'confirm') {

            modalIns.buttons = {

                ok: {
                    text: 'Yes',
                    callback: (val) => {
                        destroy();
                        callback(val, 'yes');
                    },
                    primary: true
                },
                cancel: {
                    text: 'No',
                    callback: (val) => {
                        destroy();
                        callback(val, 'no');
                    }
                }
            };
            modalIns.allowOutsideClickClose = false;

        } else {
            modalIns.buttons = {
                cancel: {
                    text: 'OK',
                    callback: (val) => {
                        destroy();
                        callback(val, 'no');
                    },
                    primary: true
                }
            };
        }

        modalIns.show();
    }

    public clone(item) {
        if (!item) { return item; } // null, undefined values check

        const types = [Number, String, Boolean];
        let result;

        // normalizing primitives if someone did new String('aaa'), or new Number('444');
        types.forEach((type) => {
            if (item instanceof type) {
                result = type(item);
            }
        });

        if (typeof result === 'undefined') {
            if (Object.prototype.toString.call(item) === '[object Array]') {
                result = [];
                item.forEach((child, index, array) => {
                    result[index] = this.clone(child);
                });
            } else if (typeof item === 'object') {
                // testing that this is DOM
                if (item.nodeType && typeof item.cloneNode === 'function') {
                    result = item.cloneNode(true);
                } else if (!item.prototype) { // check that this is a literal
                    if (item instanceof Date) {
                        result = new Date(item);
                    } else {
                        // it is an object literal
                        result = {};

                        // tslint:disable-next-line: forin
                        for (const i in item) {
                            result[i] = this.clone(item[i]);
                        }
                    }
                } else {
                    throw Error('cannot clone non object literals');
                }
            } else {
                result = item;
            }
        }

        return result;
    }

    public leadingZero(num: number): string {
        return (num < 10 ? '0' : '') + num;
    }

    public dateToString(date: Date) {
        return [
            date.getFullYear(),
            this.leadingZero(date.getMonth() + 1),
            this.leadingZero(date.getDate())
        ].join('-') + 'T' + [
            this.leadingZero(date.getHours()),
            this.leadingZero(date.getMinutes()),
            this.leadingZero(date.getSeconds())
        ].join(':');
    }

    /**
     * Calculates the the amount of months between 2 given Date objects
     * @param d1 start date
     * @param d2 end date
     * @returns amount of months between the 2 given dates
     */
    public getDateDifferencesInMonths(d1: Date, d2: Date, includeFirstAndLast = true): number {
        
        let months: number;

        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth() + 1;
        months += d2.getMonth();

        if (includeFirstAndLast) {
            months += 2;
        }

        return months <= 0 ? 0 : months;
    }
}
