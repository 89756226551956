import { HrAgent } from './hr-agent';
import { SendingEntity } from './sending-entity';
import { User } from 'src/app/models/user';
import { ReceivingEntity } from './receiving-entity';
import { Project } from './project';
import { ProjectAdmin } from './project-admin';
import { Payslip } from './payslip';
import { Document } from './document';

export class Assignee {
    public id: number;
    public mongoKey: string;
    public user: User;
    public sendingEntity: SendingEntity;
    public receivingEntity: ReceivingEntity;
    public projects: Project[];
    public lastConfirmedUser: User;
    public documents: Document[];
    public hrAgents: HrAgent[];
    public projectAdmins: ProjectAdmin[];
    public payslips: Payslip[];
    public startDate: Date;
    public endDate: Date;
    public workPermitFrom: Date;
    public workPermitTo: Date;
    public visaRpFrom: Date;
    public visaRpTo: Date;
    public salaryPaymentDeadline: number;
    public firstname: string;
    public lastname: string;
    public email: string;
    public salary: number;
    public workingTimeHomeCountry: number;
    public workingTimeAustria: number;
    public holidayEntitlementHomeCountry: number;
    public holidayEntitlementAustria: number;
    public dailyGrossUp: number;
    public country: string;
    public city: string;
    public street: string;
    public zipCode: string;
    public billedDate: Date;
    public birthday: Date;
    public lastConfirmation: Date;
    public gender: string;
    public citizenship: string;
    public rejectionMessage: string;
    public metaData: string;
    public metaDataParsed: any;
    public activeMonitoring: boolean = false;
    public notifyAssigneeAboutTimerecords: boolean = true;
    public notifyAssigneeAboutPayslips: boolean = true;
    public notifyOrgAdminsAboutTimerecords: boolean = true;
    public notifyOrgAdminsAboutPayslips: boolean = true;
    public howToData: string;
    public internalNote: string;
    public billingCode: string;
    public accessCardNumber: string;
    public alienActOnly: boolean = false;
    public personnelNumber: string;
    public canceled: boolean = false;
    public exempted: boolean = false;
    public showPromoComicGoPopup: boolean = true;
    public status: string;
    public profileStatus: boolean;
    public documentsStatus: boolean = false;
    public timerecordsStatus: boolean = false;
    public payslipsStatus: boolean = false;
    public numberOfWorkedDays: number = 0;
    public overtimeWeekCount: number = 0;
    public changesRequested: boolean;
    //public isBilled: boolean = false;

    public userInfo: any;

    public constructor(data?) {
        if (data) {
            Object.assign(this, data);

            this.metaDataParsed = data.metaData ? JSON.parse(data.metaData) : {};
            this.calculateChangesRequested();
        }
        this.userInfo =
            data && data.userInfo
                ? data.userInfo
                : { profileCategory: 'NONE', history: [], reportingModeInfo: { employeeType: '', ssnr: '', dependents: [], reportingMode: '', hiringAuthBodyLeasingCase: false } };
    }

    public isType123456() {
        return this.isType12345() || this.reportingModeEq('Category 6');
    }

    public isType12345() {
        return this.isType1234() || this.isType345();
    }

    public isType34567() {
        return this.isType345() || this.reportingModeEq('Category 6') || this.reportingModeEq('Category 7');
    }

    public isType345() {
        return (
            this.reportingModeEq('Category 3') ||
            this.reportingModeEq('Category 4') ||
            this.reportingModeEq('Category 5')
        );
    }

    public isType1234() {
        return (
            this.reportingModeEq('Category 1') ||
            this.reportingModeEq('Category 2') ||
            this.reportingModeEq('Category 3') ||
            this.reportingModeEq('Category 4')
        );
    }

    public isType34() {
        return this.reportingModeEq('Category 3') || this.reportingModeEq('Category 4');
    }

    public isType2And4() {
        return this.reportingModeEq('Category 2') || this.reportingModeEq('Category 4');
    }

    public isType5() {
        return this.reportingModeEq('Category 5');
    }

    public isType2() {
        return this.reportingModeEq('Category 2');
    }

    public isType67() {
        return this.reportingModeEq('Category 6')||this.reportingModeEq('Category 7')
    }

    public isType7() {
        return this.reportingModeEq('Category 7')
    }

    public isType6() {
        return this.reportingModeEq('Category 6')
    }

    public isType4() {
        return this.reportingModeEq('Category 4')
    }

    public isType3() {
        return this.reportingModeEq('Category 3')
    }

    public isType1() {
        return this.reportingModeEq('Category 1')
    }

    public isHiringAuthBodyLeasingCase() {
        let info = this.userInfo.reportingModeInfo;
        if (!info) {
            return false
        }
        return info.hiringAuthBodyLeasingCase
    }

    public getReportingCategory() {
        if (!this.userInfo||!this.userInfo.reportingModeInfo) {
            return "";
        }
        return this.userInfo.reportingModeInfo.reportingMode;
    }

    public reportingModeEq(str) {
        let mode = this.userInfo.reportingModeInfo.reportingMode;
        if (!mode) {
            return false
        }
        return mode.includes(str) || mode===str;
    }

    public getDependentString() {
        if (!this.userInfo || !this.userInfo.reportingModeInfo || !this.userInfo.reportingModeInfo.dependents) {
            return "";
        }
        const dependents = this.userInfo.reportingModeInfo.dependents
        return dependents.map(d => `${d.firstname} ${d.lastname}`).join("<br>")
    }

    public getReviewer() {
        if (!this.userInfo || !this.userInfo.reportingModeInfo) {
            return "";
        }
        return this.userInfo.reportingModeInfo.validatorName
    } 

    private calculateChangesRequested() {
        const metaDataParsed = this.metaDataParsed;

        for (const property in metaDataParsed) {
            if (metaDataParsed.hasOwnProperty(property)) {
                this.changesRequested = true;
                return;
            }
        }

        this.changesRequested = false;
    }
}
