import { AbsencePeriod } from './../../../models/absence-period';
import { AssigneeService } from './../../../routes/assignee/assignee.service';
import { Guid } from 'guid-typescript';
import { TimeRecordService } from 'src/app/services/time-record.service';
import { DatePipe } from '@angular/common';
import { Assignee } from './../../../models/assignee';
import { TimeRecord } from './../../../models/time-record';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

/**
 * Holds data and business logic for time-records.component
 */
@Injectable({
    providedIn: 'root',
})
export class TimeRecordsService {
    public assignee: Assignee;

    /**
     * A list of (empty timerecords) for each day in a selected month
     */
    public timeRecordsMonth: TimeRecord[];
    public monthSelection: any[];
    public restmonthSelection: any[];
    public weekSummary;

    /**
     * Overtime in ms for this month
     */
    public sumOvertimeMillis: number;
    public sumWorkedMillis: number;
    public firstDate: Date;
    public lastDate: Date;
    public holidays: Date[];
    public absencePeriods: AbsencePeriod[];
    public currentSelectionMonth: Date;
    public resetcurrentSelectionMonth: Date;

    public sumOvertimeCompliant: boolean;
    public holidaysOverall: any;

    constructor(
        private datePipe: DatePipe,
        private timeRecordService: TimeRecordService,
        private assigneeService: AssigneeService
    ) {}

    public setAssignee(assignee: Assignee) {
        // this.monthSelection = this.restmonthSelection;
        this.monthSelection = [];

        this.assignee = assignee;
        this.initMonthSelection();

        const notifyOrgAdminsAboutTimerecords = this.assignee.notifyOrgAdminsAboutTimerecords;
        const notifyAssigneeAboutTimerecords = this.assignee.notifyAssigneeAboutTimerecords;

        this.assigneeService
            .saveNotifyOrgAdminsAboutTimerecords(this.assignee.id, notifyOrgAdminsAboutTimerecords)
            .subscribe();
        this.assigneeService
            .saveNotifyAssigneeAboutTimerecords(this.assignee.id, notifyAssigneeAboutTimerecords)
            .subscribe();

        this.currentSelectionMonth = this.resetcurrentSelectionMonth;
        if (!this.currentSelectionMonth) {
            const dtTime = new Date();
            dtTime.setHours(0, 0, 0, 0);

            if (
                dtTime.getTime() >= this.assignee.startDate.getTime() &&
                dtTime.getTime() <= this.assignee.endDate.getTime()
            ) {
                this.currentSelectionMonth = this.monthSelection.find((date) => {
                    const dt = date.date;
                    // const dt_r = new Date(dt.getFullYear(), dt.getMonth(), 1);
                    // const dtTime_r = new Date(dtTime.getFullYear(), dtTime.getMonth(), 1);

                    return dt.getFullYear() === dtTime.getFullYear() && dt.getMonth() === dtTime.getMonth();
                });
            } else {
                this.currentSelectionMonth = this.monthSelection[0];
            }
        }

        this.timeRecordService.getAbsenceTimerecords(this.assignee.id).subscribe((absencePeriods) => {
            this.absencePeriods = absencePeriods;
        });
    }

    private initMonthSelection() {
        const today = new Date();

        const start = this.assignee.startDate;
        let end = this.assignee.endDate;

        if (end > today) {
            end = today;
        }

        const current = new Date();

        current.setFullYear(start.getFullYear(), start.getMonth(), 1);
        // current.setFullYear(start.getFullYear());
        // current.setMonth(start.getMonth());
        // current.setDate(1);
        current.setHours(0, 0, 0, 0);

        while (current.getTime() <= end.getTime()) {
            const toAdd = new Date();

            toAdd.setFullYear(current.getFullYear(), current.getMonth(), 1);
            // toAdd.setFullYear(current.getFullYear());
            // toAdd.setMonth(current.getMonth());
            // toAdd.setDate(1);
            toAdd.setHours(0, 0, 0, 0);

            this.monthSelection.push({
                date: toAdd,
                label: toAdd.getFullYear() + ' - ' + this.datePipe.transform(current, 'MMM'),
            });

            current.setMonth(current.getMonth() + 1);
        }
    }

    /**
     * Converts a time-string (eg. 10:00) to a date object (today)
     * @param time: eg. 10:00
     */
    private timeToDate(time: string) {
        const date = new Date();
        const hoursMinutes = time.split(':');
        date.setHours(parseInt(hoursMinutes[0], 10), parseInt(hoursMinutes[1], 10), 0, 0);
        return date;
    }

    /**
     * Compares 2 date objects, ignoring the hours
     */
    private isEqualDate(d1: Date, d2: Date) {
        return (
            d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
        );
    }

    /**
     *
     * @param year The year to generate time records for
     * @param month The month (0 = jan, 1 = feb) to generate time records for
     */
    public initTimeRecordsMonth(year: number, month: number) {
        const today = new Date();
        const selectionStart = new Date(year, month, 1, 0, 0, 0, 0);
        const selectionEnd = new Date(year, month + 1, 0, 0, 0, 0, 0);

        let start = new Date(selectionStart.getTime());
        let end = new Date(selectionEnd.getTime());

        if (end > today) {
            // today.setDate(today.getDate() + 1);

            end = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        }

        if (
            this.assignee.endDate.getFullYear() === end.getFullYear() &&
            this.assignee.endDate.getMonth() === end.getMonth()
        ) {
            if (this.assignee.endDate.getTime() < today.getTime()) {
                end = new Date(
                    this.assignee.endDate.getFullYear(),
                    this.assignee.endDate.getMonth(),
                    this.assignee.endDate.getDate()
                );
            }
        }

        if (start < this.assignee.startDate) {
            start = new Date(this.assignee.startDate.getTime());
        }

        const numberOfDays = end.getDate();
        this.timeRecordsMonth = [];

        this.firstDate = start;
        this.lastDate = end;

        this.timeRecordService
            .getTimeRecordsByAssignee(this.assignee.id, selectionStart, selectionEnd)
            .subscribe((trs) => {
                for (let date = start.getDate(); date < numberOfDays + 1; date++) {
                    const day = new Date();

                    day.setFullYear(start.getFullYear(), start.getMonth(), date);
                    // day.setFullYear(start.getFullYear());
                    // day.setMonth(start.getMonth());
                    // day.setDate(date);
                    day.setHours(0, 0, 0, 0);

                    const existingTr = trs.find((tmpTr) => {
                        return this.isEqualDate(tmpTr.day, day);
                    });

                    if (existingTr) {
                        if (existingTr.beginTime) {
                            existingTr.beginTimeDt = this.timeToDate(existingTr.beginTime);
                        }
                        if (existingTr.endTime) {
                            existingTr.endTimeDt = this.timeToDate(existingTr.endTime);
                        }
                        existingTr.week = this.getMonthWeek(existingTr.day);
                        existingTr.internalId = 'i-' + Guid.create() + '';
                        this.timeRecordsMonth.push(existingTr);
                    } else {
                        const tr = new TimeRecord();
                        tr.assignee = { id: this.assignee.id } as Assignee;
                        tr.day = day;
                        tr.week = this.getMonthWeek(tr.day);
                        tr.internalId = 'i-' + Guid.create() + '';
                        this.timeRecordsMonth.push(tr);
                    }
                }

                this.timeRecordsMonth.reverse();
                this.getHolidayDaysBetweenDates(selectionStart, selectionEnd).subscribe(
                    (days) => {
                        this.holidaysOverall = days;
                        this.calcWeekSummary();
                    },
                    (error) => {
                        // tmp
                        this.holidays = [];
                        this.calcWeekSummary();
                    }
                );
            });
    }

    public getHolidayDaysBetweenDates(start, end): Observable<Date[]> {
        this.holidays = [];
        const ret = this.timeRecordService.getHolidayDaysBetweenDates(start, end).pipe(share());

        ret.subscribe((holidays) => {
            this.holidays = holidays;
        });

        return ret;
    }

    private getMonthWeek(date: Date) {
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay() - 1;

        if (firstDay === -1) {
            firstDay = 6;
        }

        return Math.ceil((date.getDate() + firstDay) / 7);
    }

    private isHolidayDay(date: Date): boolean {
        for (const holiday of this.holidays) {
            if (this.isEqualDate(date, holiday)) {
                return true;
            }
        }
        return false;
    }

    private getWorkingDays(startDate: Date, lastDay: Date) {
        let workingDays = 0;
        let nextDay = startDate.getDate();

        while (startDate <= lastDay) {
            const date = new Date(startDate.getFullYear(), startDate.getMonth(), nextDay);

            // do calculate saturday or sunday as working days
            if (date.getDay() !== 6 && date.getDay() !== 0) {
                const isHoliday = this.isHolidayDay(date);

                if (!isHoliday) {
                    workingDays++;
                }
            }
            nextDay++;
        }

        return workingDays;
    }

    /**
     * Updates this.weekSummary
     */
    public calcWeekSummary() {
        this.weekSummary = {};
        const trs = this.timeRecordsMonth;
        const trCount = trs.length;

        for (let i = 0; i < trCount; i++) {
            if (!this.weekSummary[trs[i].week]) {
                this.weekSummary[trs[i].week] = {
                    sumMillis: 0,
                    sumOvertimeMillis: 0,
                    overtimeCompliant: false,
                };
            }

            const currentWeek = this.weekSummary[trs[i].week];
            currentWeek.sumMillis += trs[i].workedTimeInMillis || 0;
        }

        const workingTimeAustria = this.assignee.workingTimeAustria;
        const workingTimeAustriaPerDay = (workingTimeAustria / 5) * 60 * 60 * 1000;
        const overtimesPerWeek = {};

        for (let i = 0; i < trCount; i++) {
            const tr = trs[i];
            const curDay = trs[i].day;
            const curWeek = trs[i].week;

            if (curDay.getDay() !== 0 && curDay.getDay() !== 6 && !this.isHolidayDay(curDay)) {
                const sum = overtimesPerWeek[curWeek] || 0;
                overtimesPerWeek[curWeek] = sum + ((tr.workedTimeInMillis || 0) - workingTimeAustriaPerDay);
            } else {
                const sum = overtimesPerWeek[curWeek] || 0;
                overtimesPerWeek[curWeek] = sum + (tr.workedTimeInMillis || 0);
            }
        }

        for (let i = 0; i < trCount; i++) {
            const tr = trs[i];
            const curDay = trs[i].day;
            const curWeek = trs[i].week;

            if (tr.overtimeCompliant) {
                this.weekSummary[curWeek].overtimeCompliant = true;
            }
        }

        this.sumWorkedMillis = 0;
        this.sumOvertimeMillis = 0;

        for (const week in this.weekSummary) {
            if (this.weekSummary.hasOwnProperty(week)) {
                this.sumWorkedMillis += this.weekSummary[week].sumMillis;
            }
        }

        for (const week in overtimesPerWeek) {
            if (overtimesPerWeek.hasOwnProperty(week)) {
                this.weekSummary[week].sumOvertimeMillis = overtimesPerWeek[week];

                if (overtimesPerWeek[week] > 0) {
                    this.sumOvertimeMillis += overtimesPerWeek[week];
                }
            }
        }

        // Detect Austrian Holidays
        for (let i = 0; i < trCount; i++) {
            trs[i]['austriaHoliday'] = false;
        }
        for (var el in this.holidaysOverall) {
            if (this.holidaysOverall.hasOwnProperty(el)) {
                for (let i = 0; i < trCount; i++) {
                    const element = trs[i];
                    // console.log(element.day.getTime(), this.holidaysOverall[el].getTime());
                    if (element.day.getTime() === this.holidaysOverall[el].getTime()) {
                        trs[i]['austriaHoliday'] = true;
                    }
                }
            }
        }
        this.AllOverTimeComplaint();
    }

    // Detect if all Overtimes were confirmed.
    public AllOverTimeComplaint() {
        const trsarray = [];
        for (var element in this.weekSummary) {
            if (this.weekSummary.hasOwnProperty(element)) {
                if (this.weekSummary[element].sumOvertimeMillis > 0) {
                    trsarray.push(this.weekSummary[element]);
                }
            }
        }
        if (trsarray) {
            let isFalse = this.detectOvertimeComplaint(trsarray);
            if (isFalse === true) {
                this.sumOvertimeCompliant = true;
            } else {
                this.sumOvertimeCompliant = false;
            }
        }
    }

    private detectOvertimeComplaint(trsarray) {
        let falseone = false;
        let isFalse = trsarray.find((week) => !week['overtimeCompliant']);
        if (isFalse) {
            return false;
        } else {
            return true;
        }
    }
}
