import { Pipe, PipeTransform } from '@angular/core';
import { Document } from '../models/document';

@Pipe({
    name: 'documentDownloadUrl'
})
export class DocumentDownloadUrlPipe implements PipeTransform {

    transform(document: Document): any {
        const base = '/comic/filedownload?content-disposition=inline&fileName=';
        const dlName = document.documentType ? document.documentType.name : document.customName;
        return base + document.fileName + '&downloadName=' + dlName;
    }

}
