import { AbsencePeriod } from './../models/absence-period';
import { MainService } from './main.service';
import { TimeRecord } from './../models/time-record';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, NgZone, Inject } from '@angular/core';
import { MessageService } from './messageService';
import { Observable } from 'rxjs';
import { Assignee } from '../models/assignee';

@Injectable({
    providedIn: 'root'
})
export class TimeRecordService extends BaseHttpService {

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService,
        private mainService: MainService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    public getDaysOverdue(assigneeId: number): Observable<number> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/timerecord/daysOverdue/' + assigneeId;
        const options = this.getOptions();

        return this.httpClient.get<number>(url, options);
    }

    public getTimeRecordsByAssignee(assigneeId: number, begin: Date, end: Date) {
        const url = this.getApiPath() + '/timerecord/timerecordsByAssignee' +
            '?assigneeId=' + assigneeId + '&begin=' + this.mainService.dateToString(begin) + '&end=' + this.mainService.dateToString(end);

        return this.httpClient.get<TimeRecord[]>(url);
    }

    public saveTimeRecord(timeRecord: TimeRecord): Observable<TimeRecord> {
        const url = this.getApiPath() + '/timerecord';
        return this.httpClient.post<TimeRecord>(url, timeRecord);
    }

    public deleteTimeRecord(timeRecordId: number): Observable<any> {
        const url = this.getApiPath() + '/timerecord/' + timeRecordId;
        return this.httpClient.delete(url);
    }

    public getHolidayDaysBetweenDates(from: Date, to: Date) {
        const fromString = this.mainService.dateToString(from);
        const toString = this.mainService.dateToString(to);

        const url = this.getApiPath() + '/timerecord/holidayDaysBetweenDates?start=' + fromString + '&end=' + toString;
        return this.httpClient.get<Date[]>(url);
    }

    public getAbsenceTimerecords(assigneeId: number) {
        const url = this.getApiPath() + '/timerecord/absenceTimerecords/' + assigneeId;
        return this.httpClient.get<AbsencePeriod[]>(url);
    }

    public fillAbsenceTimeRecords(assigneeId: number, startDate: Date, endDate: Date, absenceType: 'leave' | 'exempt' | 'sick') {
        const url = this.getApiPath() + '/timerecord/fillAbsenceTimerecords';

        return this.httpClient.post<any>(url, {
            assignee: {
                id: assigneeId
            },
            startDate,
            endDate,
            absenceType
        });
    }

    public deleteAbsenceTimeRecords(assigneeId: number, startDate: Date, endDate: Date, absenceType: 'leave' | 'exempt' | 'sick') {
        const url = this.getApiPath() + '/timerecord/absenceTimerecords';

        return this.httpClient.post<any>(url, {
            assignee: {
                id: assigneeId
            },
            startDate,
            endDate,
            absenceType
        });
    }

    public fillTimeRecords(assigneeId: number, beginTime: Date, endTime: Date, pause: number) {
        const url = this.getApiPath() + '/timerecord/fillTimerecords';

        return this.httpClient.post<any>(url, {
            assignee: {
                id: assigneeId
            },
            beginTime: this.mainService.leadingZero(beginTime.getHours()) + ':' + this.mainService.leadingZero(beginTime.getMinutes()),
            endTime: this.mainService.leadingZero(endTime.getHours()) + ':' + this.mainService.leadingZero(endTime.getMinutes()),
            pause
        });
    }

}
