import { Document } from './../../../models/document';
import { GeneralDocumentsService } from './general-documents.service';
import { DocumentTypeMap } from 'typings';
import { MessageService } from './../../../services/messageService';
import { MainService } from 'src/app/services/main.service';
import { AppData } from './../../../services/app-data.service';
import { AssigneeService } from './../../../routes/assignee/assignee.service';
import { ProjectService } from './../../../routes/project/project.service';
import { Project } from 'src/app/models/project';
import { orderBy } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Assignee } from 'src/app/models/assignee';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SendingEntity } from 'src/app/models/sending-entity';
import { SendingEntityService } from 'src/app/routes/sending-entity/sending-entity.service';
import { ReceivingEntity } from 'src/app/models/receiving-entity';
import { ReceivingEntityService } from 'src/app/routes/receiving-entity/receiving-entity.service';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { DocumentService } from 'src/app/services/document.service';
import { DocumentType } from 'src/app/models/document-type';
import { DocumentTypeElement } from 'src/app/models/document-type-element';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-assignee-general-documents',
    templateUrl: './general-documents.component.html',
    styleUrls: ['./general-documents.component.scss'],
})
export class GeneralDocumentsComponent implements OnInit, OnChanges {
    @Input()
    public assignee: Assignee;

    public documentTypes$: Observable<DocumentType[]>;
    public documentTypeMap$: Observable<DocumentTypeMap>;
    public documentTypeMap: DocumentTypeMap;
    public documentTemplateNames = [];
    public uploadRestrictions = {};
    public permissions = this.appData.permissions;
    public useGerman: string;
    public openMe = false;
    public faInfoCircle = faInfoCircle;
    public howToData;
    public tenantName_;

    /*
     * how to use text
     */
    public howToUseText;

    // public tenantNamepreset_ = 'comic';
    // public tenantNamepreset_ = 'accenture';

    constructor(
        private formBuilder: FormBuilder,
        private sendingEntityService: SendingEntityService,
        private receivingEntityService: ReceivingEntityService,
        private projectService: ProjectService,
        private assigneeService: AssigneeService,
        public appData: AppData,
        private mainService: MainService,
        private messageService: MessageService,
        private documentService: DocumentService,
        public gdData: GeneralDocumentsService,
        private userService: UserService
    ) {
        // see getDocuments()
        /*this.gdData.documentTypeMap$.subscribe(documentTypeMap => {
            this.documentTypeMap = documentTypeMap;
            this.documentTemplateNames = Object.keys(documentTypeMap);
        });*/
        this.getDocuments();
        this.processHowToData('documents');
        this.getTenant();
        this.howToUseText = this.appData.getHowToData('documents');
    }

    ngOnInit() {}

    ngOnChanges() {
        if (this.assignee) {
            const storedAssignee = this.gdData.assignee;
            const currentAssignee = this.assignee;

            if (currentAssignee) {
                if (storedAssignee) {
                    if (currentAssignee.id !== storedAssignee.id) {
                        this.gdData.assignee = currentAssignee;
                    }
                } else {
                    this.gdData.assignee = currentAssignee;
                }
            }

            this.gdData.setAssignee(this.assignee);
            this.getTenant();
        }
    }

    private getDocuments() {
        this.gdData.documentTypeMap$.subscribe((documentTypeMap) => {
            this.documentTypeMap = documentTypeMap;
            this.documentTemplateNames = Object.keys(documentTypeMap);
        });
    }
    private getTenant() {
        this.userService.getTenant().subscribe((success) => {
            this.tenantName_ = success;
        });
    }
    /**
     * Activate/add all documents with a matching document.documentType.name = templateName
     * Documents that are already activated do not need to be deactivated/deleted
     *
     * @param templateName the selected template name as a string eg. "Alien act only"
     */
    public async onTemplateChange(templateName) {
        this.appData.showLoading = true
        this.assignee.userInfo.documents.modified = false;
        this.assignee.userInfo.documents.chosenTemplate = templateName;
        await this.assigneeService.saveAssignee(this.assignee).toPromise();
        const documentTypes = this.documentTypeMap[templateName];
        console.log("Change template: " + templateName)
        this.onDisableTemplates().then((response) => {
            const checkAndUpdate = (currentIdx: number) => {
                if (currentIdx >= documentTypes.length) {
                    this.messageService.basicToast('Activated required documents');
                    this.getDocuments();
                    this.gdData.setAssignee(this.assignee);
                    return;
                }

                const documentType = documentTypes[currentIdx];
                const current = this.gdData.documentTypeElements.find(
                    (documentTypeElement) => documentTypeElement.id === documentType.id
                );

                if (!current.isEnabled) {
                    current.isEnabled = true;
                    this.enableDocument(current).subscribe((doc) => {
                        current.document = doc;

                        checkAndUpdate(currentIdx + 1);
                    });
                } else {
                    checkAndUpdate(currentIdx + 1);
                }
            };

            checkAndUpdate(0);
            this.getDocuments();
            this.gdData.setAssignee(this.assignee);
            this.appData.showLoading = false
        });
    }

    public async onDisableTemplates() {
        const documentTypes = this.gdData.documentTypeElements.filter((dt) => dt.isEnabled);
        for (const docTypeElement of documentTypes) {
            if (docTypeElement.isEnabled === true) {
                if (!docTypeElement.document.fileUploadDate) {
                    try {
                        await this.documentService.deleteDocument(docTypeElement.document.id).toPromise();
                        docTypeElement.isEnabled = false;
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    }

    public onChangeRequiredDocument(dte: DocumentTypeElement) {
        if (dte.isEnabled) {
            this.enableDocument(dte).subscribe(
                (doc) => {
                    dte.document = doc;
                    this.messageService.basicToast('Activated field');
                },
                (error) => {
                    dte.isEnabled = false;
                }
            );
        } else {
            const msg = 'Are you sure you want to deactivate this field and delete the attached document?';
            this.mainService.prompt(
                msg,
                (val, action) => {
                    if (action === 'yes') {
                        this.documentService.deleteDocument(dte.document.id).subscribe(
                            () => {},
                            (error) => {
                                dte.isEnabled = true;
                            }
                        );
                    } else {
                        dte.isEnabled = true;
                    }
                },
                'confirm'
            );
        }
    }

    private enableDocument(dte: DocumentTypeElement): Observable<Document> {
        dte.document = new Document();

        dte.document.documentType = {
            id: dte.id,
        } as DocumentType;

        dte.document.assignee = {
            id: this.assignee.id,
        } as Assignee;

        return this.documentService.saveDocument(dte.document).pipe(share());
    }

    public emptyUpload(dte: DocumentTypeElement) {
        const msg = 'Are you sure you want to remove the uploaded document?';

        this.mainService.prompt(
            msg,
            (val, action) => {
                if (action === 'yes') {
                    const document = dte.document;
                    document.fileName = '';
                    document.fileUploadDate = null;
                    document.confirmedDate = null;
                    document.declinedDate = null;
                    document.declinedText = '';

                    this.documentService.saveDocument(document).subscribe();
                }
            },
            'confirm'
        );
    }

    public onChangeFile(evt, document: Document) {
        const src = evt.srcElement;

        this.documentService.upload(src.files[0]).subscribe(
            (resp) => {
                if (!resp.success) {
                    this.messageService.basicToast('Could not upload file', 'error');
                } else {
                    document.declinedDate = null;
                    document.confirmedDate = null;
                    document.declinedText = '';
                    document.fileUploadDate = new Date();
                    document.fileName = resp.file.fileName;

                    this.documentService.saveDocument(document).subscribe(
                        () => {
                            this.messageService.basicToast('Upload successful, please wait for review.');
                        },
                        (error) => {
                            document.fileUploadDate = null;
                            document.fileName = null;
                        }
                    );
                }
            },
            (error) => {
                this.messageService.basicToast('Could not upload file', 'error');
            }
        );
    }

    public onRejectDocument(doc: Document) {
        this.mainService.prompt('Please enter an explanation', (value, action) => {
            if (action === 'ok') {
                doc.confirmedDate = null;
                doc.fileName = null;
                doc.declinedDate = new Date();
                doc.declinedText = value;
                this.documentService.saveDocument(doc).subscribe(() => {
                    this.messageService.basicToast('Document has been rejected.');
                });
            }
        });
    }

    public onAcceptDocument(doc: Document) {
        this.mainService.prompt(
            'Are you sure?',
            (value, action) => {
                if (action === 'yes') {
                    doc.confirmedDate = new Date();
                    doc.declinedDate = null;
                    doc.declinedText = '';
                    this.documentService.saveDocument(doc).subscribe(
                        () => {
                            this.messageService.basicToast('Document has been accepted.');
                        },
                        (error) => {
                            doc.confirmedDate = null;
                        }
                    );
                }
            },
            'confirm'
        );
    }

    public onAddField() {
        this.mainService.prompt('Please enter a label for this document', (value, action) => {
            if (action === 'ok') {
                this.setTemplateModified();
                const document = new Document();
                document.customName = value;
                document.assignee = { id: this.assignee.id } as Assignee;
                this.documentService.saveDocument(document).subscribe((savedDocument) => {
                    this.gdData.documentTypeElements.push({
                        isEnabled: true,
                        document: savedDocument,
                    } as DocumentTypeElement);
                });
                this.getDocuments();
                this.gdData.setAssignee(this.assignee);
            }
        });
    }

    public changeName(doc: Document) {
        this.mainService.prompt('Please enter a name', (value, action) => {
            if (action === 'ok') {
                doc.customName = value;
                this.documentService.saveDocument(doc).subscribe(() => {
                    this.messageService.basicToast('Name has been changed');
                });
            }
        });
    }

    public onChangeNotifyOrgAdmin(evt: boolean, doc: Document) {
        doc.notifyOrganisationAdmins = evt;
        this.documentService.saveDocument(doc).subscribe();
    }

    public onChangeNotifyAssignee(evt: boolean, doc: Document) {
        doc.notifyAssignee = evt;
        this.documentService.saveDocument(doc).subscribe();
    }

    public onEnableDocument(evt: boolean, docTypeElement: DocumentTypeElement) {
        this.setTemplateModified();
        if (evt) {
            this.enableDocument(docTypeElement).subscribe((success) => {
                docTypeElement.isEnabled = true;
                docTypeElement.document.id = success.id;
                docTypeElement.document.notifyAssignee = success.documentType.notifyAssignee;
                docTypeElement.document.notifyOrganisationAdmins = success.documentType.notifyOrganisationAdmins;
                this.getDocuments();
                this.gdData.setAssignee(this.assignee);
            });
        } else {
            this.documentService.deleteDocument(docTypeElement.document.id).subscribe((success) => {
                docTypeElement.isEnabled = false;
                this.getDocuments();
                this.gdData.setAssignee(this.assignee);
            });
        }
    }

    public onClickOpenModal() {
        this.openMe = true;
        // this.fillAbsencePeriodModal.hideModal();
    }
    public onCloseOpenModal() {
        this.openMe = false;
    }
    private processHowToData(howToName) {
        if (!this.appData.loggedInAssignee||!this.appData.loggedInUser['assignee']) {
            return;
        }
        this.howToData = this.appData.loggedInUser['assignee'].howToData;
        this.howToData = this.howToData
            ? JSON.parse(this.howToData)
            : { home: false, profile: false, documents: false, timerecords: false, payslips: false };

        const assigneeId = this.appData.loggedInUser['assignee'].id;

        if (this.howToData[howToName]) {
            return;
        }
        this.howToData[howToName] = true;
        this.onClickOpenModal();
        // this.saveHowTo = true;

        const buildsave = {
            id: assigneeId,
            howToData: JSON.stringify(this.howToData),
        };

        this.saveHowToData(buildsave);
    }

    private async setTemplateModified() {
        this.assignee.userInfo.documents.modified = true;
        return await this.assigneeService.saveAssignee(this.assignee).toPromise()
    }

    private async saveHowToData(howTo: any) {
        await this.assigneeService.saveHowToData(howTo).toPromise();
        await this.userService.getCurrentUserData();
    }
}
