import { SendingEntity } from './../../models/sending-entity';
import { DefaultListQueryParams } from './../../models/query';
import { catchError, map, delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from './../../services/base-http.service';
import { Injectable, PLATFORM_ID, Inject, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { PaginatedList } from 'src/app/models/paginated-list';
import { MessageService } from 'src/app/services/messageService';

@Injectable({
    providedIn: 'root'
})
export class SendingEntityService extends BaseHttpService {

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    public getSendingEntities(params: DefaultListQueryParams): Observable<PaginatedList<SendingEntity>> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/company/companiesPaginated';
        const options = this.getOptions(params);

        return this.httpClient.get<PaginatedList<SendingEntity>>(url, options).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public deleteSendingEntity(id: number): Observable<any> {
        this.checkRequestTime();
        const url = this.getApiPath() + '/company/' + id;
        return this.httpClient.delete<any>(url, this.getOptions());
    }

    public getSendingEntity(id: number): Observable<SendingEntity> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/company/' + id;

        return this.httpClient.get<SendingEntity>(url, this.getOptions()).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public saveSendingEntity(sendingEntity: SendingEntity): Observable<SendingEntity> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/company';

        if (sendingEntity.id) {
            return this.httpClient.put<SendingEntity>(url, sendingEntity, this.getOptions());
        } else {
            return this.httpClient.post<SendingEntity>(url, sendingEntity, this.getOptions());
        }
    }

    public upload(data) {
        const uploadUrl = `/comic/fileupload`;

        const formData = new FormData();
        // payslips
        formData.append('_upid', '0');
        formData.append(data.name, data);
        
        return this.httpClient.post<any>(uploadUrl, formData);
    }

    public getCountries(): Observable<any> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/company/countries';
        const options = this.getOptions();

        return this.httpClient.get<any>(url, options).pipe(
            catchError(this.catchGlobalError)
        );
    }

}
