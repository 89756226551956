import { AssigneeSalary } from './../../../models/assignee-salary';
import { MessageService } from './../../../services/messageService';
import { MainService } from 'src/app/services/main.service';
import { AppData } from './../../../services/app-data.service';
import { AssigneeService } from './../../../routes/assignee/assignee.service';
import { ProjectService } from './../../../routes/project/project.service';
import { Project } from 'src/app/models/project';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Assignee } from 'src/app/models/assignee';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SendingEntity } from 'src/app/models/sending-entity';
import { SendingEntityService } from 'src/app/routes/sending-entity/sending-entity.service';
import { ReceivingEntity } from 'src/app/models/receiving-entity';
import { ReceivingEntityService } from 'src/app/routes/receiving-entity/receiving-entity.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-assignee-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnChanges {
    @Input()
    public assignee: Assignee;
    public profileForm: FormGroup;
    public sendingEntities: SendingEntity[];
    public receivingEntities: ReceivingEntity[];
    public projects: Project[];
    public isVisibleExempted$: Observable<boolean> = this.assigneeService.getVisibleExempted();
    public permissions = this.appData.permissions;
    public assigneeSalaries: AssigneeSalary[];

    public faInfoCircle = faInfoCircle;

    public openMe = false;
    public howToData;

    public genders = [
        {
            value: 'DIVERSE',
            text: 'Diverse'
        },
        {
            value: 'MALE',
            text: 'Male',
        },
        {
            value: 'FEMALE',
            text: 'Female',
        }
    ];

    // NEVER CHANGE
    public salaryPaymentDeadlines = [
        { value: 0, text: 'Last day of the actual month' },
        { value: 7, text: '6th of the following month' },
        { value: 11, text: '10th of the following month' },
        { value: 16, text: '15th of the following month' },
        { value: 15, text: '15th of the actual month' },
    ];

    public workingTimesAustria = [
        {
            value: null,
            text: '',
        },
        {
            value: 38,
            text: '38',
        },
        {
            value: 38.5,
            text: '38.5',
        },
        {
            value: 40,
            text: '40',
        },
    ];

    public holidayEntitlementsAustria = [
        {
            value: null,
            text: '',
        },
        {
            value: 25,
            text: '25',
        },
        {
            value: 30,
            text: '30',
        },
    ];

    public exemptedCategories = []

    public howToUseText;

    private activeUseDialog = false;
    // the user MUST choose an option, so we set it to null
    private activeUsePreselect = null;

    constructor(
        private formBuilder: FormBuilder,
        private sendingEntityService: SendingEntityService,
        private receivingEntityService: ReceivingEntityService,
        private projectService: ProjectService,
        private assigneeService: AssigneeService,
        private appData: AppData,
        private mainService: MainService,
        private messageService: MessageService,
        private router: Router,
        private userService: UserService,
        private httpClient: HttpClient
    ) {
        this.assignee = new Assignee();
        this.sendingEntityService
            .getSendingEntities({
                start: 0,
                limit: 1000,
                orderBy: { field: 'name', dir: 'asc' },
            })
            .subscribe((data) => {
                this.sendingEntities = data.list;
            });

        this.receivingEntityService
            .getReceivingEntities({
                start: 0,
                limit: 1000,
                orderBy: { field: 'name', dir: 'asc' },
            })
            .subscribe((data) => {
                this.receivingEntities = data.list;
            });

        this.projectService
            .getProjects({
                start: 0,
                limit: 1000,
            })
            .subscribe((data) => {
                this.projects = data.list;
            });

        this.processHowToData('profile');
        this.howToUseText = this.appData.getHowToData("profile");
        httpClient.get<any>("/comic/rest/constants").toPromise().then(res => {
            const data = res
            this.exemptedCategories = data.content.exemptedProfilesCategories.map(v => ({ value: v, text: v }));
        })
    }

    public ngOnChanges() {
        if (this.assignee) {
            if (this.assignee.id) {
                this.assigneeService.getAssigneeSalaries(this.assignee.id).subscribe((assigneeSalaries) => {
                    this.assigneeSalaries = assigneeSalaries;
                });
            } else {
                this.activeUseDialog = true;
            }
            this.createForm();
        }
    }

    private createForm() {
        this.profileForm = this.formBuilder.group({
            firstname: [this.assignee.firstname, [Validators.required]],
            lastname: [this.assignee.lastname, [Validators.required]],
            email: [this.assignee.email, [Validators.email, Validators.required]],
            birthday: [this.assignee.birthday],
            gender: [this.assignee.gender],
            street: [this.assignee.street],
            zipCode: [this.assignee.zipCode],
            city: [this.assignee.city],
            country: [this.assignee.country],
            citizenship: [this.assignee.citizenship],
            startDate: [this.assignee.startDate, [Validators.required]],
            endDate: [this.assignee.endDate, [Validators.required]],
            workPermitFrom: [this.assignee.workPermitFrom],
            workPermitTo: [this.assignee.workPermitTo],
            visaRpFrom: [this.assignee.visaRpFrom],
            visaRpTo: [this.assignee.visaRpTo],

            salary: [this.assignee.salary],
            dailyGrossUp: [this.assignee.dailyGrossUp],
            effectiveFrom: [null],

            salaryPaymentDeadline: [this.assignee.salaryPaymentDeadline, [Validators.required]],
            sendingEntity: [
                { value: this.assignee.sendingEntity, disabled: !this.permissions.ASSIGNEE_EDIT },
                [Validators.required],
            ],
            receivingEntity: [{ value: this.assignee.receivingEntity, disabled: !this.permissions.ASSIGNEE_EDIT }],
            projects: [
                { value: this.assignee.projects, disabled: !this.permissions.ASSIGNEE_EDIT },
                [Validators.required],
            ],
            workingTimeAustria: [this.assignee.workingTimeAustria],
            workingTimeHomeCountry: [this.assignee.workingTimeHomeCountry],
            holidayEntitlementAustria: [this.assignee.holidayEntitlementAustria],
            holidayEntitlementHomeCountry: [this.assignee.holidayEntitlementHomeCountry],
            exempted: [this.assignee.exempted],
            billingCode: [this.assignee.billingCode],
            personnelNumber: [this.assignee.personnelNumber],
            accessCardNumber: [this.assignee.accessCardNumber],
            alienActOnly: [this.assignee.alienActOnly],
            activeMonitoring: [this.assignee.activeMonitoring],
            internalNote: [this.assignee.internalNote],
        });
        const body = this.profileForm.getRawValue();

        if (!body['salaryPaymentDeadline']) {
            this.profileForm.controls['salaryPaymentDeadline'].setValue(0);
        }
    }

    async ngOnInit() {
        
    }

    private saveAssignee() {
        this.appData.showLoading = true;
        if (this.permissions.ASSIGNEE_EDIT) {
            this.assignee.metaData = null;
        }

        this.assignee.metaData = '';

        console.log("Try to save assignee", this.assignee)

        this.assigneeService.saveAssignee(this.assignee).subscribe((assignee) => {
            if (this.assignee.id) {
                // new mode so lets redirect to the new user
                //console.log(`If assignee has ID, return to profile: ${assignee.id}`)
                this.messageService.basicToast('Entity saved successfully.');
                this.appData.showLoading = false;
                return;
            }
            //this.assignee = assignee;
            //console.log("Check toast and redirect to assignees...", assignee);
            //this.appData.loadingEvents.next('end');
            this.appData.showLoading = false;
            this.messageService.basicToast('Entity saved successfully.');
            this.router.navigate(['/assignee']);
        });
    }

    public onClickSave() {
        if (!this.profileForm.valid) {
            // toast
            this.messageService.basicToast('Please complete all required fields.', 'warning');
            return;
        }
        // this.appData.loadingEvents.next('start');

        if (this.appData.permissions.ASSIGNEE_EDIT) {
            const salaryChanged =
                this.assignee.id &&
                (this.profileForm.value.salary !== this.assignee.salary ||
                    this.profileForm.value.dailyGrossUp !== this.assignee.dailyGrossUp);

            if (salaryChanged && !this.profileForm.value.effectiveFrom) {
                this.mainService.prompt(
                    'To change the salary, please fill in "effective from".',
                    (value, action) => {},
                    'alert'
                );
                return;
            }

            if (salaryChanged) {
                const assigneeSalary = new AssigneeSalary();
                assigneeSalary.assignee = { id: this.assignee.id } as Assignee;
                assigneeSalary.dailyGrossUp = this.profileForm.value.dailyGrossUp;
                assigneeSalary.salary = this.assignee.salary;
                assigneeSalary.endDate = this.profileForm.value.effectiveFrom;

                this.assigneeService.saveAssigneeSalary(assigneeSalary).subscribe();
            }
        }

        Object.assign(this.assignee, this.profileForm.value);
        const userInfo = this.assignee.userInfo||{}
        userInfo.activeUse = this.profileForm.value.userInfoActiveUse||false;
        this.assignee.userInfo = userInfo;
        console.log(this.profileForm)
        console.log(this.assignee)

        if (
            this.assignee.id &&
            (this.profileForm.controls.startDate.dirty || this.profileForm.controls.endDate.dirty)
        ) {
            this.assigneeService
                .isTimerecordOutOfPeriod({
                    id: this.assignee.id,
                    startDate: this.profileForm.controls.startDate.value,
                    endDate: this.profileForm.controls.endDate.value,
                })
                .subscribe((isTimerecordOutOfPeriod) => {
                    if (isTimerecordOutOfPeriod) {
                        const msg =
                            'There are currently time records for the days being removed from the profile. ' +
                            'If you proceed with change, these time records will be deleted.';

                        this.mainService.prompt(
                            msg,
                            (val, action) => {
                                // console.log(action, val);
                                if (action === 'yes') {
                                    this.saveAssignee();
                                }
                            },
                            'confirm'
                        );
                    } else {
                        this.saveAssignee();
                    }
                });
        } else {
            this.saveAssignee();
        }
    }

    public onClickCancelAssignee() {
        this.mainService.prompt(
            'Are you sure you want to cancel this assignee?',
            (value, action) => {
                if (action === 'yes') {
                    this.assignee.canceled = true;
                    this.assigneeService.saveAssignee(this.assignee).subscribe();
                }
            },
            'confirm'
        );
    }

    public onClickRequestChanges() {
        this.assigneeService.getAssignee(this.assignee.id).subscribe((originalAssignee) => {
            const changes = this.assigneeService.getChangesAsMetaData(this.profileForm.value, originalAssignee);

            const changesString = JSON.stringify(changes);

            this.assignee.metaData = changesString;
            this.assigneeService.saveAssignee(this.assignee).subscribe((assignee) => {
                this.assignee = assignee;
                this.createForm();
                this.messageService.basicToast('Your changes will be applied after review.');
            });
        });
    }

    public onClickRejectChanges() {
        const msg =
            'You are about to reject and restore all changes (including changes ' +
            'that you have made now). Please enter an explanation:';

        this.mainService.prompt(
            msg,
            (explanation, action) => {
                if (action === 'ok') {
                    this.assigneeService.rejectChangeRequest(this.assignee.id, explanation).subscribe(() => {
                        this.assigneeService.getAssignee(this.assignee.id).subscribe((assignee) => {
                            this.messageService.basicToast('Changes have been rejected.');
                            this.assignee = assignee;
                        });
                    });
                }
            },
            'prompt',
            600,
            true
        );
    }

    public onClickOpenModal() {
        this.openMe = true;
        // this.fillAbsencePeriodModal.hideModal();
    }
    public onCloseOpenModal() {
        this.openMe = false;
    }
    public deleteSalaryEntry(event, entry) {
        this.mainService.prompt(
            'Are you sure you want to delete this history entry?',
            (explanation, action) => {
                if (action === 'yes') {
                    this.assigneeService.deleteSalary(entry.id).subscribe(
                        (success) => {
                            // reload the salary
                            this.assigneeService.getAssigneeSalaries(this.assignee.id).subscribe((assigneeSalaries) => {
                                this.assigneeSalaries = assigneeSalaries;
                            });
                        },
                        (error) => {}
                    );
                }
            },
            'confirm',
            600,
            true
        );
    }

    private processHowToData(howToName) {
        if (!this.appData.loggedInAssignee||!this.appData.loggedInUser['assignee']) {
            return;
        }
        this.howToData = this.appData.loggedInUser['assignee'].howToData;
        this.howToData = this.howToData
            ? JSON.parse(this.howToData)
            : { home: false, profile: false, documents: false, timerecords: false, payslips: false };

        const assigneeId = this.appData.loggedInUser['assignee'].id;

        if (this.howToData[howToName]) {
            return;
        }
        this.howToData[howToName] = true;
        this.onClickOpenModal();
        // this.saveHowTo = true;

        const buildsave = {
            id: assigneeId,
            howToData: JSON.stringify(this.howToData),
        };

        this.saveHowToData(buildsave);
    }

    private async saveHowToData(howTo: any) {
        await this.assigneeService.saveHowToData(howTo).toPromise();
        await this.userService.getCurrentUserData();
    }

    private assigneeActiveUseClicked() {
        if (this.activeUsePreselect === null) {
            this.messageService.basicToast('Please select a value!', 'warning');
            return
        }
        this.assignee.activeMonitoring = this.activeUsePreselect;
        this.createForm();
        this.activeUseDialog = false;
    }
}
