import { UploadModule } from '@progress/kendo-angular-upload';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { HttpClientModule } from '@angular/common/http';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './components/modal/modal.component';
import { HrAgentsComponent } from './components/hr-agents/hr-agents.component';
import { HomeComponent } from './components/assignee/home/home.component';
import { ProfileComponent } from './components/assignee/profile/profile.component';
import { ProfileReportComponent } from './components/assignee/profile/reporting/profile.report.component';
import { TimeRecordsComponent } from './components/assignee/time-records/time-records.component';
import { PayslipsComponent } from './components/assignee/payslips/payslips.component';
import { TvButtonComponent } from './components/tv-button/tv-button.component';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { GeneralDocumentsComponent } from './components/assignee/general-documents/general-documents.component';
import { SendDocComponent } from './components/assignee/home/send-doc/send-doc.component';
import { KiabAlertComponent } from './components/assignee/home/kiab-alert/kiab-alert.component';
import { DocumentDownloadUrlPipe } from './pipes/document-download-url.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { TooltipDirective } from './directives/tooltip.directive';
import { TooltipComponent } from './directives/tooltip.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CssLoaderComponent } from './shared/components/css-loader.component';
import { CountUpModule } from 'ngx-countup';
import { TvUploadComponent } from './components/tv-upload/tv-upload.component';

@NgModule({
    declarations: [
        ModalComponent,
        HrAgentsComponent,
        HomeComponent,
        ProfileComponent,
        ProfileReportComponent,
        TimeRecordsComponent,
        PayslipsComponent,
        GeneralDocumentsComponent,
        SendDocComponent,
        KiabAlertComponent,
        DocumentDownloadUrlPipe,
        DurationPipe,
        TooltipDirective,
        TooltipComponent,
        InfoButtonComponent,
        TvButtonComponent,
        CssLoaderComponent,
        TvUploadComponent,
    ],
    entryComponents: [
        ModalComponent,
        HomeComponent,
        ProfileComponent,
        ProfileReportComponent,
        TimeRecordsComponent,
        PayslipsComponent,
        GeneralDocumentsComponent,
        SendDocComponent,
        KiabAlertComponent,
        TooltipComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        GridModule,
        DateInputsModule,
        DropDownsModule,
        ButtonsModule,
        FormsModule,
        DropDownsModule,
        HttpClientModule,
        InputsModule,
        LayoutModule,
        ReactiveFormsModule,
        UploadModule,
        FontAwesomeModule,
        LeafletModule,
        ChartsModule,
        CountUpModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        GridModule,
        ButtonsModule,
        FormsModule,
        DropDownsModule,
        HttpClientModule,
        InputsModule,
        LayoutModule,
        ReactiveFormsModule,
        ModalComponent,
        HrAgentsComponent,
        HomeComponent,
        ProfileComponent,
        ProfileReportComponent,
        TimeRecordsComponent,
        PayslipsComponent,
        GeneralDocumentsComponent,
        TooltipDirective,
        InfoButtonComponent,
        DateInputsModule,
        FontAwesomeModule,
        LeafletModule,
        CssLoaderComponent,
        ChartsModule,
        CountUpModule,
    ],
})
export class SharedModule {}
