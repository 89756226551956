import { SendingEntity } from './sending-entity';
import { User } from 'src/app/models/user';
import { ReceivingEntity } from './receiving-entity';

export class HrAgent {
    public id: number;
    public username: string;
    public email: string;
    public firstname: string;
    public lastname: string;
    public user: User;
    public sendingEntity: SendingEntity;
    public receivingEntity: ReceivingEntity;
}
