import { ProjectAdmin } from './../../models/project-admin';
import { DefaultListQueryParams } from './../../models/query';
import { catchError, map, delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from './../../services/base-http.service';
import { Injectable, PLATFORM_ID, Inject, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Project } from 'src/app/models/project';
import { SortDescriptor } from '@progress/kendo-data-query';
import { PaginatedList } from 'src/app/models/paginated-list';
import { MessageService } from 'src/app/services/messageService';


@Injectable({
    providedIn: 'root'
})
export class ProjectService extends BaseHttpService {

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    public getProjects(params: DefaultListQueryParams): Observable<any> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/project/projectsPaginated';
        // const url = '/assets/projects.json';
        const options = this.getOptions(params);

        return this.httpClient.get<PaginatedList<Project>>(url, options).pipe(
            map((data) => {
                // we need to modify that data
                for (const project of data.list) {
                    if (project['projectAddresses'].length) {
                        project['street'] = project['projectAddresses'][0].street;
                        project['zipCode'] = project['projectAddresses'][0].zipCode;
                        project['city'] = project['projectAddresses'][0].city;
                        project['country'] = project['projectAddresses'][0].country;
                    }
                }
                // data['street'] = data['projectAddresses'][0].street;
                return data;
            })
            // catchError(this.catchGlobalError)
        );
    }

    public getProject(id: number): Observable<Project> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/project/' + id;

        return this.httpClient.get<Project>(url, this.getOptions()).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public saveProject(project: Project) {
        this.checkRequestTime();

        const url = this.getApiPath() + '/project';

        if (project.id) {
            return this.httpClient.put<Project>(url, project, this.getOptions()).pipe(
                catchError(this.catchGlobalError)
            );
        } else {
            return this.httpClient.post<Project>(url, project, this.getOptions()).pipe(
                catchError(this.catchGlobalError)
            );
        }
    }

    public saveProjectAdmin(projectAdmin: ProjectAdmin) {
        this.checkRequestTime();

        const url = this.getApiPath() + '/project/projectAdmin';

        if (projectAdmin.id) {
            return this.httpClient.put<ProjectAdmin>(url, projectAdmin, this.getOptions()).pipe(
                catchError(this.catchGlobalError)
            );
        }
        return this.httpClient.post<ProjectAdmin>(url, projectAdmin, this.getOptions()).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public sendProjectAdminCredentials(projectAdmin: ProjectAdmin) {
        this.checkRequestTime();

        const url = this.getApiPath() + '/project/sendProjectAdminCredentials';

        return this.httpClient.post<boolean>(url, projectAdmin, this.getOptions()).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public deleteProject(projectId: number) {
        this.checkRequestTime();

        const url = this.getApiPath() + '/project/' + projectId;

        return this.httpClient.delete<any>(url, this.getOptions()).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public archive(projectId: number) {
        this.checkRequestTime();
        const url = this.getApiPath() + '/project/archive/' + projectId;
        return this.httpClient.get<any>(url, this.getOptions()).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public deleteProjectAgent(projectAgentId: number) {
        this.checkRequestTime();

        const url = this.getApiPath() + '/project/projectAdmin/' + projectAgentId;

        return this.httpClient.delete<any>(url, this.getOptions());
    }

}
