export default {
    general: `Bevor Sie das Profil für einen Kandidaten / eine Kandidatin eingeben, vergewissern Sie sich bitte, dass das entsendende Unternehmen (in den Einwanderungsszenarien 1 bis 5) bzw. das aufnehmende Unternehmen (in den Einwanderungsszenarien 1 bis 5) bzw. der österreichische Arbeitgeber (im Einwanderungsszenario 6) bereits im System registriert ist.<br><br>
    Before you enter the profile for a single candidate, please make sure that the sending entity (in the immigration scenarios 1 to 5) respectively the receiving entity (in the immigration scenarios 1 to 5) / local Austrian employer (in the immigration scenario 6) is already registered in the system.`,

    sending: `Für die Einwanderungsszenarien 1 bis 5 benötigen Sie ein entsendendes Unternehmen im Heimatland (= der Arbeitgeber im Heimatland). Bitte geben Sie die Informationen hier ein.<br><br>
    For the immigration scenarios 1 to 5 you need a home country sending entity (= the home country employer). Please enter the information here. 
    `,

    receiving: `Für die Einwanderungsszenarien 1 bis 5 benötigen Sie ein österreichisches aufnehmendes Unternehmen. Für das Einwanderungsszenario 6 benötigen Sie einen österreichischen Arbeitgeber, der den Kandidaten / die Kandidatin einstellt. Bitte tragen Sie die Informationen hier ein. <br> <br>
    For the immigration scenarios 1 to 5 you need an Austrian receiving entity. In the immigration scenario 6 you need an Austrian employer who locally hires the candidate. Please enter the information here.
    `,

    trackingReport: `Sie können einen Bericht erstellen, der die einzelnen Ablaufdaten für die Kandidaten und Kandidatinnen enthält. Profile, die abgelaufen sind, sind - wenn kein Verlängerungsprozess aktiviert wurde - im Bericht nicht mehr enthalten. Diese Profile finden Sie dann in der Funktion "Archiv" (rechts oben auf Status klicken und "archiviert" wählen).  
    <br><br>
    You can generate a report which contains the single expiry dates for the candidates. Profiles which have expired, if no extension process has been kicked of, are not visible any more. Such profiles can be found in the “archive” function (click status at the right top corner and choose “archived”).  
    `,

    sendingReport: `Für die Einwanderungsszenarien 2, 4 und 5 (wenn die Option "Überlassung" zutrifft) können Sie hier einen Bericht über die Kandidaten und Kandidatinnen je überlassendem Unternehmen (= ausländischer Arbeitgeber) erstellen.
    <br><br>
    Regarding the immigration scenarios 2, 4 and 5 (if the option “leasing/hiring-out” applies), you can generate a report on the candidates per sending entity (= foreign employer) here.
    `,

    receivingReport: `Für die Einwanderungsszenarien 2 und 4 können Sie hier einen Bericht über die Kandidaten und Kandidatinnen je aufnehmendem Unternehmen (= inländischer Beschäftiger) erstellen.
    <br><br>
    Regarding the immigration scenarios 2 and 4, you can generate a report on the candidates per Austrian receiving entity here. 
    `,

    statisticAustria: `Bei den Einwanderungsszenarien 2, 4 und 5 (wenn die Option "Überlassung" zutrifft) muss das überlassende ausländische Unternehmen bzw. der aufnehmende österreichische Beschäftiger die im Zeitraum vom 1. Juli (des Vorjahres) bis 30. Juni (des laufenden Jahres) überlassenen Kandidaten und Kandidatinnen bis Ende September des jeweiligen laufenden Jahres melden. Hier können Sie den entsprechenden Bericht herunterladen. 
    <br><br>
    For candidates falling under the immigration scenarios 2, 4 and 5 (if the option “leasing/hiring-out” applies) the foreign sending entity respectively the Austrian receiving entity (as the case may be) must report the posted candidates for the period 1 July (previous year) to 30 June (current year) by the end of September of the respective current year. Here you can download the respective report. 
    `,

    'Assignment EU Normal - Category 1': `Assignment EU Normal – Category 1 / Entsendung EU Normal – Kategorie 1
    <br><br>
    Dieses Einwanderungsszenario gilt für ArbeitnehmerInnen, die StaatsbürgerInnen der EU/EWR/Schweiz sind, und die von einem Arbeitgeber mit Sitz in der EU/EWR/Schweiz (entsendendes Unternehmen) auf seine Rechnung und unter seiner Leitung im Rahmen eines Vertrags zwischen dem entsendenden Unternehmen und der in Österreich tätigen Partei, für die die Dienstleistungen bestimmt sind (aufnehmendes Unternehmen), entsandt werden, sofern während des Entsendungszeitraums ein Arbeitsverhältnis zwischen dem entsendenden Unternehmen und dem Arbeitnehmer/der Arbeitnehmerin besteht und bestehen bleibt.
    <br><br>
    This immigration scenario applies to EU/EEA/Swiss employees posted by an employer based in the EU/EEA/Switzerland (sending entity) on its account and under its direction under a contract concluded between the sending entity and the party for whom the services are intended (receiving entity), operating in Austria, provided there is an (ongoing) employment relationship between the sending entity and the employee during the period of posting.
    `,
    'Secondment EU Normal - Category 2': `Secondment EU Normal – Category 2 / Überlassung EU Normal – Kategorie 2
    <br><br>
    Dieses Einwanderungsszenario gilt für ArbeitnehmerInnen, die StaatsbürgerInnen der EU/EWR/Schweiz sind, 
    <br><br>
    •	die von einem Arbeitgeber mit Sitz in der EU/EWR/Schweiz (überlassendes Unternehmen/überlassender Arbeitgeber) zu einer Niederlassung oder einem Unternehmen desselben Konzerns (aufnehmendes Unternehmen/Beschäftiger) in Österreich überlassen werden, sofern zwischen dem entsendenden Unternehmen und dem Arbeitnehmer/der Arbeitnehmerin während des Überlassungszeitraums ein Arbeitsverhältnis besteht und bestehen bleibt;
    <br>oder<br>
    •	die von einem ausländischen überlassenden Unternehmen/einem ausländischen Arbeitgeber mit Sitz in der EU/EWR/Schweiz an ein entleihendes Unternehmen (aufnehmendes Unternehmen/Beschäftiger), das in Österreich ansässig oder tätig ist, überlassen werden, sofern zwischen dem überlassenden Unternehmen/Arbeitgeber und dem Arbeitnehmer/der Arbeitnehmerin während des Überlassungszeitraums ein Arbeitsverhältnis besteht und bestehen bleibt.
    <br><br>
    This immigration scenario applies to EU/EEA/Swiss employees
    <br><br>
    •	posted by an employer based in the EU/EEA/Switzerland (sending entity) to an establishment or to an undertaking part of the same group (receiving entity) in the territory of Austria, provided there is an (ongoing) employment relationship between the sending entity and the employee during the period of posting;
    <br>or<br>
    •	hired out by a foreign sending entity/foreign employer based in the EU/EEA/Switzerland to a receiving entity established or operating in Austria, provided there is an (ongoing) employment relationship between the foreign sending entity/foreign employer and the employee during the period of posting.
    `,
    'Assignment EU-Vanderelst - Category 3': `Assignment EU VanderElst– Category 3 / Entsendung EU VanderElst – Kategorie 3<br><br>Dieses Einwanderungsszenario gilt für drittstaatsangehörige ArbeitnehmerInnen (= Staatsangehörige, die nicht Staatsangehörige der EU/EWR/Schweiz sind), die von einem Arbeitgeber mit Sitz in der EU/EWR/Schweiz (entsendendes Unternehmen) auf seine Rechnung und unter seiner Leitung im Rahmen eines Vertrags zwischen dem entsendenden Unternehmen und der in Österreich tätigen Partei, für die die Dienstleistungen bestimmt sind (aufnehmendes Unternehmen), entsandt werden, sofern während des Entsendungszeitraums ein Arbeitsverhältnis zwischen dem entsendenden Unternehmen und dem Arbeitnehmer/der Arbeitnehmerin besteht und bestehen bleibt.
    <br><br>
    This immigration scenario applies to Third Country employees (=citizens not being EU/EEA/Swiss nationals) posted by an employer based in the EU/EEA/Switzerland (sending entity) on their account and under their direction under a contract concluded between the sending entity and the party for whom the services are intended (receiving entity), operating in Austria, provided there is an (ongoing) employment relationship between the sending entity and the employee during the period of posting.
    `,
    'Secondment EU-Vanderelst - Category 4': `Secondment EU VanderElst – Category 4 / Überlassung EU VanderElst – Kategorie 4<br><br>Dieses Einwanderungsszenario gilt für drittstaatsangehörige ArbeitnehmerInnen (= Staatsangehörige, die nicht Staatsangehörige der EU/EWR/Schweiz sind),
    <br><br>
    •	die von einem Arbeitgeber mit Sitz in der EU/EWR/Schweiz (überlassendes Unternehmen/überlassender Arbeitgeber) zu einer Niederlassung oder einem Unternehmen desselben Konzerns (aufnehmendes Unternehmen/Beschäftiger) in Österreich überlassen werden, sofern zwischen dem entsendenden Unternehmen und dem Arbeitnehmer/der Arbeitnehmerin während des Überlassungszeitraums ein Arbeitsverhältnis besteht und bestehen bleibt;
    <br> oder<br>
    •	die von einem ausländischen überlassenden Unternehmen/einem ausländischen Arbeitgeber mit Sitz in der EU/EWR/Schweiz an ein entleihendes Unternehmen (aufnehmendes Unternehmen/Beschäftiger), das in Österreich ansässig oder tätig ist, überlassen werden, sofern zwischen dem überlassenden Unternehmen/Arbeitgeber und dem Arbeitnehmer/der Arbeitnehmerin während des Überlassungszeitraums ein Arbeitsverhältnis besteht und bestehen bleibt.
    <br><br>
    This immigration scenario applies to Third Country employees (=citizens not being EU/EEA/Swiss nationals) 
    <br><br>
    •	posted by an employer based in the EU/EEA/Switzerland (sending entity) to an establishment or to an undertaking part of the same group in the territory of Austria (receiving entity), provided there is an (ongoing) employment relationship between the sending entity and the employee during the period of posting;
    <br>or<br>
    •	 hired out by a foreign sending entity/foreign employer based in the EU/EEA/Switzerland to a receiving entity established or operating in Austria, provided there is an (ongoing) employment relationship between the foreign sending entity/foreign employer and the employee during the period of posting.
    `,
    'TCN Assignment | stand alone Work Permit  | ICT - Category 5': `TCN Assignment | stand alone Work Permit  | ICT– Category 5 / Entsendung/Überlassung von Drittstaatsangehörigen | Beschäftigungsbewilligungen | ICT – Kategorie 5
    <br> 
    <br>Dieses Einwanderungsszenario gilt für drittstaatsangehörige ArbeitnehmerInnen (= Staatsangehörige, die nicht Staatsangehörige der EU/EWR/Schweiz sind),
    <br> 
    <br>die von einem Arbeitgeber mit Sitz außerhalb der EU/EWR/Schweiz (ausländisches entsendendes Unternehmen/ausländischer Arbeitgeber) auf seine Rechnung und unter seiner Leitung im Rahmen eines Vertrags zwischen dem entsendenden Unternehmen und der in Österreich tätigen Partei, für die die Dienstleistungen bestimmt sind (aufnehmendes Unternehmen), entsandt werden, sofern während des Entsendungszeitraums ein Arbeitsverhältnis zwischen dem ausländischen entsendenden Unternehmen/Arbeitgeber und dem Arbeitnehmer/der Arbeitnehmerin besteht (Entsendung von Drittstaatsangehörigen);
    <br>oder
    <br>die von einem Arbeitgeber mit Sitz außerhalb der EU/EWR/Schweiz (ausländisches überlassendes Unternehmen/ausländischer Arbeitgeber) auf seine Rechnung und unter seiner Leitung im Rahmen eines Vertrags zwischen dem entsendenden Unternehmen und der in Österreich tätigen Partei, für die die Dienstleistungen bestimmt sind (aufnehmendes Unternehmen), entsandt werden, sofern während des Überlassungszeitraums ein Arbeitsverhältnis zwischen dem ausländischen überlassenden Unternehmen/Arbeitgeber und dem Arbeitnehmer/der Arbeitnehmerin besteht (Überlassung von Drittstaatsangehörigen);
    <br>oder
    <br>die von einem Arbeitgeber mit Sitz außerhalb der EU/EWR/Schweiz (ausländisches delegierendes Unternehmen) zu einer Niederlassung oder einem Unternehmen desselben Konzerns (aufnehmendes Unternehmen) in Österreich delegiert werden, sofern zwischen dem delegierenden Unternehmen und dem Arbeitnehmer/der Arbeitnehmerin während des Delegationszeitraums ein Arbeitsverhältnis besteht (ICT);
    <br>oder
    <br>die von einem Arbeitgeber mit Sitz in Österreich als Volontär, Praktikant, Trainee oder in einer anderen Eigenschaft unter eine vom AMS zu erteilenden Beschäftigungsbewilligung oder Anzeigebestätigung beschäftigt werden (zB Inhaber eines Aufenthaltstitels Student + Beschäftigunsbewilligung; Inhaber eines Aufenthaltstitels Student + Anzeigebestätigung; Inhaber einer Ausweises für Vertriebene + Beschäftigunsbewilligung;  Inhaber eines Visums + Anzeigebestätigung/Beschäftigungsbewilligung; Inhaber eines diplomatischen Passes + Beschäftigungsbewilligung). 
    <br> 
    <br>This immigration scenario applies to Third Country employees (=citizens not being EU/EEA/Swiss nationals)
    <br> 
    <br>posted by an employer based outside the EU/EEA/Switzerland on its account and under its direction (foreign sending entity/foreign employer) under a contract concluded between the sending entity and the party for whom the services are intended (receiving entity), operating in Austria, provided there is an employment relationship between the sending entity and the employee during the period of posting (TCN Assignment);
    <br>or
    <br>hired out by an employer based outside the EU/EEA/Switzerland (foreign sending entity/foreign employer) to a user undertaking established or operating in Austria (receiving entity), provided there is an employment relationship between the foreign sending entity/employer and the employee during the period of posting (TCN Assignment);
    <br>or
    <br>posted by an employer based outside the EU/EEA/Switzerland (foreign sending entity) to an establishment or to an undertaking part of the same group (receiving entity) in the territory of Austria, provided there is an employment relationship between the sending entity and the ICT-employee during the period of posting (ICT);
    <br>or
    <br>who are employed by an employer based in Austria as a volunteer, intern, trainee or in any other capacity under an employment permit or notification confirmation to be issued by the AMS (e.g. holder of a residence permit student + employment permit; holder of a residence permit student + notification confirmation; holder of an ID card as a displaced person + employment permit; holder of a visa + notification confirmation/employment permit; holder of a diplomatic passport + employment permit).`,
    'Local Hire – Category 6': `Local Hire – Category 6 / Lokale Anstellung – Kategorie 6
    <br><br>
    Dieses Einwanderungsszenario gilt für lokal in Österreich angestellte ArbeitnehmerInnen, die keine EU/EWR/Schweizer Staatsangehörige sind (z.B.: Rot-Weiß-Rot-Karte, Rot-Weiß-Rot-Plus-Karte, Blaue Karte EU, Daueraufenthaltskarte usw.).
    <br><br>
    This immigration scenario applies to employees locally hired in Austria who are not EU/EEA/Swiss nationals (e.g.: Red White Red Cards, Red White Red Plus Card, Blue Card EU, Permanent Residence Permit, etc.). 
    `,
    'Private Client - Category 7': `Private Client – Category 7 / Privatieer – Kategorie 7 <br><br>Dieses Einwanderungsszenario gilt für Kandidaten/Kandidatinnen, die keine EU-/EWR-/Schweizer Staatsangehörigen sind, nicht in Österreich arbeiten und nicht nach Österreich entsandt sind, die aber beabsichtigen, in Österreich zu wohnen.
    <br><br>
    This immigration scenario applies to candidates who are not EU/EEA/Swiss nationals and not working in Austria or posted into Austria, but who intend to reside in Austria. 
    `,
};
