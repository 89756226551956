import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-wrapper',
    templateUrl: './wrapper.component.html',
    styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        // this.route.queryParams.subscribe(() => {
        //     const data = this.route.snapshot.data;
        //     this.renderComponent(data.component);
        // });
    }

    ngOnInit() {}

    renderComponent(component: any) {
        const factory = this.componentFactoryResolver.resolveComponentFactory<any>(component);

        this.viewContainerRef.clear();
        this.viewContainerRef.createComponent(factory);
    }
}
