import { MainService } from './../../../services/main.service';
import { DocumentTypeElement } from './../../../models/document-type-element';
import { AssigneeService } from './../../../routes/assignee/assignee.service';
import { DocumentService } from './../../../services/document.service';
import { DocumentTypeMap } from 'typings';
import { Assignee } from './../../../models/assignee';
import { Injectable } from '@angular/core';
import { Document } from 'src/app/models/document';
import { DocumentType } from 'src/app/models/document-type';
import { Observable, of, Subject } from 'rxjs';
import { MessageService } from '@progress/kendo-angular-l10n';
import { UserService } from 'src/app/services/user.service';

/**
 * Holds data and business logic for general-documents.component
 */
@Injectable({
    providedIn: 'root',
})
export class GeneralDocumentsService {
    /**
     * The current assignee being edited or viewed
     */
    public assignee: Assignee;

    /**
     */
    public assigneeDocuments: Document[];

    public documentTypes$: Observable<DocumentType[]>;

    public documentTypeMap$: Observable<DocumentTypeMap>;

    public assigneeDocuments$: Observable<Document[]>;

    public documentTypeElements: DocumentTypeElement[];
    public documentTypeElements$: Subject<DocumentTypeElement[]>;
    public tenantName_;

    constructor(
        private documentService: DocumentService,
        private assigneeService: AssigneeService,
        private mainService: MainService,
        public userService: UserService
    ) {
        this.documentTypes$ = this.documentService.getDocumentTypes();
        this.documentTypeMap$ = this.documentService.getDocumentTypesMap();
    }

    public setAssignee(assignee: Assignee) {
        this.assignee = assignee;

        this.assigneeDocuments$ = this.assigneeService.getDocumentsByAssignee(assignee.id);
        this.documentTypeElements$ = new Subject<DocumentTypeElement[]>();

        this.documentTypes$.subscribe((documentTypes) => {
            this.assigneeDocuments$.subscribe((assigneeDocuments) => {
                const docTypeEls: DocumentTypeElement[] = [];

                for (const documentType of documentTypes) {
                    const docTypeEl = new DocumentTypeElement();
                    Object.assign(docTypeEl, documentType);

                    const document = assigneeDocuments.find((assigneeDocument) => {
                        if (!assigneeDocument.documentType) {
                            return false;
                        }
                        return assigneeDocument.documentType.name === documentType.name;
                    });

                    if (document) {
                        docTypeEl.isEnabled = true;
                        docTypeEl.document = document;
                    }

                    docTypeEls.push(docTypeEl);
                }

                // also add custom documents
                for (const assigneeDocument of assigneeDocuments) {
                    if (!assigneeDocument.documentType) {
                        docTypeEls.push({
                            isEnabled: true,
                            document: assigneeDocument,
                            name: assigneeDocument.customName,
                        } as DocumentTypeElement);
                    }
                }

                // this.documentTypeElements$.next(docTypeEls);
                // docTypeEls.sort((a, b) => (a.name > b.name) ? 1 : -1);
                docTypeEls.sort((a, b) => {
                    a.name = a.name || ""
                    b.name = b.name || ""
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    var isEnabledA = a.isEnabled;
                    var isEnabledB = b.isEnabled;

                    if (isEnabledA !== isEnabledB) {
                        return isEnabledA ? -1 : 1;
                    } else {
                        return nameA > nameB ? 1 : -1;
                    }
                });

                this.documentTypeElements = docTypeEls;
            });
        });
    }

    private getTenant() {
        this.userService.getTenant().subscribe((success) => {
            this.tenantName_ = success;
        });
    }
}
