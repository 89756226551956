import { DocumentType } from 'src/app/models/document-type';
import { Document } from './document';

/**
 * Helper model for the general documents view
 */
export class DocumentTypeElement extends DocumentType {
    public document: Document;
    public isEnabled: boolean;
	public name: string;
}
