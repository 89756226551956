import { AppData } from './../../../../services/app-data.service';
import { AssigneeService } from './../../../../routes/assignee/assignee.service';
import { DocumentService } from './../../../../services/document.service';
import { Assignee } from './../../../../models/assignee';
import { ModalComponent } from './../../../modal/modal.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MessageService } from 'src/app/services/messageService';

@Component({
    selector: 'app-kiab-alert',
    templateUrl: './kiab-alert.component.html',
    styleUrls: ['./kiab-alert.component.scss']
})
export class KiabAlertComponent implements OnInit {

    public formGroup: FormGroup;

    @ViewChild(ModalComponent, {
        static: true
    })
    private modal: ModalComponent;

    @Input()
    public assignee: Assignee;

    constructor(
        private formBuilder: FormBuilder,
        private assigneeService: AssigneeService,
        private messageService: MessageService,
        private appData: AppData
    ) {
        this.formGroup = this.formBuilder.group({
            phone: ['', []]
        });
    }

    ngOnInit() {
    }

    public show() {
        this.modal.show();
    }

    public clickedCancel() {
        this.modal.hideModal();
    }

    public clickedSend() {
        const values = this.formGroup.value;
        this.appData.loadingEvents.next('start');
        this.assigneeService.sendInspectionNotification(this.assignee.id, values.phone).subscribe(() => {
            this.appData.loadingEvents.next('end');
            this.modal.hideModal();
            this.messageService.basicToast('Notification has been sent successfully.');
        }, () => {
            this.appData.loadingEvents.next('end');
        });
    }

}
