import { DefaultListQueryParams } from './../../models/query';
import { catchError, map, delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from './../../services/base-http.service';
import { Injectable, PLATFORM_ID, Inject, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { PaginatedList } from 'src/app/models/paginated-list';
import { ReceivingEntity } from 'src/app/models/receiving-entity';
import { MessageService } from 'src/app/services/messageService';

@Injectable({
    providedIn: 'root'
})
export class ReceivingEntityService extends BaseHttpService {

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        httpClient: HttpClient,
        ngZone: NgZone,
        messageService: MessageService
    ) {
        super(platformId, httpClient, ngZone, messageService);
    }

    public getReceivingEntities(params: DefaultListQueryParams): Observable<PaginatedList<ReceivingEntity>> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/receivingCompany/receivingCompaniesPaginated';
        const options = this.getOptions(params);

        return this.httpClient.get<PaginatedList<ReceivingEntity>>(url, options).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public getReceivingEntity(id: number): Observable<ReceivingEntity> {
        this.checkRequestTime();

        const url = this.getApiPath() + '/receivingCompany/' + id;

        return this.httpClient.get<ReceivingEntity>(url, this.getOptions()).pipe(
            catchError(this.catchGlobalError)
        );
    }

    public saveReceivingEntity(receivingEntity: ReceivingEntity) {
        this.checkRequestTime();

        const url = this.getApiPath() + '/receivingCompany';

        if (receivingEntity.id) {
            return this.httpClient.put<ReceivingEntity>(url, receivingEntity, this.getOptions());
        }

        return this.httpClient.post<ReceivingEntity>(url, receivingEntity, this.getOptions());
    }

    public deleteReceivingEntity(id: number): Observable<any> {
        this.checkRequestTime();
        const url = this.getApiPath() + '/receivingCompany/' + id;
        return this.httpClient.delete<any>(url, this.getOptions());
    }

    public upload(data) {
        const uploadUrl = `/comic/fileupload`;

        const formData = new FormData();
        // payslips
        formData.append('_upid', '0');
        formData.append(data.name, data);
        return this.httpClient.post<any>(uploadUrl, formData);
    }


}
