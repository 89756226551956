import { AppData } from './../../../services/app-data.service';
import { Assignee } from 'src/app/models/assignee';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-payslips',
    templateUrl: './payslips.component.html',
    styleUrls: ['./payslips.component.scss']
})
export class PayslipsComponent implements OnInit {

    public assignee: Assignee = this.appData.loggedInAssignee;

    constructor(
        private appData: AppData
    ) { 
	}

    ngOnInit() {

    }

}
