import { HrAgent } from './hr-agent';

export class SendingEntity {
    id: number;
    name: string;
    street: string;
    zipCode: string;
    city: string;
    country: string;
    logoFileName: string;
    hrAgents: HrAgent[];
    company: any;

    constructor() {
        this.company = {
            representationOfInterests: ""
        }
    }
}
