import { MainService } from 'src/app/services/main.service';
import { MessageService } from 'src/app/services/messageService';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    private iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

    constructor(
        protected messageService: MessageService,
        protected mainService: MainService
    ) {
    }

    /**
     * HTTP-Requests will return only response.content
     * Date-strings are detected and converted to date objects
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.body && !(request.body instanceof FormData)) {
            const body = this.mainService.clone(request.body);
            this.serializeDates(body);
            request = request.clone({
                body
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse && event.body.content !== undefined) {

                    const body = event.body.content;
                    this.convertToDate(body);

                    const modifiedEvt = event.clone({
                        body
                    });

                    return modifiedEvt;
                }

                return event;
            }), catchError((error: HttpErrorResponse) => {

                if (error.status === 401) {
                    // user is not logged in
                    return throwError(error);
                }

                let msg = 'Error occurred';

                if (error.error.content && error.error.content.message) {
                    msg += ': <br>' + error.error.content.message;
                }

                //this.messageService.basicToast(msg, 'error');
                return throwError(error);
            }));
    }

    private leadingZero(num: number): string {
        return (num < 10 ? '0' : '') + num;
    }

    private dateToString(date: Date) {
        return [
            date.getFullYear(),
            this.leadingZero(date.getMonth() + 1),
            this.leadingZero(date.getDate())
        ].join('-') + 'T' + [
            this.leadingZero(date.getHours()),
            this.leadingZero(date.getMinutes()),
            this.leadingZero(date.getSeconds())
        ].join(':');
    }

    private serializeDates(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (value instanceof Date) {
                body[key] = this.dateToString(value);
            } else if (typeof value === 'object') {
                this.serializeDates(value);
            }
        }
    }

    private convertToDate(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIso8601(value)) {
                const tmp = new Date();

                const b = value.split(/\D/);
                tmp.setFullYear(parseInt(b[0], 10), parseInt(b[1], 10) - 1, parseInt(b[2], 10));
                // tmp.setFullYear(parseInt(b[0], 10));
                // tmp.setMonth(parseInt(b[1], 10) - 1);
                // tmp.setDate(parseInt(b[2], 10));

                tmp.setHours(parseInt(b[3], 10), parseInt(b[4], 10), parseInt(b[5], 10), 0);
                body[key] = tmp;
            } else if (typeof value === 'object') {
                this.convertToDate(value);
            }
        }
    }

    private isIso8601(value) {
        if (value === null || value === undefined) {
            return false;
        }

        return this.iso8601.test(value);
    }
}
